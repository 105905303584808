<div class="alert_wrapper">
    <div class="alert_body" v-click-outside="close">
        <div class="alert_title">{{$t('timetable.copying_alert.copying_trainings')}}</div>
        <div class="alert_text">
            <p class="my-3">{{$t('timetable.copying_alert.alert_text')}}</p>
            <p class="alert_p">{{alertCopyingWeek}}</p>
            <p class="alert_p">↓</p>
            <p class="alert_p">{{alertPastedWeek}}</p>
        </div>
        <div class="alert_buttons_wrapper">
            <button class="primary_button" @click="$emit('success')">{{$t('timetable.copying_alert.copy')}}</button>
            <button class="secondary_button button_space" @click="$emit('cancel')">{{$t('timetable.copying_alert.cancel')}}</button>
        </div>
    </div>
</div>