<template src="./registerClient.html"></template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, getFormatedAmount, checkIfClickInsideAlert } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { RadioModel } from '../../../../models/GUIModels';
    import FindClients from '../../FindClients/FindClients.vue';

    interface ComponentModel {
        isButtonDisabled: boolean,
        search: string,
        activeClient: any,
        isShowAvailableMethod: boolean,
        availableMethod: RadioModel[],
        selectedMethod: RadioModel | null,
        isShowAlert: boolean,
        alertText: string,
    }

    export default defineComponent({
        name: 'RegisterClient',
        props: ["training"],
        emits: ["close"],
        data(): ComponentModel {
            return {
                isButtonDisabled: false,
                search: "",
                activeClient: null,
                isShowAvailableMethod: false,
                availableMethod: [],
                selectedMethod: null,
                isShowAlert: false,
                alertText: "",
            }
        },
        methods: {
            clickOutside: function (event: any) {
                if (!checkIfClickInsideAlert(event.target)) this.close();
            },
            close: function () {
                this.$emit("close", false);
            },
            selectClient: function (client: any) {
                this.activeClient = client;

                if (this.training.price == 0) {
                    this.isButtonDisabled = false;
                    this.selectedMethod = { label: '', isTranslated: false, value: null }
                    return;
                }  

                this.availableMethod = [];
                this.selectedMethod = null;

                this.isShowAvailableMethod = false;

                if(!client) return;

                if (this.training.capacity > this.training.load) this.availableMethod.push({ label: 'training_payment_method.pos', isTranslated: true, value: "pos" });
                if (this.training.capacity > this.training.load) this.availableMethod.push({ label: 'training_payment_method.cash', isTranslated: true, value: "cash" });

                if (this.activeClient?.hasPaymentMean) this.availableMethod.push({ label: 'training_payment_method.linked_card', isTranslated: true, value: "linkedCard" });
                if (this.activeClient?.hasTrainingBlock) this.availableMethod.push({ label: 'training_payment_method.training_block', isTranslated: true, value: "trainingBlock" });

                if (this.training.capacity > this.training.load) {
                    this.selectedMethod = { label: 'training_payment_method.pos', isTranslated: true, value: "pos" }
                } else {
                    if (this.activeClient?.hasPaymentMean) {
                        this.selectedMethod = { label: 'training_payment_method.linked_card', isTranslated: true, value: "linkedCard" }
                    } else if (this.activeClient?.hasTrainingBlock) {
                        this.selectedMethod = { label: 'training_payment_method.training_block', isTranslated: true, value: "trainingBlock" }
                    }
                }

                nextTick(() => this.isShowAvailableMethod = true);
            },
            save: function () {
                this.isShowAlert = false;
                this.isButtonDisabled = true;

                let data;
                let url;

                if (this.training.capacity > this.training.load) {
                    url = Routes.registerClientOnGroupTraining;
                    data = { clientId: this.activeClient.id, trainingId: this.training.trainingId, paymentMethod: this.selectedMethod?.value };
                } else {
                    url = Routes.addToWaitingList;
                    data = { clientId: this.activeClient.id, trainingId: this.training.trainingId, isTrainingBlockUsed: this.selectedMethod?.value == "trainingBlock" };
                }

                axiosRequests.post(editUrl(url), data)
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );

            },
            clickOnSaveButton: function () {
                if (this.training.price == 0) {
                    this.save();
                    return;
                }

                if (this.selectedMethod?.value == "pos") {
                    this.alertText = 'timetable.view.register_client.pos_alert';
                    this.isShowAlert = true;
                } else if (this.selectedMethod?.value == "cash") {
                    this.alertText = 'timetable.view.register_client.cash_alert';
                    this.isShowAlert = true;
                } else {
                    this.save();
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            },
            getFormatedAmount
        },
        components: { findClients: FindClients },
        beforeMount() {
            if (!this.training) return;
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .register_client_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .register_client_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .register_client_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .register_client {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .register_client_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .text_with_span {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #65656F;
    }

    .text_with_span span {
        font-weight: 600;
        color: black;
    }

    .no_available_payment_methods {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #65656F;
    }

    @media(max-width: 500px) {
        .register_client_body {
            width: 98%;
        }

        .button_space {
            margin-left: 0px;
        }

        .register_client_buttons_wrapper {
            display: block;
        }

        .register_client_content_wrapper {
            max-height: calc(100vh - 90px);
            overflow: auto;
        }
    }
</style>