<template src="./addAdditionalFreezingDays.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, getFormatedAmount, checkIfClickInsideAlert } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { SelectModel } from '../../../../models/GUIModels';
    import { RadioModel } from '../../../../models/GUIModels';

    interface ComponentModel {
        services: SelectModel[],
        selectedService: SelectModel,
        isButtonDisabled: boolean,
        selectedPrice: string,
        selectedFirstPaymentAmount: string,
        availableMethod: RadioModel[]
        selectedMethod: RadioModel | null,
        showAlert: boolean,
        alertText: string,
        serviceErrorText: string,
    }

    export default defineComponent({
        name: 'AddAdditionalFreezingDays',
        props: ["availableServices", "currencySign", "hasClientPaymentMean", "contractId"],
        emits: ["close", "update"], 
        data(): ComponentModel {
            return {
                services: [],
                selectedService: { id: "", text: "", isTranslated: false, disabled: false, groupName: null },
                isButtonDisabled: false,
                selectedPrice: "",
                selectedFirstPaymentAmount: "",
                availableMethod: [],
                selectedMethod: null,
                showAlert: false,
                alertText: "",
                serviceErrorText: ""
            }
        },
        methods: {
            clickOutside: function (event: any) {
                 if (!checkIfClickInsideAlert(event.target)) this.close();
             },
             close: function () {
                 this.$emit("close");
             },
            selectService: function () {
                let index = this.availableServices.findIndex(x => x.serviceId == this.selectedService.id);

                if (index == -1) return;

                this.selectedPrice = getFormatedAmount(this.availableServices[index].price) + " " + this.currencySign;
                this.selectedFirstPaymentAmount = getFormatedAmount(this.availableServices[index].firstPaymentAmount) + " " + this.currencySign;
            },
            save: function () {
                this.showAlert = false;
                this.isButtonDisabled = true;

                if (!this.selectedService?.id) this.serviceErrorText = 'contract.view.add_additional_freezing_days.service_error_text';

                if (this.serviceErrorText) return;

                const data = { contractId: this.contractId, serviceId: this.selectedService.id, paymentMethod:  this.selectedMethod?.value };

                axiosRequests.post(editUrl(Routes.createServiceContract), data)
                     .then(
                         () => {
                             this.$emit("update");             
                         }
                     )
                     .catch(
                         async (err: any) => {
                             this.isButtonDisabled = false;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                  this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );
            },
            clickOnSaveButton: function () {
                if (this.selectedMethod?.value == "pos") {
                    this.alertText = 'contract.view.add_additional_freezing_days.pos_alert';
                    this.showAlert = true;
                } else if (this.selectedMethod?.value == "cash") {
                    this.alertText = 'contract.view.add_additional_freezing_days.cash_alert';
                    this.showAlert = true;
                } else {
                    this.save();
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            for (let i = 0; i < this.availableServices.length; i++) {
                this.services.push({ id: this.availableServices[i].serviceId?.toString(), text: this.availableServices[i].name?.toString(), isTranslated: false, disabled: false, groupName: null })
            }

            if (this.services.length > 0) {
                this.selectedService = this.services[0];
            } else {
                this.isButtonDisabled =true
            }

            this.availableMethod.push({ label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" });
            this.availableMethod.push({ label: 'add_entity_payment_method.cash', isTranslated: true, value: "cash" });

            if (this.hasClientPaymentMean) this.availableMethod.push({ label: 'add_entity_payment_method.linked_bank_card', isTranslated: true, value: "linkedCard" });

            this.selectedMethod = { label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" };

            this.selectService();
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .add_additional_freezing_days_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add_additional_freezing_days_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
    }

    .add_additional_freezing_days_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .add_additional_freezing_days {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .add_additional_freezing_days_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .price_span {
        color: #65656F;
    }

    @media(max-width: 500px) {
        .add_additional_freezing_days_body {
            width: 98%;
        }

        .add_additional_freezing_days_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }

        .add_additional_freezing_days_buttons_wrapper {
            display: block;
        }
    }
</style>