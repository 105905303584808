<template src="./createMailing.html"></template>

<script lang="ts">
    import { axiosRequests } from '../../../services/axiosinstance';
    import { defineComponent } from 'vue';
    import { editUrl, getFormatedDate, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';
    import { Club } from '../../../models/Club';
    import { MailingGroup } from '../../../models/Mailing';

    interface ComponentModel {
        clubs: SelectModel[],
        mailingTypes: SelectModel[],
        mailingGroups: SelectModel[],
        types: SelectModel[],
        periodTypes: SelectModel[],
        selectedClubs: SelectModel[],
        selectedMailingType: SelectModel,
        selectedType: SelectModel,
        selectedMailingGroup: SelectModel | null,
        selectedPeriodType: SelectModel,
        subject: string,
        editorMessage: string,
        message: string,
        sendDate: string,
        repetitionPeriod: number,
        isPeriodic: boolean,
        isButtonDisabled: boolean,
        clubErrorText: string,
        subjectErrorText: string,
        mailingGroupErrorText: string,
        messageErrorText: string,
        editorMessageErrorText: string,
        sendDateErrorText: string,
        repetitionPeriodErrorText: string,
        editorOption: any,
        tempImg: any,
        notificationImg: any
    }

    export default defineComponent({
        name: 'CreateMailing',
        data(): ComponentModel {
            return {
                selectedClubs: [],
                clubs: [],
                mailingTypes: [
                    { id: "mail", text: "notification_type.mail", isTranslated: true, disabled: false, groupName: null },
                    { id: "sms", text: "notification_type.sms", isTranslated: true, disabled: false, groupName: null },
                    { id: "notification", text: "notification_type.notification", isTranslated: true, disabled: false, groupName: null }
                ],
                types: [
                    { id: "emailmarketing", text: "message_type.email_marketing", isTranslated: true, disabled: false, groupName: null },
                    { id: "emailnotification", text: "message_type.email_notification", isTranslated: true, disabled: false, groupName: null }                
                ],
                periodTypes: [
                    { id: "days", text: "interval_measure.days", isTranslated: true, disabled: false, groupName: null },
                    { id: "months", text: "interval_measure.months", isTranslated: true, disabled: false, groupName: null },
                    { id: "years", text: "interval_measure.years", isTranslated: true, disabled: false, groupName: null }
                ],
                mailingGroups: [],
                selectedMailingType: { id: "mail", text: "notification_type.mail", isTranslated: true, disabled: false, groupName: null },
                selectedType: { id: "emailmarketing", text: "message_type.email_marketing", isTranslated: true, disabled: false, groupName: null },
                selectedPeriodType: { id: "days", text: "interval_measure.days", isTranslated: true, disabled: false, groupName: null },
                selectedMailingGroup: null,
                subject: "",
                editorMessage: "",
                message: "",
                sendDate: "",
                repetitionPeriod: 1,
                isPeriodic: false,
                isButtonDisabled: false,
                clubErrorText: "",
                subjectErrorText: "",
                mailingGroupErrorText: "",
                messageErrorText: "",
                editorMessageErrorText: "",
                sendDateErrorText: "",
                repetitionPeriodErrorText: "",
                editorOption: {
                    placeholder: '',
                    modules: {
                        toolbar: { container: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ script: 'sub' }, { script: 'super' }],
                                [{ indent: '-1' }, { indent: '+1' }],
                                [{ direction: 'rtl' }],
                                [{ size: ['small', false, 'large', 'huge'] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ['clean'],
                                ['link', 'image'],
                                ['emoji'],   
                            ],
                            handlers: {'emoji': function() {}}
                        },
                       "emoji-toolbar": true,
                       "emoji-textarea": false,
                       "emoji-shortname": true,
                    },
                },
                tempImg: null,
                notificationImg: null
            }
        },
        methods: {
            getClubs: async function () {
                await axiosRequests.get(editUrl(Routes.getClubs))
                    .then(
                        (data: Club[]) => {
                            this.clubs = data.map(x => { return { id: x.id.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null } });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getMailingGroups: async function () {
                await axiosRequests.get(editUrl(Routes.getMailingGroups))
                    .then(
                        (data: MailingGroup[]) => {
                            this.mailingGroups = data.map(x => { return { id: x.mailingGroupId.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null } });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            save: function () {
                if (this.selectedClubs.length == 0) this.clubErrorText = 'mailings.create.club_error';
                if (!this.subject) this.subjectErrorText = 'mailings.create.subject_error';
                if (!this.selectedMailingGroup) this.mailingGroupErrorText = 'mailings.create.mailing_group_error';
                if (this.selectedMailingType.id == 'mail' && !this.editorMessage) this.editorMessageErrorText = 'mailings.create.message_error';
                if (this.selectedMailingType.id != 'mail' && !this.message) this.messageErrorText = 'mailings.create.message_error';
                if (this.isPeriodic && this.repetitionPeriod < 1) this.repetitionPeriodErrorText = 'mailings.create.repetition_period_error';
                if (!this.sendDate) this.sendDateErrorText = 'mailings.create.send_date_error';

                if (this.clubErrorText || this.subjectErrorText || this.mailingGroupErrorText || this.messageErrorText || this.repetitionPeriodErrorText || this.sendDateErrorText) return;

                let formData = new FormData();
                formData.append("Subject", this.subject);
                formData.append("NotificationType", this.selectedMailingType.id);

                formData.append("MailingGroupId", this.selectedMailingGroup?.id ?? "");
                formData.append("SendingDate", getFormatedDate(this.sendDate, "yyyy-MM-DD HH:mm:ss"));
                formData.append("IsPeriodic", this.isPeriodic.toString());

                if (this.selectedMailingType.id != "notification") {
                    formData.append("ClientConsentType", this.selectedType.id);
                }

                if (this.selectedMailingType.id == 'mail') {
                    formData.append("Message", this.editorMessage);
                } else {
                    formData.append("Message", this.message);
                }
                
                if (this.isPeriodic) {
                    formData.append("PeriodType", this.selectedPeriodType.id);
                    formData.append("PeriodCount", this.repetitionPeriod.toString());
                }

                if (this.notificationImg) {
                    formData.append("Image", this.notificationImg);
                }
               
                for (let i = 0; i < this.selectedClubs.length; i++) {
                    formData.append(`ClubIds[${i}]`, this.selectedClubs[i].id);
                }

                this.isButtonDisabled = true;

                axiosRequests.post(editUrl(Routes.createMailing), formData)
                    .then(
                        () => {
                            this.$router.push('/mailings');
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            selectMailingType: function(){
                if(this.selectedMailingType.id == "mail"){
                    this.types = [
                        { id: "emailmarketing", text: "message_type.email_marketing", isTranslated: true, disabled: false, groupName: null },
                        { id: "emailnotification", text: "message_type.email_notification", isTranslated: true, disabled: false, groupName: null }                
                    ];

                    this.selectedType = { id: "emailmarketing", text: "message_type.email_marketing", isTranslated: true, disabled: false, groupName: null };
                }
                else if(this.selectedMailingType.id == "sms") {
                    this.types = [
                        { id: "smsmarketing", text: "message_type.sms_marketing", isTranslated: true, disabled: false, groupName: null },
                        { id: "smsnotification", text: "message_type.sms_notification", isTranslated: true, disabled: false, groupName: null }                
                    ];

                    this.selectedType = { id: "smsmarketing", text: "message_type.sms_marketing", isTranslated: true, disabled: false, groupName: null };
                }
            },
            removeImg: function () {
                this.notificationImg = null;
                this.tempImg = null;

                let fileInput: any = this.$refs.fileInput;
                fileInput.value = null;
            },
            addImg: function (event: any) {
                if (!(event.target.files && event.target.files[0])) {
                    return false;
                }

                this.notificationImg = event.target.files[0];

                if (!this.checkType(this.notificationImg.type)) {
                    this.notificationImg = null;
                    let fileInput: any = this.$refs.fileInput;
                    fileInput.value = null;
                    this.tempImg = null;

                    this.$store.commit('SET_ADD_ERROR', 'error.invalid_file_format');
                    return false;
                }

                const reader = new FileReader();

                reader.onload = e => this.tempImg = e.target?.result;
                reader.readAsDataURL(this.notificationImg);
            },
            checkType: function (type: string) {
                return type == "image/jpeg" || type == "image/bmp" || type == "image/png" || type == "image/gif";
            }

        },
        async beforeMount() {
            let mailingsPermission = this.$store.getters.getMailingsPermission;

            if (mailingsPermission.length == 0) mailingsPermission = await retryGetPermissions("mailing");

            if (!mailingsPermission?.includes('mailings/create')) this.$router.push('/forbidden');

            await this.getClubs();
            await this.getMailingGroups();
        },
    });
</script>

<style scoped>

    .top_half_left {
        display: flex;
        flex-flow: column;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .content_body {
        padding-right: 5px;
        overflow: auto;
    }

    .top_half_right {
        display: flex;
        flex-flow: column;
        margin-left: 8px;
        width: calc(50% - 8px);
    }

    .half_left_wrapper {
        display: flex;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .half_right_wrapper {
        display: flex;
        margin-left: 8px;
        width: calc(50% - 8px);
    }

    .four_block_left {
        display: flex;
        flex-flow: column;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .four_block_right {
        display: flex;
        flex-flow: column;
        margin-left: 8px;
        width: calc(50% - 8px);
    }

    .button_wrapper {
        display: flex;
    }

    .save_button {
        margin-bottom: 0px;
        margin-right: 16px;
    }

    .cancel_button {
        margin-bottom: 0px;
    }

    .one_block_wrapper {
        width: 100%;
        display: flex;
    }
    
    .two_block_wrapper {
        width: 100%;
        display: flex;
    }
    
    .four_block_wrapper {
        width: 100%;
        display: flex;
    }

    .input_label {
        position: absolute;
        font-size: 12px;
        top: -8px;
        background-color: white;
        padding: 0px 4px;
        z-index: 2;
        left: 13px;
        border-radius: 8px;
    }

    .date_input{
        width: 286px !important;
        margin-top: 18px;
    }

    .small_input {
        width: 286px !important;
    }

    .notification_img {
        margin-top: 12px;
        max-height: 200px;
        max-width: 100%;
        margin-bottom: -6px;
        display: flex;
        width: auto;
    }

    .periodic_wrapper{
        display: flex;
    }

    .period_type {
        margin-right: 12px;
    }

    .span_error {
        font-size: 11px;
        color: red;
        margin-left: 15px;
    }

    .img_delete_button {
        padding: 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 38px;
        height: 38px;
        margin-left: 12px;
        margin-top: 6px;
    }

        .img_delete_button img {
            height: 14px;
            width: 14px;
        }

    @media(max-width: 1200px) {
        .four_block_wrapper {
            flex-flow: column;
        }

        .half_left_wrapper {
            margin-right: 0px;
            width: 100%;
        }

        .half_right_wrapper {
            margin-left: 0px;
            width: 100%;
        }
    }


    @media(max-width: 960px) {
        .top_half_left {
            margin-right: 0px;
            width: 100%;
        }

        .top_half_right {
            margin-left: 0px;
            width: 100%;
        }

        .four_block_left {
            margin-right: 0px;
            width: 100%;
        }

        .four_block_right {
            margin-left: 0px;
            width: 100%;
        }

        .half_left_wrapper {
            flex-flow: column;
        }

        .half_right_wrapper {
            flex-flow: column;
        }

        .two_block_wrapper {
            flex-flow: column;
        }
    }

    @media(max-width: 700px) {
        .periodic_wrapper{
            display: block;
        }

        .small_input {
            width: 100% !important;
        }

        .period_type{
            margin-right: 0px;
            margin-bottom: 12px;
        }
    }

    
    @media(max-width: 500px){
        .button_wrapper {
            display: block;
        }

        .save_button {
            margin-bottom: 12px;
            margin-right: 0px;
        }

        .date_input {
            width: 100% !important;
            margin-top: 18px;
        }
    }


</style>