<template src="./passCardAlert.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'PassCardAlert',
        props: ["title"]
    });
</script>

<style scoped>
    .pass_card_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pass_card_body {
        background-color: white;
        max-width: 480px;
        width: 350px;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .pass_card_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .pass_card {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .pass_card_img {
        height: 77px;
        margin: auto;
        display: flex;
    }

    .pass_card_text {
        margin-bottom: 0;
        text-align: center;
    }

    .pass_card_buttons_wrapper {
        margin-top: 0px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
        justify-content: center;
    }


    @media(max-width: 500px) {
        .pass_card_body {
            width: 98%;
        }

        .pass_card_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }
    }
</style>