<template src="./linkPassCard.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';
    import { ClubShort } from '../../../models/Club';

    interface ComponentModel {
        clubs: SelectModel[],
        selectedClub: SelectModel | null,
        clubErrorText: string,
        isButtonDisabled: boolean
    }

    export default defineComponent({
        name: 'LinkPassCard',
        props: ["clientId", "userId"],
        emits:["close", "linkPassCard"],
        data(): ComponentModel {
            return {
                clubs: [],
                selectedClub: null,
                clubErrorText: "",
                isButtonDisabled: false
            }
        },
        methods: {
            close: function () {
                this.$emit("close", false);
            },
            getClubs: async function () {
                let query = ""

                if(this.clientId){
                    query = `?ClientId=${this.clientId}`;
                } else if(this.userId) {
                    query = `?UserId=${this.userId}`;
                }

                await axiosRequests.get(editUrl(`${Routes.getAvailableClubsForAddingPassCard}${query}`))
                    .then(
                        (data: ClubShort[]) => {
                            this.clubs = data.map(x => { return { id: x.clubId.toString(), text: x.clubName, isTranslated: false, disabled: false, groupName: null } });

                            this.selectedClub = this.clubs.find(x => x.id == this.$store.getters.getSelectedClubId) ?? null;

                            if (this.clubs.length > 0 && this.selectedClub == null) this.selectedClub = this.clubs[0];

                            if (this.clubs.length == 1 && this.selectedClub != null) {
                                this.$emit("linkPassCard", this.selectedClub.id);
                                return;
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            link: function () {
                if (!this.selectedClub) {
                    this.clubErrorText = 'pass_card.link_pass_card.club_error';
                    return;
                }

                 this.$emit("linkPassCard", this.selectedClub.id);
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            await this.getClubs()
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .pass_card_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pass_card_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
    }

    .pass_card_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .pass_card {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .pass_card_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-right: 16px;
    }


    .pass_card_text {
        margin-top: 0px;
        margin-bottom: 16px;
        color: #65656F;
        font-weight: bold;
    }

    @media(max-width: 500px) {
        .pass_card_body {
            width: 98%;
        }

        .pass_card_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-right: 0px;
        }
    }
</style>