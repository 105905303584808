<div class="create_training_wrapper">
    <div class="create_training_body" v-click-outside="close">
        <div class="create_training_title">{{$t('timetable.create_group_training.create_training')}}</div>
        <div class="create_training">
            <p class="text_with_span">{{$t('timetable.create_group_training.club')}}: <span>{{clubName}}</span></p>
            <p class="text_with_span mb-4">{{$t('timetable.create_group_training.club_zone')}}: <span>{{clubZoneName}}</span></p>
                <v-select class="mt-3 mb-4" v-model="selectedClass" :label="'timetable.create_group_training.training_class'" :items="classes" :errorText="classErrorText" @click="classErrorText = ''" @update:modelValue="selectClass"></v-select>
                <v-select class="mt-4 mb-4" v-model="selectedTrainer" :label="'timetable.create_group_training.trainer'" :items="groupTrainers" :errorText="trainerErrorText" @click="trainerErrorText = ''"></v-select>
                <v-input class="mt-4 mb-2" v-model="startDate" inputType="datetime-local" :label="$t('timetable.create_group_training.start_date')" height="22" :min="minDate" :errorText="startDateErrorText" @focus="startDateErrorText = ''" clearable></v-input>
                <p class="text_with_span" v-show="classes.length > 0">{{$t('timetable.create_group_training.capacity')}}: <span>{{capacity}}</span></p>
        </div>
        <div class="create_training_buttons_wrapper">
            <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('timetable.create_group_training.create') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('timetable.create_group_training.cancel')}}</button>
        </div>
    </div>
</div>