<v-alert v-model="isShowAlert" :title="'timetable.view.alert_title'" :text="'timetable.view.are_you_sure'" :successButtonText="$t('timetable.view.confirm')" :cancelButtonText="$t('timetable.view.cancel')" @success="deleteTraining" @cancel="isShowAlert = false"></v-alert>
<registerClient v-if="isShowRegisterClient" :training="training" @close="closeWindow"></registerClient>
<retryToPay v-if="isShowRetry" :client="clientForRetryToPay" @close="closeWindow"></retryToPay>

<div class="view_training_wrapper">
    <div class="view_training_body" v-click-outside="clickOutside">
        <div class="view_training_title">{{training?.trainingClassName}} <img class="close_button" src="/assets/img/close.svg" @click="close" /></div>
        <div class="view_training">
            <div class="view_training_info_wrapper">
                <div class="blocks_wrapper">
                    <div class="block">
                        <div class="block_label">{{$t('timetable.view.status')}}</div>
                        <div :class="training?.isTrainerAccepted == null ? 'not_accept' : training?.isTrainerAccepted == true ? 'accept' : 'reject'">{{ training?.isTrainerAccepted == null ? $t('timetable.view.not_accept') : training?.isTrainerAccepted == true ? $t('timetable.view.accept') : $t('timetable.view.reject') }}</div>
                    </div>

                    <div class="block">
                        <div class="block_label">{{$t('timetable.view.start_date')}}</div>
                        <div>{{ getFormatedDate(training?.trainingStartDate, 'DD.MM.yyyy HH:mm') }}</div>
                    </div>
                </div>

                <v-select class="mb-4" v-model="selectedTrainer" :label="'timetable.view.trainer'" :items="training?.trainers" v-if="!isPassed"></v-select>

                <div class="blocks_wrapper" v-if="isPassed">
                    <div class="mb-4">
                        <div class="block_label">{{$t('timetable.view.trainer')}}</div>
                        <div>{{selectedTrainer?.text}}</div>
                    </div>
                </div>

                <div class="blocks_wrapper">
                    <div class="block">
                        <div class="block_label">{{$t('timetable.view.capacity')}}</div>
                        <div>{{training?.capacity}}</div>
                    </div>

                    <div class="block">
                        <div class="block_label">{{$t('timetable.view.load')}}</div>
                        <div>{{training?.load}}</div>
                    </div>
                </div>

                <div :class="hasAddClientPermission ? 'view_training_buttons_wrapper_with_add' : 'view_training_buttons_wrapper'" v-if="!isPassed">
                    <button class="save_button" @click="save" v-if="hasUpdatePermission" :disabled="isButtonDisabled">{{$t('timetable.view.save') }}</button>
                    <button class="delete_button" @click="isShowAlert = true" :disabled="isButtonDisabled">{{$t('timetable.view.delete')}}</button>
                </div>

            </div>

            <div class="view_training_clients_wrapper">
                <div class="view_training_clients">
                    <div class="view_training_clients_title">
                        <div class="view_training_clients_title_element view_training_clients_title_element_first" :class="{ active: isShowRegistered}" @click="isShowRegistered = true">{{$t('timetable.view.registered')}}</div>
                        <div class="view_training_clients_title_element" :class="{ active: !isShowRegistered}" @click="isShowRegistered = false">{{$t('timetable.view.awaited')}}</div>
                    </div>
                    <div class="view_training_clients_body" v-if="isShowRegistered">
                        <div class="client_wrapper" v-for="client in training?.registeredClients">
                            <div class="client_photo" :style="{ 'background-image': `url(${client?.photoUrl})` }"></div>
                            <div class="client_info_wrapper">
                                <div class="flex"><p class="client_name" @click="redirectToClient(client?.id)">{{client?.fullName}}</p></div>
                                <p class="client_phone">{{addPhoneMask(client.phoneWithCountryCode)}}</p>
                            </div>

                            <div class="client_pay_wrapper">
                                <div :class="client?.isPayed || !hasRetryPermission ? 'client_payed' : 'client_not_payed'" @click="openRetryWindow(client)"><div :class="client?.isPayed ? 'dot mt-1 payed' : 'dot mt-1 not_payed'"></div>{{ client?.isPayed ? $t('timetable.view.payed') : $t('timetable.view.not_payed')}}</div>
                                <p class="client_pay_amount" v-if="client?.isPayed">
                                    <span v-if="client?.paymentMethod == 'training_payment_method.training_block'">{{$t('timetable.view.from_block')}}</span>
                                    <span v-else>{{getFormatedAmount(client?.amount)}} {{client?.currencySign}}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="view_training_clients_body" v-if="!isShowRegistered">
                        <div class="client_wrapper" v-for="client in training?.waitingList">
                            <div class="client_photo" :style="{ 'background-image': `url(${client?.photoUrl})` }"></div>
                            <div class="client_wait_list_info_wrapper">
                                <div class="flex"><p class="client_name" @click="redirectToClient(client?.id)">{{client?.fullName}}</p></div>
                                <p class="client_phone">{{addPhoneMask(client.phoneWithCountryCode)}}</p>
                            </div>
                        </div>
                    </div>

                </div>

                <a class="add_client" v-if="hasAddClientPermission" @click="isShowRegisterClient = true">{{$t('timetable.view.add_client')}}</a>
            </div>

        </div>

    </div>
</div>