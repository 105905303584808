<div class="change_next_step_date_wrapper">
    <div class="change_next_step_date_body" v-click-outside="close">
        <div class="change_next_step_date_body_title">{{$t('task.change_date.change_next_step_date')}}</div>

        <v-input class="change_next_step_date_inpute" v-model="nextStepDate" :label="$t('task.change_date.date')" inputType="datetime-local" height="22" :min="minDate" :max="maxDate" ref="changeDateInpute" :errorText="dateErrorText" @focus="dateErrorText = ''" clearable></v-input>

        <div class="change_next_step_date_buttons_wrapper">
            <button class="primary_button" @click="saveDate">{{$t('task.change_date.change') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('task.change_date.cancel')}}</button>
        </div>

    </div>
</div>

