import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a28e5e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_wrapper wrapper" }
const _hoisted_2 = { class: "content_body" }
const _hoisted_3 = { class: "component_title" }
const _hoisted_4 = { class: "two_block_wrapper" }
const _hoisted_5 = { class: "top_half_left mb-4" }
const _hoisted_6 = { class: "top_half_right mb-4" }
const _hoisted_7 = { class: "two_block_wrapper" }
const _hoisted_8 = { class: "top_half_left mb-4" }
const _hoisted_9 = { class: "top_half_right mb-4" }
const _hoisted_10 = { class: "one_block_wrapper" }
const _hoisted_11 = { class: "w-100 mb-4" }
const _hoisted_12 = { class: "input_label" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "w-100 mt-2 mb-3" }
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "periodic_wrapper" }
const _hoisted_17 = { class: "button_wrapper mt-3" }

export function render(_ctx, _cache) {
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_select = _resolveComponent("v-select")
  const _component_quill_editor = _resolveComponent("quill-editor")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _component_v_checkbox = _resolveComponent("v-checkbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('mailings.create.create_mailing')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_input, {
            modelValue: _ctx.subject,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.subject) = $event)),
            label: _ctx.$t('mailings.create.subject'),
            height: "22",
            errorText: _ctx.subjectErrorText,
            onFocus: _cache[1] || (_cache[1] = $event => (_ctx.subjectErrorText = '')),
            clearable: ""
          }, null, 8, ["modelValue", "label", "errorText"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedClubs,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedClubs) = $event)),
            label: 'mailings.create.clubs',
            items: _ctx.clubs,
            errorText: _ctx.clubErrorText,
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.clubErrorText = '')),
            multiple: "",
            clearable: ""
          }, null, 8, ["modelValue", "label", "items", "errorText"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedMailingType,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = $event => ((_ctx.selectedMailingType) = $event)),
              _ctx.selectMailingType
            ],
            label: 'mailings.create.mailing_type',
            items: _ctx.mailingTypes
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedMailingGroup,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.selectedMailingGroup) = $event)),
            label: 'mailings.create.mailing_group',
            items: _ctx.mailingGroups,
            errorText: _ctx.mailingGroupErrorText,
            onClick: _cache[6] || (_cache[6] = $event => (_ctx.mailingGroupErrorText = ''))
          }, null, 8, ["modelValue", "label", "items", "errorText"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedType,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.selectedType) = $event)),
            label: 'mailings.create.type',
            items: _ctx.types
          }, null, 8, ["modelValue", "label", "items"])
        ])
      ], 512), [
        [_vShow, _ctx.selectedMailingType.id != 'notification']
      ]),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass([{ edit_error: _ctx.editorMessageErrorText }, "mt-1 edit position-relative"])
      }, [
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('mailings.create.message')), 1),
        _createVNode(_component_quill_editor, {
          value: _ctx.editorMessage,
          "onUpdate:value": _cache[8] || (_cache[8] = $event => ((_ctx.editorMessage) = $event)),
          options: _ctx.editorOption,
          onFocus: _cache[9] || (_cache[9] = $event => (_ctx.editorMessageErrorText = ''))
        }, null, 8, ["value", "options"]),
        _withDirectives(_createElementVNode("span", { class: "span_error" }, _toDisplayString(_ctx.messageErrorText), 513), [
          [_vShow, _ctx.editorMessageErrorText]
        ])
      ], 2), [
        [_vShow, _ctx.selectedMailingType.id == 'mail']
      ]),
      _withDirectives(_createVNode(_component_v_textarea, {
        class: "mt-0",
        modelValue: _ctx.message,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.message) = $event)),
        label: _ctx.$t('mailings.create.message'),
        errorText: _ctx.messageErrorText,
        onFocus: _cache[11] || (_cache[11] = $event => (_ctx.messageErrorText = ''))
      }, null, 8, ["modelValue", "label", "errorText"]), [
        [_vShow, _ctx.selectedMailingType.id != 'mail']
      ]),
      _withDirectives(_createElementVNode("div", null, [
        (_ctx.tempImg)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "notification_img",
              src: _ctx.tempImg
            }, null, 8, _hoisted_13))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          type: "file",
          hidden: "",
          ref: "fileInput",
          onChange: _cache[12] || (_cache[12] = (...args) => (_ctx.addImg && _ctx.addImg(...args)))
        }, null, 544),
        _createElementVNode("button", {
          class: "primary_button mt-3",
          onClick: _cache[13] || (_cache[13] = $event => (_ctx.$refs.fileInput.click()))
        }, _toDisplayString(_ctx.$t('mailings.create.add_img')), 1)
      ], 512), [
        [_vShow, _ctx.selectedMailingType.id == 'notification']
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_v_checkbox, {
            modelValue: _ctx.isPeriodic,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.isPeriodic) = $event)),
            label: _ctx.$t('mailings.create.periodic')
          }, null, 8, ["modelValue", "label"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_16, [
        _createVNode(_component_v_select, {
          class: "small_input period_type",
          modelValue: _ctx.selectedPeriodType,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.selectedPeriodType) = $event)),
          label: 'mailings.create.period_type',
          items: _ctx.periodTypes
        }, null, 8, ["modelValue", "label", "items"]),
        _createVNode(_component_v_input, {
          class: "small_input",
          modelValue: _ctx.repetitionPeriod,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.repetitionPeriod) = $event)),
          label: _ctx.$t('mailings.create.repetition_period'),
          height: "22",
          errorText: _ctx.repetitionPeriodErrorText,
          onFocus: _cache[17] || (_cache[17] = $event => (_ctx.repetitionPeriodErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "errorText"])
      ], 512), [
        [_vShow, _ctx.isPeriodic]
      ]),
      _createVNode(_component_v_input, {
        class: "date_input",
        modelValue: _ctx.sendDate,
        "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.sendDate) = $event)),
        label: _ctx.$t('mailings.send_date'),
        inputType: "datetime-local",
        height: "22",
        errorText: _ctx.sendDateErrorText,
        onFocus: _cache[19] || (_cache[19] = $event => (_ctx.sendDateErrorText = '')),
        clearable: ""
      }, null, 8, ["modelValue", "label", "errorText"]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("button", {
          class: "primary_button save_button",
          onClick: _cache[20] || (_cache[20] = (...args) => (_ctx.save && _ctx.save(...args)))
        }, _toDisplayString(_ctx.$t('mailings.create.create')), 1),
        _createElementVNode("button", {
          class: "secondary_button cancel_button",
          onClick: _cache[21] || (_cache[21] = $event => (_ctx.$router.go(-1)))
        }, _toDisplayString(_ctx.$t('mailings.create.back')), 1)
      ])
    ])
  ]))
}