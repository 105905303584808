<div class="pass_card_wrapper">
    <div class="pass_card_body">
        <div class="pass_card_title">{{$t(title)}}</div>
        <div class="pass_card">
            <p class="pass_card_text">{{$t('pass_card.pass_card_alert.attach_card') }}</p>
            <img class="pass_card_img" src="/assets/img/load-animation.svg" />
        </div>
        <div class="pass_card_buttons_wrapper">
            <button class="secondary_button" @click="$emit('close')">{{$t('pass_card.pass_card_alert.cancel')}}</button>
        </div>
    </div>
</div>