<div class="add_photo_wrapper">
    <div class="add_photo_body" v-click-outside="close">
        <div class="add_photo_title">{{$t('add_photo.add_photo')}}</div>
        <div class="add_photo">

            <div v-show="isLoading" class="connection">
                <p class="connection_p">{{$t('add_photo.connection')}}</p>
            </div>

            <div v-show="!hasCamera && !isLoading">
                <img class="camera_missing_img" src="/assets/img/camera-noflash.svg" />
                <p class="camera_missing_p">{{$t('add_photo.camera_missing')}}</p>
            </div>

            <div class="camera_wrapper" v-show="hasCamera && !isShowResult && !isLoading">
                <img class="camera_template" src="/assets/img/ellipse.svg" />
                <camera ref="camera" :resolution="{ width: 640, height: 480 }" autoplay></camera>
            </div>

            <div v-show="isShowResult">
                <img class="result_img" :src="tempImg" />
            </div>
        </div>
        <div class="add_photo_buttons_wrapper">
            <button class="primary_button" @click="createSnapshot" v-show="!isShowResult" :disabled="!hasCamera">{{$t('add_photo.create_photo') }}</button>
            <button class="secondary_button button_space" v-show="!isShowResult" @click="$emit('close')">{{$t('add_photo.cancel')}}</button>

            <button class="primary_button" @click="save" v-show="isShowResult" :disabled="isButtonDisabled">{{$t('add_photo.save') }}</button>
            <button class="secondary_button button_space" v-show="isShowResult" @click="isShowResult = false">{{$t('add_photo.back')}}</button>
        </div>
    </div>
</div>


