<template src="./addPhoto.html"></template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl } from '../../services/helper';
    import { Routes } from '../../const';
    import Camera from "simple-vue-camera";

    interface ComponentModel {
        hasCamera: boolean,
        isShowResult: boolean,
        isButtonDisabled: boolean,
        tempImg: any,
        photo: any,
        isLoading: boolean
    }

    export default defineComponent({
        name: 'AddPhoto',
        props: ["clientId", "userId"],
        emits: ["close"],
        data(): ComponentModel {
            return {
                hasCamera: false,
                isShowResult: false,
                isButtonDisabled: false,
                tempImg: null,
                photo: null,
                isLoading: true
            }
        },
        methods: {
            close: function () {
                this.$emit("close", false);
            },
            save: async function () {
                let formData = new FormData();
                formData.append("Photo", this.photo);

                try{
                     if (this.clientId) {
                        this.isButtonDisabled = true;
                         
                        await axiosRequests.post(editUrl(Routes.uploadClientPhoto, this.clientId), formData);

                        this.$emit("close", true);
                     } else if(this.userId) {
                        await axiosRequests.post(editUrl(Routes.uploadUserPhoto, this.userId), formData);

                        this.$emit("close", true);
                     }
                }
                catch (err: any) {
                    this.isButtonDisabled = false;

                    if (err.status == 401) {
                        return;
                    } else if (err.status == 403) {
                        this.$router.push('/forbidden');
                    } else if (err.status == 400 && err.data) {
                        this.$store.commit('SET_ADD_ERROR', err.data);
                    } else {
                        this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                    }
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            },
            checkCamera: async function() {
                let devices = await this.camera?.devices() || [];

                this.hasCamera = devices.length > 0;
                this.isLoading = false;
            },
            createSnapshot: async function(){
                this.photo = await this.camera?.snapshot({ width: 640, height: 480 });

                if(!this.photo) return;

                this.tempImg = URL.createObjectURL(this.photo);

                this.isShowResult = true;
            }
        },
        components: { camera: Camera },
        setup() {
            const camera = ref<InstanceType<typeof Camera>>();

            return { camera }
        },
        async mounted() {
            window.addEventListener('keyup', this.checkPressedButton);

            setTimeout(this.checkCamera, 1000)   
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .add_photo_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
    }

    .add_photo_body {
        background-color: white;
        position: absolute;
        top: 50%;
        max-width: 480px;
        width: max-content;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .add_photo_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .add_photo {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .add_photo_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }


    .camera_wrapper {
        width: 320px;
        position: relative;
        height: 243px;
    }

    .camera_template {
        position: absolute;
        height: 260px;
        width: 260px;
        transform: translateX(-50%);
        left: 50%;
        top: -10px;
        z-index: 2;
    }

    .result_img {
        width: 320px;
        height: 243px;
    }

    .camera_missing_img {
        height: 175px;
        width: 175px;
        margin: auto;
        display: flex;
        margin-top: -20px;
    }

    .camera_missing_p {
        font-weight: bold;
        text-align: center;
        margin-top: 0px;
    }

    .connection {
        height: 195px;
        width: 319px;
        display: flex;
    }

    .connection_p {
        margin: auto;
        font-size: 20px;
        font-weight: bold;
    }

    @media(max-width: 500px) {
        .add_photo_body {
            width: 98%;
        }

        .add_photo_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }
</style>