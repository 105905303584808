<div class="popup_wrapper">
    <v-alert v-model="isShowAlert" :title="'timetable.view.register_client.confirmation'" :text="alertText" :successButtonText="$t('timetable.view.register_client.confirm')" :cancelButtonText="$t('timetable.view.register_client.cancel')" @success="save" @cancel="isShowAlert = false"></v-alert>

    <div class="register_client_wrapper">
        <div class="register_client_body" v-click-outside="clickOutside">
            <div class="register_client_title">{{training.capacity > training.load ? $t('timetable.view.register_client.registration_of_client') : $t('timetable.view.register_client.add_to_waiting_list')}}</div>
            <div class="register_client_content_wrapper">
                <div class="register_client">
                    <p class="text_with_span">{{$t('timetable.view.register_client.training')}}: <span>{{training.trainingClassName}}</span></p>
                    <p class="text_with_span mb-4">{{$t('timetable.view.register_client.price')}}: <span>{{getFormatedAmount(training.price)}} {{training.currencySign}}</span></p>
                    <findClients @selectClient="selectClient" :isGroup="true" :trainingClassId="training.trainingClassId" :beginDate="training.trainingStartDate"></findClients>

                    <h4 class="mt-3 mb-2" v-if="isShowAvailableMethod">{{$t('add_entity_payment_method.payment_method')}}</h4>
                    <v-radio v-if="isShowAvailableMethod" class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
                    <p v-if="availableMethod.length == 0 && isShowAvailableMethod" class="no_available_payment_methods">{{$t('timetable.view.register_client.no_available_payment_methods')}}</p>
                </div>
                <div class="register_client_buttons_wrapper">
                    <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled || !activeClient || selectedMethod == null">{{training.capacity > training.load ? $t('timetable.view.register_client.register') : $t('timetable.view.register_client.add')}}</button>
                    <button class="secondary_button button_space" @click="$emit('close')">{{$t('timetable.view.register_client.cancel')}}</button>
                </div>
            </div>

        </div>
    </div>
</div>
