<div class="popup_wrapper">
    <v-alert v-model="isShowAlert" :title="'timetable.retry_to_pay.confirmation'" :text="alertText" :successButtonText="$t('timetable.retry_to_pay.confirm')" :cancelButtonText="$t('timetable.retry_to_pay.cancel')" @success="save" @cancel="isShowAlert = false"></v-alert>

    <div class="retry_to_pay_wrapper">
        <div class="retry_to_pay_body" v-click-outside="clickOutside">
            <div class="retry_to_pay_title">{{$t('timetable.retry_to_pay.retry_to_pay')}}</div>
            <div class="retry_to_pay_content_wrapper">
                <div class="retry_to_pay">
                    <h4 class="mt-3 mb-2">{{$t('add_entity_payment_method.payment_method')}}</h4>
                    <v-radio class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
                </div>
                <div class="retry_to_pay_buttons_wrapper">
                    <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled">{{$t('timetable.retry_to_pay.pay')}}</button>
                    <button class="secondary_button button_space" @click="$emit('close')">{{$t('timetable.retry_to_pay.cancel')}}</button>
                </div>
            </div>

        </div>
    </div>
</div>
