<div class="write_message_wrapper">
    <div class="write_message_body" v-click-outside="close">
        <div class="write_message_title">{{$t('clients.view.write_message.send_message')}}</div>
        <div class="write_message">
            <v-input class="mb-4 mt-5" v-model="subject" :label="$t('clients.view.write_message.subject')" height="22" :errorText="subjectErrorText" @focus="subjectErrorText = ''" clearable></v-input>
            <v-textarea v-model="message" :label="$t('clients.view.write_message.message')" :errorText="messageErrorText" @focus="messageErrorText = ''"></v-textarea>

            <div class="flex mt-3">
                <div class="mr-2">
                    {{$t('clients.view.write_message.send')}}:
                </div>
                <v-radio v-model="selectedType" :radioList="availableTypes" :mr="3"></v-radio>
            </div>
            

        </div>
        <div class="write_message_buttons_wrapper">
            <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('clients.view.write_message.save') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('clients.view.write_message.cancel')}}</button>
        </div>
    </div>
</div>

