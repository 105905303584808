<v-alert v-model="showAlert" :title="'clients.view.add_client_training_block.confirmation'" :text="alertText" :successButtonText="$t('clients.view.add_client_training_block.confirm')" :cancelButtonText="$t('clients.view.cancel')" @success="save" @cancel="showAlert = false"></v-alert>

<div class="add_client_training_block_wrapper">
    <div class="add_client_training_block_body" v-click-outside="clickOutside">
        <div class="add_client_training_block_title">{{$t('clients.view.add_client_training_block.add_training_block')}}</div>
        <div class="add_client_training_block">
            <v-select class="mt-4 mb-4" v-model="selectedClientTrainingBlockType" :label="'clients.view.add_client_training_block.block_type'" :items="clientTrainingBlockTypes" @update:modelValue="selectType"></v-select>

            <v-select v-show="selectedClientTrainingBlockType.id == 0 && contracts.length > 0" class="mb-3" v-model="selectedContract" :label="'clients.view.add_client_training_block.contract'" :items="contracts" @update:modelValue="selectContract"></v-select>

            <v-select v-show="selectedClientTrainingBlockType.id == 0" class="mb-3" v-model="selectedClubTrainingBlock" :label="'clients.view.add_client_training_block.block_name'" :items="clubTrainingBlocks" @update:modelValue="selectClubBlock"></v-select>


            <v-select v-show="selectedClientTrainingBlockType.id == 1" class="mb-3" v-model="selectedTrainer" :label="'clients.view.add_client_training_block.trainer'" :items="trainers" @update:modelValue="getTrainerBlocks"></v-select>


            <v-select v-show="selectedClientTrainingBlockType.id == 1 && clubs.length > 0" class="mb-3" v-model="selectedClub" :label="'clients.view.add_client_training_block.club'" :items="clubs" @update:modelValue="getTrainerBlocks"></v-select>

            <v-select v-show="selectedClientTrainingBlockType.id == 1 && trainers.length > 0" class="mt-4 mb-3" v-model="selectedTrainerBlock" :label="'clients.view.add_client_training_block.block_name'" :items="trainerBlocks" @update:modelValue="selectTrainerBlock"></v-select>


            <p class="mt-0 mb-0" v-show="price">{{$t('contract.view.add_service.price')}}: <span class="price_span">{{price}}</span></p>

            <h4 class="mt-3 mb-2">{{$t('add_entity_payment_method.payment_method')}}</h4>

            <v-radio class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
        </div>
        <div class="add_client_training_block_buttons_wrapper">
            <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled">{{$t('clients.view.add_client_training_block.add') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('clients.view.add_client_training_block.cancel')}}</button>
        </div>
    </div>
</div>

