<v-alert v-model="showAlert" :title="'order.alert_change_status'" :text="'order.are_you_sure'" :successButtonText="$t('order.confirm')" :cancelButtonText="$t('order.cancel')" @success="changeOrderStatus" @cancel="showAlert = false"></v-alert>
<v-alert v-model="showPOSAlert" :title="'order.alert_pos_pay'" :text="'order.pos_alert_text'" :successButtonText="$t('order.confirm')" :cancelButtonText="$t('order.cancel')" @success="pay('card')" @cancel="showPOSAlert = false"></v-alert>
<v-alert v-model="showCashAlert" :title="'order.alert_cash_pay'" :text="'order.cash_alert_text'" :successButtonText="$t('order.confirm')" :cancelButtonText="$t('order.cancel')" @success="pay('cash')" @cancel="showCashAlert = false"></v-alert>

<div class="content_wrapper wrapper">
    <button @click="$router.go(-1)" class="button button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('order.back')}}</span></button>
    <div class="order_page_wrapper">
        <div class="order_data">

            <div class="w-100 mb-1">
                <div class="order_title">
                    <p class="order" v-show="isLoaded">ORD #{{order?.orderId?.toString().padStart(8, '0')}}</p>
                    <div :class="'order_status status_text_' + getOrderStatus(generateOrderStatus(order?.processingStatus, order?.paidStatus))" v-show="isLoaded">{{$t('order_status.' + getOrderStatus(generateOrderStatus(order?.processingStatus ?? 1, order?.paidStatus)))}}<span v-if="order?.resultDescription && order?.paidStatus != 1">: </span><span class="order_status_span_red" v-if="order?.resultDescription && order?.paidStatus != 1">{{$t(order?.resultDescription)}}</span>
                    </div>
                </div>

            </div>

            <div class="mt-3 order_data_block">
                <p class="block_label">{{ $t('order.amount') }}</p>
                <p class="block_info">{{ getFormatedAmount(order?.total) }} {{order?.currencySign }}</p>
            </div>

            <div class="mt-3 order_data_block">
                <p class="block_label">{{ $t('order.date') }}</p>
                <p class="block_info">{{ getFormatedDate(order?.queueDate, "DD.MM.yyyy") || '-' }}</p>
            </div>

            <div class="mt-3 order_data_block">
                <p class="block_label">{{ $t('order.payments_processing_provider') }}</p>
                <p class="block_info">{{ order?.bankProcessingProviderName ? $t(getOrderPaymentsProcessingProvider(order?.bankProcessingProviderName)) : '-' }}</p>
            </div>

            <div class="mt-3 order_data_block">
                <p class="block_label">{{ $t('order.description') }}</p>
                <p class="block_info">{{ order?.comment }}</p>
            </div>
        </div>

        <div class="button_wrapper">
            <button class="order_pay_button" v-if="canPayOrder && hasPayPermission" @click="showPOSAlert = true" :disabled="isButtonDisabled">{{ $t('order.pay_by_card') }}</button>
            <button class="order_pay_button" v-if="canPayOrder && hasPayPermission" @click="showCashAlert = true" :disabled="isButtonDisabled">{{ $t('order.pay_in_cash') }}</button>
            <button class="order_cancel_button" v-if="order?.processingStatus == 0 && hasCancelPermission && !order?.isBankTransfer" @click="showAlert = true" :disabled="isButtonDisabled">{{ $t('order.cancel_order') }}</button>
            <button class="order_resume_button" v-if="order?.processingStatus == -1 && hasResumePermission && !order?.isBankTransfer" @click="showAlert = true" :disabled="isButtonDisabled">{{ $t('order.resume_order') }}</button>
            <button class="order_refund_button" v-if="order?.processingStatus == 2 && order?.paidStatus == 1 && !order?.isRefunded && hasRefundPermission && !order?.isBankTransfer" @click="showAlert = true" :disabled="isButtonDisabled">{{ $t('order.refund_order') }}</button>
        </div>
       
        <div class="order_data mt-6">
            <div class="w-100 ">
                <h3 class="my-0">{{$t('order.order_details')}}</h3>
            </div>

            <v-datatable class="mt-3" :columTemplates="orderItemsColumTemplates" :data="orderItemsTableData"></v-datatable>
        </div>
    </div>
    
</div>

