import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { LastViewedClients } from '../models/Clients';
import { User } from '../models/User';
import { HubConnectionBuilder } from '@microsoft/signalr'
import { LeadCalling } from '../models/LeadCalling';

export interface State {
    load: boolean,
    isAuth: boolean,
    isShowInternalServerError: boolean,
    currentUser: User | null,
    selectedClubId: number,
    networkId: number,
    errors: string[],
    successMessages: string[],
    currentUrl: string,
    activeMenu: string,
    activeSubmenu: string,
    lastViewedClients: LastViewedClients[],
    clientsPermission: string[],
    contractsPermission: string[],
    serviceContractsPermission: string[],
    ordersPermission: string[],
    refundOrdersPermission: string[],
    trainingBlocksPermission: string[],
    mailingsPermission: string[],
    tasksPermission: string[],
    timetablesPermission: string[],
    userPermission: string[],
    hubConnection: HubConnectionBuilder | undefined,
    isChatReconnecting: boolean,
    isChatConnected: boolean,
    isLeadOpen: boolean,
    leadCalling: LeadCalling | null
    isCallingButtonClicked: boolean
    hasConnectionToLocal: boolean
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        load: false,
        isAuth: false,
        isShowInternalServerError: false,
        currentUser: null,
        selectedClubId: 0,
        networkId: 0,
        errors: [],
        successMessages: [],
        currentUrl: '',
        activeMenu: '',
        activeSubmenu: '',
        lastViewedClients: [],
        clientsPermission: [],
        contractsPermission: [],
        serviceContractsPermission: [],
        ordersPermission: [],
        refundOrdersPermission: [],
        trainingBlocksPermission: [],
        mailingsPermission: [],
        tasksPermission: [],
        timetablesPermission: [],
        userPermission: [],
        hubConnection: undefined,
        isChatReconnecting: false,
        isChatConnected: false,
        isLeadOpen: false,
        leadCalling: null,
        isCallingButtonClicked: false,
        hasConnectionToLocal: false
    },
    getters: {
        getLoad: state => { return state.load },
        getIsAuth: state => { return state.isAuth },
        getIsShowInternalServerError: state => { return state.isShowInternalServerError },
        getCurrentUser: state => { return state.currentUser },
        getSelectedClubId: state => { return state.selectedClubId },
        getNetworkId: state => { return state.networkId },
        getErrors: state => { return state.errors },
        getSuccessMessages: state => { return state.successMessages },
        getActiveMenu: state => { return state.activeMenu },
        getActiveSubmenu: state => { return state.activeSubmenu },
        getCurrentUrl: state => { return state.currentUrl },
        getLastViewedClients: state => { return state.lastViewedClients },
        getClientsPermission: state => { return state.clientsPermission },
        getContractsPermission: state => { return state.contractsPermission },
        getServiceContractsPermission: state => { return state.serviceContractsPermission },
        getOrdersPermission: state => { return state.ordersPermission },
        getRefundOrdersPermission: state => { return state.refundOrdersPermission },
        getTrainingBlocksPermission: state => { return state.trainingBlocksPermission },
        getMailingsPermission: state => { return state.mailingsPermission },
        getTasksPermission: state => { return state.tasksPermission },
        getTimetablePermission: state => { return state.timetablesPermission },
        getUserPermission: state => { return state.userPermission },
        getHubConnection: state => { return state.hubConnection },
        getIsChatReconnecting: state => { return state.isChatReconnecting },
        getIsChatConnected: state => { return state.isChatConnected },
        getIsLeadOpen: state => { return state.isLeadOpen },
        getLeadCalling: state => { return state.leadCalling },
        getIsCallingButtonClicked: state => { return state.isCallingButtonClicked },
        getHasConnectionToLocal: state => { return state.hasConnectionToLocal },
    },
    mutations: {
        SET_LOAD: (state, data) => {
            state.load = data;
        },
        SET_ISAUTH: (state, data) => {
            state.isAuth = data;
        },
        SET_ISSHOWINTERNALSERVERERROR: (state, data) => {
            state.isShowInternalServerError = data;
        },
        SET_CURRENT_USER: (state, data) => {
            state.currentUser = data;
        },
        SET_SELECTED_CLUB_ID: (state, data) => {
            state.selectedClubId = data;
        },
        SET_NETWORK_ID: (state, data) => {
            state.networkId = data;
        },
        SET_ADD_ERROR: (state, data) => {
            if (!state.errors.includes(data)) state.errors.push(data);
        },
        SET_REMOVE_ERROR: (state, data) => {
            const index = state.errors.findIndex(x => x == data);
            if (index != -1) state.errors.splice(index, 1);
        },
        SET_ADD_SUCCESS_MESSAGES: (state, data) => {
            if (!state.successMessages.includes(data)) state.successMessages.push(data);
        },
        SET_REMOVE_SUCCESS_MESSAGES: (state, data) => {
            const index = state.successMessages.findIndex(x => x == data);
            if (index != -1) state.successMessages.splice(index, 1);
        },
        SET_ACTIVE_MENU: (state, data) => {
            state.activeMenu = data;
        },
        SET_ACTIVE_SUBMENU: (state, data) => {
            state.activeSubmenu = data;
        },
        SET_CLIENTS_PERMISSION: (state, data) => {
            state.clientsPermission = data;
        },
        SET_CONTRACTS_PERMISSION: (state, data) => {
            state.contractsPermission = data;
        },
        SET_SERVICE_CONTRACTS_PERMISSION: (state, data) => {
            state.serviceContractsPermission = data;
        },
        SET_ORDERS_PERMISSION: (state, data) => {
            state.ordersPermission = data;
        },
        SET_REFUND_ORDERS_PERMISSION: (state, data) => {
            state.refundOrdersPermission = data;
        },
        SET_TRAINING_BLOCKS_PERMISSION: (state, data) => {
            state.trainingBlocksPermission = data;
        },
        SET_MAILINGS_PERMISSION: (state, data) => {
            state.mailingsPermission = data;
        },
        SET_TASKS_PERMISSION: (state, data) => {
            state.tasksPermission = data;
        },
        SET_TIMETABLES_PERMISSION: (state, data) => {
            state.timetablesPermission = data;
        },
        SET_USER_PERMISSION: (state, data) => {
            state.userPermission = data;
        },
        SET_CURRENT_URL: (state, data) => {
            state.currentUrl = data;
        },
        SET_LAST_VIEWED_CLIENT: (state, data) => {
            const index = state.lastViewedClients.findIndex(x => x.id == data.id);
            if (index == 0) return;
            if (index != -1) state.lastViewedClients.splice(index, 1);
            if (state.lastViewedClients.length == 10) state.lastViewedClients.pop();
            state.lastViewedClients.unshift(data);

            localStorage.setItem("last-viewed-clients", JSON.stringify(state.lastViewedClients));
        },
        SET_LAST_VIEWED_CLIENTS: (state, data) => {
            state.lastViewedClients = data;
        },
        SET_REMOVE_LAST_VIEWED_CLIENT: (state, index) => {
            state.lastViewedClients.splice(index, 1);
            localStorage.setItem("last-viewed-clients", JSON.stringify(state.lastViewedClients));
        },
        SET_HUBCONNECTION: (state, data) => {
            state.hubConnection = data;
        },
        SET_IS_CHAT_RECONNECTING: (state, data) => {
            state.isChatReconnecting = data;
        },
        SET_IS_CHAT_CONNECTED: (state, data) => {
            state.isChatConnected = data;
        },
        SET_IS_LEAD_OPEN: (state, data) => {
            state.isLeadOpen = data;
        },
        SET_LEAD_CALLING: (state, data) => {
            state.leadCalling = data;
        },
        SET_IS_CALLING_BUTTON_CLICKED: (state, data) => {
            state.isCallingButtonClicked = data;
        },
        SET_HAS_CONNECTION_TO_LOCAL: (state, data) => {
            state.hasConnectionToLocal = data;
        },
    },
    actions: {
       
    },
});
