<div class="content_wrapper">
    <div :class="isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile'">
        <div class="filter_wrapper">
            <v-select class="small_filter_input mr-4 mt-2" v-model="filterNetwork" :label="'user.network'" :items="networks" @update:modelValue="applyFilter" clearable v-if="isAdmin"></v-select>
            <v-select class="small_filter_input mt-2" v-model="filterClub" :label="'user.club'" :items="clubs" @update:modelValue="applyFilter" clearable></v-select>
        </div>
        <div><p @click="clearAllFilters" class="clear_all_filters pl-2">{{$t('user.clear_all_filters')}}</p></div>
    </div>

    <button class="filter_button" @click="isShowFilters = !isShowFilters">{{ !isShowFilters ? $t('user.filter') : $t('user.hide')}} <img v-show="!isShowFilters" src="/assets/img/filter.svg" /></button>

    <div class="mt-3 total_wrapper">
        <p class="total pl-2" v-show="isLoad"></p>
        <p class="total pl-2" v-show="!isLoad">{{$t('user.total')}}: {{tableData.length}}</p>
    </div>

    <div :class="isShowFilters ? isAdmin ? 'mt-2 table_wrapper_with_open_filter_with_network' : 'mt-2 table_wrapper_with_open_filter' : 'mt-2 table_wrapper'">
        <v-datatable class="pointer" :columTemplates="columTemplates" :data="tableData" @clickOnRow="openUser"></v-datatable>
    </div>
</div>


    








