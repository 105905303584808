import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { store } from '../store'
import { getPermissions } from '../services/helper';
import Search from "@/components/Search/Search.vue";
import Clients from "@/components/Clients/Clients.vue";
import CreateClient from "@/components/Clients/Create/CreateClient.vue";
import ViewClient from "@/components/Clients/View/ViewClient.vue";
import CreateContract from "@/components/Contract/Create/CreateContract.vue";
import ViewContract from "@/components/Contract/View/ViewContract.vue";
import ViewOrder from "@/components/Order/ViewOrder.vue";
import ViewRefundOrder from "@/components/RefundOrder/ViewRefundOrder.vue";
import TimetableView from "@/components/Timetable/Timetable.vue";
import ViewMailings from "@/components/Mailings/Mailings.vue";
import ViewMailing from "@/components/Mailings/View/ViewMailing.vue";
import CreateMailing from "@/components/Mailings/Create/CreateMailing.vue";
import ViewChats from "@/components/Chats/ViewChats.vue";
import ViewTasks from "@/components/Tasks/ViewTasks.vue";
import ViewUser from "@/components/User/View/ViewUser.vue";
import UsersList from "@/components/User/Users.vue";

import ViewPage403 from "@/components/ServicePages/Page403.vue";
import ViewPage404 from "@/components/ServicePages/Page404.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: '/clients'
    },
    {
        path: "/tasks/:id?",
        name: "ViewTasks",
        component: ViewTasks
    },
    {
        path: "/order/:id?",
        name: "ViewOrder",
        component: ViewOrder
    },
    {
        path: "/refundorder/:id?",
        name: "ViewRefundOrder",
        component: ViewRefundOrder
    },
    {
        path: "/client/:id/contract/create",
        name: "CreateContract",
        component: CreateContract
    },
    {
        path: "/contract/:id?",
        name: "ViewContract",
        component: ViewContract
    },
    {
        path: "/client/create",
        name: "CreateClient",
        component: CreateClient
    },
    {
        path: "/client/:id/:tab?",
        name: "ViewClient",
        component: ViewClient
    },
    {
        path: "/clients/:tab?",
        name: "Clients",
        component: Clients
    },
    {
        path: "/search",
        name: "Search",
        component: Search
    },
    {
        path: "/timetable/:id?",
        name: "Timetable",
        component: TimetableView
    },
    {
        path: "/mailing/create",
        name: "CreateMailing",
        component: CreateMailing
    },
    {
        path: "/mailing/:id",
        name: "ViewMailing",
        component: ViewMailing
    },
    {
        path: "/mailings",
        name: "ViewMailings",
        component: ViewMailings
    },
    {
        path: "/chats",
        name: "ViewChats",
        component: ViewChats
    },
    {
        path: "/user/:id",
        name: "ViewUser",
        component: ViewUser
    },
    {
        path: "/users",
        name: "UsersList",
        component: UsersList
    },
    {
        path: "/forbidden",
        name: "ViewPage403",
        component: ViewPage403
    },
    {
        path: "/:pathMatch(.*)*",
        name: "ViewPage404",
        component: ViewPage404
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
});

router.beforeEach(async (to, from, next) => {
    const url = to.path.split('/');

    store.commit('SET_CURRENT_URL', to.path);
    store.commit('SET_ISSHOWINTERNALSERVERERROR', false);

    if (url[1] == "contract" || url[1] == "order" || url[1] == "refundorder") {
        store.commit('SET_ACTIVE_MENU', "/clients");
    } else {
        store.commit('SET_ACTIVE_MENU', "/" + url[1]);
    }

    store.commit('SET_ACTIVE_SUBMENU', url.length > 2 ? "/" + url[2] : "");

    await getPermissions(url[1]);

    next();
})

export default router;