<template src="./copyingAlert.html">
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'CopyingAlert',
        props: ["alertCopyingWeek", "alertPastedWeek"],
        methods: {
            close: function () {
                this.$emit('cancel');
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .alert_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .alert_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: showAlert 0.6s linear;
    }

    .alert_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .alert_text {
        margin-top: 12px;
        line-height: 22px;
        padding: 0px 24px;
    }

    .alert_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
        justify-content: center;
    }

    .button_space {
        margin-left: 16px;
    }

    .alert_p {
        text-align: center;
        margin-top: 4px;
        margin-bottom: 4px;
        font-weight: bold;
    }

    @media(max-width: 500px) {
        .alert_body {
            max-width: 98%;
        }

        .alert_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }

    @keyframes showAlert {
        0% {
            top: -100%;
        }

        100% {
            top: 50%;
        }
    }
</style>