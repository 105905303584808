<div class="create_new_lead_wrapper">
    <div class="create_new_lead_body" v-click-outside="close">
        <div class="create_new_lead_body_title">{{$t('task.create.create_task')}}</div>
        <div class="create_new_lead_content_wrapper">
            <div class="create_new_lead">

                <div class="two_block_wrapper">
                    <div class="left_block mb-4">
                        <v-input v-model="phone" placeholder="+7 (000) 000-00-00" v-maska:[options] :label="$t('clients.view.phone')" height="22" :error="isShowExistError" :errorText="phoneErrorText" @blur="checkLeadExist" @focus="phoneErrorText = ''; isShowExistError = false" clearable></v-input>
                        <span v-show="isShowExistError" class="exist_phone_error">{{$t('task.create.phone_exist_error')}} <a @click="clickOnRedirect">{{$t('task.create.go_over')}}</a></span>
                    </div>
                    <div class="right_block mb-4"><v-select class="w-100" v-model="selectedClub" :label="'task.create.club'" @update:modelValue="checkLeadExist" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''"></v-select></div>
                </div>

                <div class="three_block_wrapper">
                    <div class="left_two_blocks">
                        <div class="left_block mb-4"><v-input v-model="name" :label="$t('task.create.name')" height="22" :errorText="nameErrorText" @focus="nameErrorText = ''" clearable></v-input></div>
                        <div class="right_block mb-4"><v-select class="w-100" v-model="selectedSex" :label="'task.create.sex'" :items="sex"></v-select></div>
                    </div>
                    <div class="right_one_block mb-4"><v-input v-model="mail" :label="$t('task.create.mail')" height="22" clearable></v-input></div>
                </div>

                <div class="two_block_wrapper">
                    <div class="left_block mb-4"><v-select class="w-100" v-model="selectedStatus" :label="'task.create.status'" :items="statuses" clearable></v-select></div>
                    <div class="right_block mb-4"><v-select class="w-100" v-model="selectedManagers" :label="'task.create.manager'" :items="managers" clearable></v-select></div>
                </div>

                <div class="two_block_wrapper">
                    <div class="left_block mb-4"><v-select class="w-100" v-model="selectedNextStep" :label="'task.create.next_step'" :items="nextSteps" clearable></v-select></div>
                    <div class="right_block mb-4">
                        <div class="position-relative">
                            <v-input v-model="date" :label="$t('task.create.date')" :inputType="'datetime-local'" height="22" :errorText="dateErrorText" @focus="dateErrorText = ''" clearable></v-input>
                            <button class="now_button" @click="setNowTime"><img class="" src="/assets/img/clock.svg"></button>
                        </div>
                    </div>
                    
                </div>

                <v-textarea v-model="message" :label="$t('task.create.comment')"></v-textarea>

            </div>
            <div class="create_new_lead_buttons_wrapper">
                <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('task.create.create') }}</button>
                <button class="secondary_button button_space" @click="$emit('close')">{{$t('task.create.cancel')}}</button>
            </div>
        </div>
        
    </div>
</div>

