<template src="./viewGroupTraining.html">
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, getFormatedDate, getFormatedAmount, addPhoneMask, checkIfClickInsideAlert, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';
    import { RegisteredClient } from '../../../models/Timetable';
    import RegisterClient from './RegisterClient/RegisterClient.vue';
    import RetryToPay from '../RetryToPay/RetryToPay.vue';
    
    interface ClientForRetryToPay {
        id: number,
        trainingId: number,
        hasPaymentMean: boolean,
        hasTrainingBlock: boolean
    }

    interface ComponentModel {
        selectedTrainer: SelectModel | null,
        isButtonDisabled: boolean,
        hasUpdatePermission: boolean,
        hasDeletePermission: boolean,
        hasAddClientPermission: boolean,
        hasRetryPermission: boolean,
        isShowRegistered: boolean,
        isShowAlert: boolean,
        isShowRegisterClient: boolean,
        isShowRetry: boolean,
        clientForRetryToPay: ClientForRetryToPay | null,
        isPassed: boolean
    }

    export default defineComponent({
        name: 'ViewGroupTraining',
        props: ["training"],
        emits: ["close", "updateTraining"],
        data(): ComponentModel {
            return {
                selectedTrainer: null,
                isButtonDisabled: false,
                hasUpdatePermission: false,
                hasDeletePermission: false,
                hasAddClientPermission: false,
                hasRetryPermission: false,
                isShowRegistered: true,
                isShowAlert: false,
                isShowRegisterClient: false,
                isShowRetry: false,
                clientForRetryToPay: null,
                isPassed: false
            }
        },
        methods: {
            redirectToClient: function(clientId: number){
                this.$router.push(`/client/${clientId}`);
            },
            clickOutside: function (event: any) {
                if (!checkIfClickInsideAlert(event.target) && !this.checkIfClickInsideRegisterClient(event.target)) this.close();
            },
            close: function () {
                this.$emit("close", false);
            },
            save: function () {
                if (!this.selectedTrainer) return;

                this.isButtonDisabled = true;

                axiosRequests.patch(editUrl(Routes.updateGroupTraining, this.training.trainingId), { trainerId: this.selectedTrainer.id })
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            deleteTraining: function () {
                this.isShowAlert = false;
                this.isButtonDisabled = true;

                axiosRequests.delete(editUrl(Routes.deleteGroupTraining, this.training.trainingId))
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            },
            checkIfClickInsideRegisterClient: function (target: any) {
                if (target.className == "popup_wrapper") {
                    return true;
                } else if (target.className != "popup_wrapper" && target.parentElement) {
                    return this.checkIfClickInsideRegisterClient(target.parentElement);
                }

                return false;
            },
            openRetryWindow: function (client: RegisteredClient) {
                if(client.isPayed) return;
                if(!this.hasRetryPermission) return;

                this.clientForRetryToPay = { id: client.id, trainingId: this.training.trainingId, hasPaymentMean: client.hasPaymentMean, hasTrainingBlock: client.hasTrainingBlock };
                this.isShowRetry = true;
            },
            closeWindow: function (isNeedToUpdate: boolean) {
                if (isNeedToUpdate) this.$emit("updateTraining", this.training.trainingId);

                this.isShowRegisterClient = false;
                this.isShowRetry = false;
                this.clientForRetryToPay = null;
            },
            getFormatedDate,
            getFormatedAmount,
            addPhoneMask
        },
        components: { registerClient: RegisterClient, retryToPay: RetryToPay },
        async beforeMount() {
            let timetablePermission = this.$store.getters.getTimetablePermission;

            if (timetablePermission.length == 0) timetablePermission = await retryGetPermissions("timetable");

            if (!timetablePermission?.includes('timetables/trainings/{id}')) this.$router.push('/forbidden');

            if (!this.training) {
                this.$emit("close");
                return;
            }

            this.isPassed = new Date(this.training.trainingStartDate) < new Date();

            this.hasUpdatePermission = timetablePermission?.includes('timetables/group-trainings/{id}/update');
            this.hasDeletePermission = timetablePermission?.includes('timetables/group-trainings/{id}/delete');
            this.hasAddClientPermission = timetablePermission?.includes('timetables/group-trainings/register-client') && !this.isPassed;
            this.hasRetryPermission = timetablePermission?.includes('timetables/trainings/retry-to-pay');

            this.selectedTrainer = this.training.trainers.find(x => x.id == this.training.trainerId) ?? null;

        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .view_training_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .view_training_body {
        background-color: white;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
        min-height: 320px;
    }

    .view_training_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 60px 16px 24px;
        position: relative;
        border-radius: 24px 24px 0px 0px;
    }

    .view_training {
        margin-top: 12px;
        padding: 0px 24px;
        display: flex;
    }

    .view_training_info_wrapper {
        width: 365px;
    }

    .block_label {
        color: #65656F;
        margin-top: 0px;
        margin-bottom: 8px;
    }

    .blocks_wrapper {
        display: flex
    }

    .block{
        width: 50%;
        margin-bottom: 16px;
    }

    .not_accept {
        background-color: #b9b94b;
        padding: 4px 10px;
        border-radius: 12px;
        color: yellow;
        width: fit-content;
    }

    .accept {
        background-color: #def8e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: green;
        width: fit-content;
    }

    .reject {
        background-color: #ffe5e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: red;
        width: fit-content;
    }

    .view_training_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        margin-bottom: 0px;
    }

    .view_training_buttons_wrapper_with_add {
        margin-top: 39px;
        display: flex;
        margin-bottom: 0px;
    }

    .save_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
        margin-right: 16px;
        margin-top: 6px;
        color: white;
        min-width: 150px;
        height: min-content;
    }

    .delete_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #FBE0E0;
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
        color: black;
        min-width: 150px;
        height: min-content;
    }

    .save_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .delete_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .view_training_clients_wrapper {
        margin-left: 12px;
    }

    .view_training_clients {
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        overflow: hidden;
        height: 242px;
        width: 365px;
    }

    .view_training_clients_title {
        display: flex;
        background-color: #f6f7f8;
        padding: 0px 24px;
        border-radius: 12px 12px 0px 0px;
    }

    .view_training_clients_body {
        overflow: auto;
        padding: 10px;
        height: 180px;
    }

    .client_wrapper {
        display: flex;
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        padding: 5px 10px;
        width: calc(100% - 22px);
        margin-top: 4px;
        flex-flow: wrap;
    }

    .client_wrapper:first-child {
        margin-top: 0px;
    }

    .client_photo {
        background-size: cover;
        width: 35px;
        height: 35px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
    }

    .client_info_wrapper {
        margin-left: 12px;
        display: flex;
        flex-flow: column;
        width: calc(100% - 142px);
    }

    .client_wait_list_info_wrapper {
        margin-left: 12px;
        display: flex;
        flex-flow: column;
        width: calc(100% - 50px);
    }

    .client_name {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
    }

    .client_phone {
        margin: 0px;
        color: #65656F;
        font-size: 14px;
    }

    .client_pay_wrapper {
        display: flex;
        flex-flow: column;
        width: 93px;
    }

    .client_payed {
        display: flex;
        color: #65656F;
        font-size: 14px;
        margin-top: 0px;
        justify-content: end;
    }

    .client_not_payed {
        display: flex;
        color: #65656F;
        font-size: 14px;
        margin-top: 0px;
        justify-content: end;
        text-decoration: underline;
        cursor: pointer
    }

    .payed {
        background-color: green;
    }

    .not_payed {
        background-color: red;
    }

    .client_pay_amount {
        display: flex;
        font-size: 14px;
        margin-top: 1px;
        justify-content: end;
        margin-bottom: 0px;
    }

    .add_client {
        color: #00a3ba;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 6px;
        margin-bottom: 14px;
        display: flex;
        margin-right: 4px;
        justify-content: end;
    }

    .view_training_clients_title_element {
        padding: 12px 8px;
        cursor: pointer;
    }

    .active {
        border-bottom: 1px solid #21A7D1;
        color: #21A7D1;
    }

    .close_button {
        position: absolute;
        right: 24px;
        top: 19px;
        cursor: pointer;
    }

    .view_training_clients_title_element_first{
        margin-right: 12px;
    }

    @media(max-width: 840px) {
        .view_training_body {
            width: 98%;
        }

        .view_training{
            display: block
        }

        .view_training_info_wrapper {
            width: 100%;
        }

        .view_training_buttons_wrapper {
            margin-top: 0px;
            margin-bottom: 10px;
            justify-content: space-between;
        }

        .view_training_buttons_wrapper_with_add {
            margin-top: 0px;
            margin-bottom: 10px;
            justify-content: space-between;
        }

        .save_button {
            margin-right: 0px;
            width: 49%;
        }

        .delete_button{
            width: 49%;
        }

        .view_training_clients_wrapper{
            margin-left: 0px;
        }

        .view_training_clients {
            width: 100%;
        }

        .add_client {
            display: flex;
            padding: 7px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #00a3ba;
            font-size: 16px;
            line-height: 24px;
            color: white;
            min-width: 150px;
            height: -moz-min-content;
            height: min-content;
            text-decoration: none;
            margin-bottom: 14px;
            margin-top: 12px;
            margin-right: 0px;
        }
    }

    @media(max-width: 500px) {
        .view_training {
            max-height: calc(100vh - 75px);
            overflow: auto;
        }

        .view_training_buttons_wrapper {
            display: block;
            justify-content: unset;
        }

        .view_training_buttons_wrapper_with_add {
            display: block;
            justify-content: unset;
        }

        .save_button {
            margin-bottom: 12px;
            width: 100%;
        }

        .delete_button {
            width: 100%;
        }

        .blocks_wrapper {
            display: block;
        }

        .block {
            width: 100%;
        }

        .view_training_clients {
            border: none;
            max-height: 242px;
            min-height: 20px;
            height: fit-content;
        }

        .view_training_clients_title {
            padding: 0px;
            background-color: white;
            border-radius: 12px;
            border: 1px solid #D5D5D5;
        }

        .view_training_clients_title_element_first {
            margin-right: 0px;
        }

        .view_training_clients_title_element {
            padding: 12px;
            cursor: pointer;
            display: flex;
            margin: auto;
            width: 50%;
            font-size: 14px;
            justify-content: center;
        }

        .active {
            border-bottom: none;
            background-color: #21A7D1;
            color: white;
            border-radius: 12px;
            margin: 0px;
        }

        .view_training_clients_body {
            margin-top: 10px;
            padding: 0px 4px;
            height: fit-content;
            max-height: 180px;
            min-height: 20px;
        }

        .client_info_wrapper {
            width: calc(100% - 50px);
        }

        .client_pay_wrapper {
            display: flex;
            flex-flow: row-reverse;
            width: 100%;
            justify-content: space-between;
            margin-top: 8px;
        }

        .client_payed {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
            width: fit-content;
        }

        .client_payed .dot{
            display: none;
        }

        .client_not_payed {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
            width: fit-content;
        }

        .client_not_payed .dot {
            display: none;
        }

        .client_pay_amount {
            font-weight: 600;
            margin-top: 4px;
            margin-left: 5px;
        }
    }
</style>