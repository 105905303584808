<template src="./viewUser.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, addPhoneMask, getFormatedDate, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { User } from '../../../models/User';
    import AddPhoto from '../../AddPhoto/AddPhoto.vue';
    import LinkPassCard from '../../PassCard/LinkPassCard/LinkPassCard.vue';
    import PassCardAlert from '../../PassCard/PassCardAlert/PassCardAlert.vue';

    interface ComponentModel {
        userId: number,
        options: any,
        user: User | null,
        showAddPhoto: boolean,
        showlinkPassCard: boolean,
        abortRequest: AbortController | null,
        isShowAwaitingCard: boolean,
        passCardAlertTitle: string,
        hasConnection: boolean,
        hasUploadPhotoPermission: boolean
    }

    export default defineComponent({
        name: 'ViewUser',
        data(): ComponentModel {
            return {
                userId: 0,
                options: {
                    mask: "+7 (###) ###-##-##"
                },
                user: null,
                showAddPhoto: false,
                showlinkPassCard: false,
                abortRequest: null,
                isShowAwaitingCard: false,
                passCardAlertTitle: "",
                hasConnection: false,
                hasUploadPhotoPermission: false
            }
        },
        methods: {
            getUser: async function () {
                await axiosRequests.get(editUrl(Routes.getUser, this.userId))
                    .then(
                        (data: User) => {
                            this.user = data;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                return this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            closeAddPhotoWindow: function (isNeedToUpdateUser: boolean) {
                this.showAddPhoto = false;

                if (isNeedToUpdateUser) this.getUser();
            },
            createPassCard: function (clubId: number) {
                this.showlinkPassCard = false;

                if(!clubId) return;

                this.passCardAlertTitle = "pass_card.pass_card_alert.linking_card";
                this.isShowAwaitingCard = true;

                this.abortRequest = new AbortController();

                axiosRequests.post(editUrl(Routes.createPassCardLocal, undefined, true), { clubId: clubId, clientId: null, userId: this.userId }, this.abortRequest.signal)
                     .then(
                         () => {
                             this.isShowAwaitingCard = false;

                              if (this.user) this.user.hasPassCard = true;

                              this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'succee_messages.pass_card_linked');
                         }
                     )
                     .catch(
                         (err: any) => {
                             this.isShowAwaitingCard = false;

                             if (!err?.status) return;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         })

            },
            closeAwaitingCard: function () {
                this.isShowAwaitingCard = false;
                this.abortRequest?.abort();

                axiosRequests.post(editUrl(Routes.cancelCurrentOperation, undefined, true)).then(() => {}).catch(() => {})
            },
            deactivatePassCard: function () {
                this.isShowAwaitingCard = true;

                this.passCardAlertTitle = "pass_card.pass_card_alert.untie_card";
                
                this.abortRequest = new AbortController();

                axiosRequests.patch(editUrl(Routes.deactivatePassCardLocal, undefined, true), { clientId: null, userId: this.userId }, this.abortRequest.signal)
                    .then(
                        () => {
                            this.isShowAwaitingCard = false;

                            if (this.user) this.user.hasPassCard = false;

                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'succee_messages.pass_card_ublinked');
                        }
                    )
                    .catch(
                        (err: any) => {
                            this.isShowAwaitingCard = false;

                            if (!err?.status) return;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {

                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        })
            },
            getFormatedDate,
            addPhoneMask
        },
        components: { addPhoto: AddPhoto, linkPassCard: LinkPassCard, passCardAlert: PassCardAlert },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;
                    this.userId = id;

                    await this.getUser();
                },
                deep: true,
                immediate: true
            }
        },
        async beforeMount() {
            let userPermission = this.$store.getters.getUserPermission;

            if (userPermission.length == 0) userPermission = await retryGetPermissions("user");

            if (!userPermission?.includes('users/{id}')) this.$router.push('/forbidden');

            this.hasUploadPhotoPermission = userPermission.includes('users/{id}/upload-photo');

            this.hasConnection = this.$store.getters.getHasConnectionToLocal;
        }
    });
</script>

<style scoped>
    .user_wrapper {
        overflow: hidden;
        max-height: calc(100vh - 184px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .tab_body {
        overflow: auto;
        padding-right: 5px;
    }

    .button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button_back {
        width: 107px;
        margin-bottom: 12px;
    }

    .back_button_img {
        margin-right: 7px;
        margin-top: 1px;
        margin-left: -10px;
    }

    .profile_wrapper{
        display: flex;
    }

    .photo_wrapper{
        display: block;
        margin-right: 16px;
    }

    .photo {
        width: 172px;
        height: 172px;
        background-size: cover;
        margin: auto;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
    }

    .photo_text {
        text-align: center;
        line-height: normal;
        color: #65656F;
        margin-bottom: 8px;
    }

    .photo_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 175px;
        margin: 0px 8px;
    }

    .data_wrapper{
        width: 100%;
    }

    .img_block {
        margin: 0px 8px;
    }

    .user_info_wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid #D5D5D5;
        border-radius: 24px;
        margin-top: 18px;
        overflow: hidden;
    }

    .user_info_title {
        padding: 16px 24px;
        display: flex;
        background-color: #f6f7f8;
        justify-content: space-between;
        width: calc(100% - 48px);
    }

    .user_info_title_contract {
        padding: 16px 24px;
        display: flex;
        background-color: #f6f7f8;
        justify-content: space-between;
        width: calc(100% - 48px);
    }

    .user_info_fi {
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }

    .user_info_phone {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }


    .user_info_body {
        padding: 0px 24px 16px 24px;
        display: flex;
    }

   
    .block_row {
        display: flex;
        justify-content: space-between;
    }

    .one_block_wrapper {
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    .two_block_wrapper {
        display: flex;
        flex-flow: column;
        width: 50%;
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .first_block_wrapper{
        width: calc(50% - 8px);
        padding-right: 8px;
        display:flex;
        flex-flow:column;
    }

    .second_block_wrapper {
        width: calc(50% - 8px);
        padding-left: 8px;
        display: flex;
        flex-flow: column;
    }

    .dot_active {
        background-color: green;
        margin-top: 5.5px;
    }

    .dot_disable {
        background-color: black;
        margin-top: 5.5px;
    }

    .in_club_status {
        margin: 0;
        white-space: nowrap;
    }

    .club_name_info {
        width: unset;
    }

    .camera {
        cursor: pointer;
        height: 36px;
        width: 36px;
        min-width: 36px;
        border-radius: 50%;
        margin-left: 15px;
        background-color: white;
        border: 1px solid #878787;
        position: absolute;
        right: -10px;
        top: 20px;
    }

    .camera_img {
        height: 18px;
        margin-top: 4px;
    }

    .top_buttons_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .link_card_button {
        color: #00a3ba;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 5px;
    }

    .untie_card {
        color: #df2793;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 12px;
        margin-top: 5px;
    }

    @media(max-width: 1200px) {
        
        .profile_wrapper {
            display: block;
        }

        .img_wrapper {
            display: flex;
            justify-content: center;
        }

        .photo_buttons_wrapper {
            display: flex;
            justify-content: center;
        }

        .user_info_fi {
            font-size: 26px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width: unset;
            line-height: 28px;
        }

        .user_info_phone {
            font-size: 18px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width: unset;
            margin-top: 2px;
        }

        .camera {
            right: -7px;
            top: 30px;
        }
    }

    @media(max-width: 900px) {
        .block_row {
            display: block;
        }

        .two_block_wrapper {
            width: 100%;
        }
    }

    @media(max-width: 768px) {
        .user_wrapper {
            max-height: calc(100vh - 168px);
        }      
    }

    @media(max-width: 650px) {
        .user_info_body {
            display: block;
        }

        .first_block_wrapper {
            width: 100%;
            padding-right: 0px;
        }

        .second_block_wrapper {
            width: 100%;
            padding-left: 0px;
        }

        .club_name_wrapper{
            margin-top: 12px;
        }

        .user_wrapper {
            max-height: calc(100vh - 130px);
        }
    }

    @media(max-width: 500px) {
        .photo {
            width: 125px;
            height: 125px;
        }

        .photo_buttons_wrapper{
            display: none;
        }

        .user_info_fi {
            font-size: 17px;
            line-height: 17px;
        }

        .user_info_phone {
            font-size: 14px;
        }

        .dot {
            width: 6px;
            height: 6px;
            margin-top: 3px;
        }

        .camera {
            display: none;
        }
    }
</style>