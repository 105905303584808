<template src="./changeLeadNextStepDate.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';

    interface ComponentModel {
        nextStepDate: string | null
        minDate: string | null
        maxDate: string | null,
        dateErrorText: string
    }

    export default defineComponent({
        name: 'ChangeLeadNextStepDate',
        props: ["data"],
        emits: ["close"], 
        data(): ComponentModel {
            return {
                nextStepDate: null,
                minDate: null,
                maxDate: null,
                dateErrorText: ""
            }
        },
        methods: {
            close: function () {
                this.$emit("close", this.data.nextStepDate);
            },
            saveDate: function () {
                if (!this.nextStepDate) {
                    this.dateErrorText = this.$t('task.change_date.date_error_text');
                    return;
                }

                this.$emit("close", this.nextStepDate);
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        beforeMount() {
            this.nextStepDate = this.data.nextStepDate;
            this.minDate = this.data.minDate;
            this.maxDate = this.data.maxDate;
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);

            setTimeout(() => {
                try {
                    const changeDateInpute: any = this.$refs.changeDateInpute;

                    changeDateInpute.$el?.querySelector("input")?.showPicker();
                } catch {
                    return;
                }
            }, 610);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .change_next_step_date_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
    }

    .change_next_step_date_body {
        background-color: white;
        position: absolute;
        top: 50%;
        width: 385px;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
        max-height: 98%;
        display: flex;
        flex-flow: column;
    }

    .change_next_step_date_body_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .change_next_step_date_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .change_next_step_date_inpute{
        width: calc(100% - 48px) !important;
        padding: 0 24px;
        margin-top: 12px;
    }

    .button_space {
        margin-left: 16px;
    }

    @media(max-width: 500px) {
        .change_next_step_date_body {
            width: 98%;
        }

        .button_space {
            margin-left: 0px;
        }

        .change_next_step_date_buttons_wrapper {
            display: block;
        }
    }
</style>