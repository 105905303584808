import { createApp } from 'vue';
import App from './App.vue';
import vClickOutside from "click-outside-vue3";
import { i18n } from './i18n';
import router from './router';
import { store, key } from './store';
import { vMaska } from "maska";
import { quillEditor, Quill } from 'vue3-quill';
import HighchartsVue from 'highcharts-vue';
import VSelect from './components/GUI/Select.vue';
import VCheckBox from './components/GUI/CheckBox.vue';
import VDataTable from './components/GUI/DataTable.vue';
import VInput from './components/GUI/Input.vue';
import VAlert from './components/GUI/Alert.vue';
import VTextarea from './components/GUI/Textarea.vue';
import VRadio from './components/GUI/Radio.vue';
import VScrollableMenu from './components/GUI/ScrollableMenu.vue';

import 'quill-emoji/dist/quill-emoji.css';
import * as Emoji from "quill-emoji";

Quill.register("modules/emoji", Emoji);

createApp(App).use(router)
    .use(store, key)
    .use(i18n)
    .use(HighchartsVue as any)
    .use(quillEditor)
    .component('v-select', VSelect)
    .component('v-input', VInput)
    .component('v-checkbox', VCheckBox)
    .component('v-datatable', VDataTable)
    .component('v-scrollable-menu', VScrollableMenu)
    .component('v-alert', VAlert)
    .component('v-textarea', VTextarea)
    .component('v-radio', VRadio)
    .use(vClickOutside)
    .directive("maska", vMaska)
    .mount('#app')