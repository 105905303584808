<createTask v-if="isShowCreate" :managers="managers" :statuses="statuses" :callingPhone="callingPhone" @close="closeLeadPopup" @openLead="openLead"></createTask>
<viewTask v-if="isShowView" :managers="managers" :statuses="statuses" :leadId="popupLeadId" @close="closeLeadPopup" ></viewTask>
<changeLeadNextStepDate v-if="isShowChangeDate" :data="changeNextStepDateModel" @close="closeChangeDate"></changeLeadNextStepDate>

<div class="content_wrapper wrapper">
    <div class="new_lead_wrapper" v-show="!isShowFilters">
        <button class="new_task_button" @click="openCreateWindow">{{$t('task.add_task')}}</button>
        <v-input class="search" v-model="searchText" :label="$t('task.search')" :placeholder="$t('task.search_placeholder')" height="24" inputType="text" @update:modelValue="search" clearable></v-input>
    </div>

    <div :class="isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile'">
        <div class="filter_wrapper pt-2">
            <div class="filter_left_wrapper">
                <v-select v-model="filterStatus" :label="'task.status'" class="w-100 margin_right" :items="statuses" @update:modelValue="applyFilter" clearable></v-select>
                <v-select v-model="filterManager" :label="'task.manager'" class="w-100 margin_top" :items="managers" @update:modelValue="applyFilter" clearable></v-select>
            </div>

            <div class="filter_right_wrapper">
                <v-select class="w-100 margin_right" v-model="filterNextStep" :label="'task.next_step'" :items="nextSteps" @update:modelValue="applyFilter" clearable></v-select>
                <v-input class="w-100 margin_top" v-model="filterDate" :label="$t('task.date')" inputType="date" height="22" @update:modelValue="applyFilter" clearable></v-input>
            </div>

            <div class="w-100 mt-3">
                <v-checkbox v-model="isShowClosed" :label="$t('task.show_closed')" @click="clickOnIsShowClosed"></v-checkbox>
            </div>

        </div>
        <div><p @click="clearAllFilters" class="clear_all_filters">{{$t('task.clear_all_filters')}}</p></div>
    </div>

    <button class="filter_button" @click="isShowFilters = !isShowFilters">{{ !isShowFilters ? $t('task.filter') : $t('task.hide')}} <img v-show="!isShowFilters" src="/assets/img/filter.svg" /></button>

    <div class="leads_list_wrapper" id="leads_list_wrapper">

        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.unprocessed')}}<span v-show="unprocessedLeadsCount > 0">({{unprocessedLeadsCount}})</span></p>
            </div>
            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="unprocessedLeads" @scroll="scrollKanban">
                <draggable :list="unprocessedLeads" group="lead" item-key="leadId" :disabled="true" :sort="false" class="h-100" id="unprocessedLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'unprocessedLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>

        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.overdue')}}<span v-show="overdueLeadsCount > 0">({{overdueLeadsCount}})</span></p>
            </div>
            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="overdueLeads" @scroll="scrollKanban">
                <draggable :list="overdueLeads" group="lead" :disabled="isMobile" item-key="leadId" :sort="false" class="h-100" @end="endMove" :forceFallback="true" id="overdueLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'overdueLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>

        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.today')}}<span v-show="todayLeadsCount > 0">({{todayLeadsCount}})</span></p>
            </div>

            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="todayLeads" @scroll="scrollKanban">
                <draggable :list="todayLeads" group="lead" :disabled="isMobile" item-key="leadId" :sort="false" class="h-100" @end="endMove" :forceFallback="true" id="todayLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'todayLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>


        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.tomorrow')}}<span v-show="tomorrowLeadsCount > 0">({{tomorrowLeadsCount}})</span></p>
            </div>

            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="tomorrowLeads" @scroll="scrollKanban">
                <draggable :list="tomorrowLeads" group="lead" :disabled="isMobile" item-key="leadId" :sort="false" class="h-100" @end="endMove" :forceFallback="true" id="tomorrowLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'tomorrowLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>

        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.this_week')}}<span v-show="thisWeekLeadsCount > 0">({{thisWeekLeadsCount}})</span></p>
            </div>

            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="thisWeekLeads" @scroll="scrollKanban">
                <draggable :list="thisWeekLeads" group="lead" :disabled="isMobile" item-key="leadId" :sort="false" class="h-100" @end="endMove" :forceFallback="true" id="thisWeekLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'thisWeekLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>

        <div class="lead_list_wrapper">
            <div class="lead_list_title">
                <p class="m-0">{{$t('task.later')}}<span v-show="laterLeadsCount > 0">({{laterLeadsCount}})</span></p>
            </div>

            <div class="lead_list" :class="{open_filter: isShowFilters}" param-kanban="laterLeads" @scroll="scrollKanban">
                <draggable :list="laterLeads" group="lead" :disabled="isMobile" item-key="leadId" :sort="false" class="h-100" @end="endMove" :forceFallback="true" id="laterLeads">
                    <template #item="{element}">
                        <div :class="element.leadClass" :style="{ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }" @dblclick="openLead(element.leadId)" v-show="element.isShow">
                            <div class="lead_body">
                                <p class="lead_name">{{ element.name }}</p>
                                <p class="lead_phone">{{ element.phone }}</p>
                                <div class="lead_next_step_date" @click="openChangeDateWindow(element, 'laterLeads')">{{ element.nextStepDateText }} </div>

                                <div v-if="element.isClient" class="lead_contract_wrapper">
                                    <div class="lead_contract_title">
                                        <label class="lead_label">{{ element.contractLabel }}</label>
                                        <div class="lead_contract_status"><div :class="element.contractDotClass"></div>{{ element.contractStatus }}</div>
                                    </div>
                                    <p class="lead_contact_name">{{ element.contractName }}</p>
                                </div>
                                <div class="lead_comment_wrapper">
                                    <label class="lead_label">{{ element.commentLabel }}</label>
                                    <p class="lead_comment">{{ element.comment }}</p>
                                </div>

                                <div class="lead_next_step_wrapper">
                                    <label class="lead_label">{{ element.labelNextStep }}</label>
                                    <div class="lead_next_step"> {{ element.nextStep }}</div>
                                </div>
                            </div>

                            <p class="lead_status" v-show="element.leadStatus" :style="{ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor }">{{ element.leadStatus }}</p>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>

</div>



