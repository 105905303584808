<template src="./findClients.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, addPhoneMask } from '../../../services/helper';
    import { FindClientsOnTrainingResponse } from '../../../models/Timetable';
    import { Routes } from '../../../const';

    interface ComponentModel {
        searchText: string,
        clients: FindClientsOnTrainingResponse[],
        activeClient: FindClientsOnTrainingResponse | null,
        isLoading: boolean
    }

    export default defineComponent({
        name: 'FindClients',
        props: ["isGroup", "сlubId", "trainerId", "beginDate", "trainingClassId"],
        data(): ComponentModel {
            return {
                searchText: "",
                clients: [],
                activeClient: null,
                isLoading: true
            }
        },
        methods: {
            search: async function () {
                this.isLoading = true;
                this.activeClient = null;
                this.$emit("selectClient", null);
                this.clients = [];

                if (!this.searchText) return;

                let url: string;

                if (!this.beginDate) {
                    this.$store.commit('SET_ADD_ERROR', "error.training_start_date_empty");
                    return;
                }

                if (this.isGroup) {
                    url = `${Routes.findGroupTrainingClients}?TrainingClassId=${this.trainingClassId}&TextToSearch=${this.searchText}&BeginDate=${this.beginDate}`;
                } else {
                    url = `${Routes.findPersonalTrainingClients}?ClubId=${this.сlubId}&TrainerId=${this.trainerId}&TextToSearch=${this.searchText}&BeginDate=${this.beginDate}`;
                }
                
                await axiosRequests.get(editUrl(url))
                    .then(
                        (data: FindClientsOnTrainingResponse[]) => {
                            this.clients = data;

                            this.isLoading = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoading = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clickOnClient: function (client: any) {
                this.activeClient = client;
                this.$emit("selectClient", client);
            },
            addPhoneMask
        },
    });
</script>

<style scoped>
    .find_clients_text {
        margin-top: 0px;
        margin-bottom: 8px;
        font-weight: 600;
    }

    .find_clients_wrapper {
        overflow: auto;
        max-height: 165px;
        padding-right: 5px;
        display: flex;
        flex-flow: column;
        overflow-x: hidden;
    }

    .client_wrapper {
        display: flex;
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        padding: 5px 10px;
        width: calc(100% - 21px);
        cursor: pointer;
        margin-top: 4px;
        flex-flow: wrap;
    }

    .client_wrapper.active{
         border: 1px solid #48aab8 !important;
    }

    .client_wrapper:first-child{
        margin-top: 0px;
    }

    .client_photo {
        background-size: cover;
        width: 35px;
        height: 35px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
    }

    .client_info_wrapper {
        margin-left: 12px;
        display: flex;
        flex-flow: column;
        width: calc(100% - 70px);
    }

    .client_name {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
    }

    .client_phone {
        margin: 0px;
        color: #65656F;
        font-size: 14px;
    }


    .radio {
        height: 12px;
        width: 12px;
        border: 1.5px solid #D5D5D5;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 6px;
        margin-top: 10px;
    }

    .radio.active_radio {
        border: 1.5px solid #48aab8 !important;
    }

    .radio_child {
        margin: 2px;
        background-color: #48aab8;
        height: 8px;
        width: 8px;
        border-radius: 50%;
    }

    @media(max-width: 500px) {
        .find_clients_wrapper {
            width: 100%;
        }
    }
</style>