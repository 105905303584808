<v-alert v-model="showAlert" :title="'contract.view.service_delete'" :text="'contract.view.are_you_sure'" :successButtonText="$t('contract.view.confirm')" :cancelButtonText="$t('contract.view.cancel')" @success="deleteService" @cancel="showAlert = false"></v-alert>
<addService v-if="showAddService" :availableServices="contract.availableServices" :currencySign="contract.currencySign" :contractId="contract.id" :hasClientPaymentMean="contract.clientPaymentMean" @close="showAddService = false" @update="updateContract"></addService>
<addAdditionalFreezingDays v-if="showAdditionalFreezingDays" :availableServices="contract.availableAdditionalFreezingDays" :currencySign="contract.currencySign" :contractId="contract.id" :hasClientPaymentMean="contract.clientPaymentMean" @close="showAdditionalFreezingDays = false" @update="updateContract"></addAdditionalFreezingDays>
<retryToPayService v-if="showRetryToPayService" :serviceContractId="serviceRetryToPayId" :additionalFreezingDayId="additionalFreezingDayRetryToPayId" :contractId="contract.id" :hasClientPaymentMean="contract.clientPaymentMean" @close="showRetryToPayService = false" @update="updateContract"></retryToPayService>

<div class="content_wrapper wrapper">
    <button @click="$router.go(-1)" class="button button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('contract.view.back')}}</span></button>
    <div class="contract_page_wrapper">
        <div class="contract_data">

            <div class="w-100 pt-1 contract_title">
                <p class="agreement" v-show="isLoaded">{{contract?.agreement}}, {{contract?.clubName}} ({{contract?.contract}})</p>
                <div :class="'contract_status status_text_' + getContractStatus(contract?.status)" v-show="isLoaded">{{$t('contract_status.' + getContractStatus(contract?.status ?? 1))}}</div>
            </div>


            <div class="contract_recurring_payments_agrees" v-show="contract?.hasUserAgreedWithRecurringPayments != null">
                <p class="m-0" v-if="contract?.hasUserAgreedWithRecurringPayments">{{ $t('contract.view.client_agreed_with_recurring_payments') }}</p>
                <p class="m-0" v-else>{{ $t('contract.view.client_disagreed_with_recurring_payments') }}</p>
            </div>

            <div class="contract_status_description w-100" v-if="contractStatusDescription">{{contractStatusDescription}}</div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.start_date') }}</p>
                <p class="block_info">{{ getFormatedDate(contract?.startDate, "DD.MM.yyyy") || '-' }}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.end_date') }}</p>
                <p class="block_info">{{ getFormatedDate(contract?.endDate, "DD.MM.yyyy") || '-' }}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.price') }}</p>
                <p class="block_info">{{ getFormatedAmount(contract?.price) }} {{contract?.currencySign }}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.referralcode') }}</p>
                <p class="block_info">{{ contract?.referralPromocode }}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.freeze') }}</p>
                <p class="block_info">{{ $t('contract.view.available_days')}}: {{contract?.availableFreezingDaysNumber}}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.freeze_price') }}</p>
                <p class="block_info">{{ getFormatedAmount(contract?.freezePrice) }} {{contract?.currencySign }}</p>
            </div>

            <div class="mt-3 contract_data_block">
                <p class="block_label">{{ $t('contract.view.guest_visits') }}</p>
                <p class="block_info">{{ $t('contract.view.available_visits')}}: {{contract?.availableGuestVisit}}</p>
            </div>

            <div class="mt-3 contract_data_block" v-if="contract?.pin">
                <p class="block_label">{{ $t('contract.view.pin') }}</p>
                <p class="block_info">{{ contract?.pin }}</p>
            </div>
        </div>



        <div class="contract_data mt-2">
            <div class="contract_payment_block mt-3">
                <p class="block_label">{{$t('contract.view.previous_payment')}}</p>
                <div class="flex" v-if="contract?.previousPaymentDueDate">
                    <div class="dot dot_contract_active mr-2"></div>
                    <p class="block_info ">{{getFormatedAmount(contract?.previousPaymentTotal) }} {{contract?.currencySign }} <span class="payment_date">{{ getFormatedDate(contract?.previousPaymentDueDate, "DD.MM.yyyy") || '-' }}</span></p>
                </div>
                <div class="flex" v-else><p class="block_info ">-</p></div>
                <div class="contract_previous_payment_queue" v-show="contract?.previousPaymentQueueDate != contract?.previousPaymentDueDate">({{$t('contract.view.paid_contract_previos_paid')}} {{ getFormatedDate(contract?.previousPaymentQueueDate, "DD.MM.yyyy") || '-' }})</div>
            </div>

            <div class="contract_payment_block mt-3">
                <p class="block_label">{{$t('contract.view.next_payment')}}</p>
                <div class="flex" v-if="contract?.nextPaymentDueDate">
                    <div :class="contract?.nextPaymentDueDate != contract?.nextPaymentQueueDate ? 'dot dot_contract_indebt mr-2' : 'dot dot_contract_active mr-2'"></div>
                    <p class="block_info ">{{getFormatedAmount(contract?.nextPaymentTotal) }} {{contract?.currencySign }} <span class="payment_date">{{ getFormatedDate(contract?.nextPaymentQueueDate, "DD.MM.yyyy") || '-' }}</span></p>
                </div>
                <div class="flex" v-else><p class="block_info ">-</p></div>
                <div class="contract_previous_payment_queue" v-show="contract?.nextPaymentDueDate != contract?.nextPaymentQueueDate">({{$t('contract.view.in_debt_since')}} {{ getFormatedDate(contract?.nextPaymentDueDate, "DD.MM.yyyy") || '-' }})</div>
            </div>
        </div>

        <div class="contract_data mt-6" v-show="contract?.services.length > 0 || hasAddServicePermission">
            <div class="service_title ">
                <h3 class="my-0">{{$t('contract.view.services')}}</h3>
                <button class="service_add_button" v-if="isLoaded && hasAddServicePermission && contract?.status == 1" @click="showAddService = true"><span class="service_add_button_text">{{$t('clients.view.add')}}</span><img class="" src="/assets/img/plus.svg"></button>
            </div>

            <div v-for="service in contract?.services" class="service_wrapper mt-3">
                <div class="service">
                    <div class="service_title_wrapper">
                        <div :class="hasPayServicePermission && !service.hasPaid ? 'service_name_wrapper_with_pay_button':'service_name_wrapper'">
                            <p class="service_title_data service_name">{{service.name}}</p>

                            <div class="flex service_title_data not_payed" v-if="!service.hasPaid">{{$t('contract.view.not_paid')}}</div>
                            <div class="flex" v-else-if="service.hasPaid && service.status == 0"><p class="mt-0 mb-0 service_title_data">{{$t('contract.view.activating')}}</p></div>
                            <div class="flex" v-else-if="!service.isMandatory && service.clientTerminationDate && service.status != 6 && service.status != 5"><p class="mt-0 mb-0 service_title_data">{{$t('contract.view.service_terminated', [getFormatedDate(service.clientTerminationDate, "DD.MM.yyyy")])}}</p></div>
                            <div class="flex service_title_data" v-else><div :class="'dot dot_service_contract_' + getServiceContractStatus(service.status)"></div>{{$t('service_contract_status.' + getServiceContractStatus(service.status))}}</div>

                        </div>

                        <button class="pay_button" @click="serviceRetryToPayId = service.serviceContractId; additionalFreezingDayRetryToPayId = 0; showRetryToPayService = true" v-if="hasPayServicePermission && !service.hasPaid">{{$t('contract.view.pay')}}</button>
                        <button class="service_delete_button" @click="showAlert = true; serviceToDeleteId = service.serviceContractId" v-if="hasDeleteServicePermission && !service.isMandatory && !service.clientTerminationDate && service.status != 6 && service.status != 5"><img class="service_delete_button_img" src="/assets/img/delete.svg"></button>
                    </div>

                    <div class="service_body_wrapper">
                        <div class="mt-3 service_body">
                            <p class="block_label">{{$t('contract.view.period_of_validity')}}</p>
                            <p class="block_info" v-if="service.endDate">{{$t('contract.view.from_till', [getFormatedDate(service.startDate, "DD.MM.yyyy"), getFormatedDate(service.endDate, "DD.MM.yyyy")])}}</p>
                            <p class="block_info" v-else>{{$t('contract.view.from', [getFormatedDate(service.startDate, "DD.MM.yyyy")])}}</p>
                        </div>

                        <div class="mt-3 service_body">
                            <p class="block_label">{{$t('contract.view.next_payment')}}</p>
                            <div class="flex" v-if="service.nextPaymentDate && !service.clientTerminationDate"><p class="block_info ">{{getFormatedAmount(service.nextPaymentAmount) }} {{contract?.currencySign }} <span class="payment_date">{{ getFormatedDate(service.nextPaymentDate, "DD.MM.yyyy") || '-' }}</span></p></div>
                            <div class="flex" v-else><p class="block_info ">-</p></div>
                        </div>
                    </div>

                    <button class="pay_button_mobile" @click="payService" v-if="!service.hasPaid">{{$t('contract.view.pay')}}</button>
                </div>
            </div>
            <div v-show="contract?.services.length == 0" class="empty_table mt-3">
                {{$t('contract.view.no_services')}}
            </div>
        </div>


        <div class="contract_data mt-6">
            <div class="additional_freezing_days_title">
                <h3 class="my-0 additional_freezing_days_h">{{$t('contract.view.additional_freezing_days')}}</h3>
                <button class="service_add_button" v-if="isLoaded && hasAddServicePermission && contract?.status == 1" @click="showAdditionalFreezingDays = true"><span class="service_add_button_text">{{$t('clients.view.add')}}</span><img class="" src="/assets/img/plus.svg"></button>
            </div>

            <v-datatable class="mt-3" :columTemplates="additionalFreezingDaysColumTemplates" :data="additionalFreezingDaysTableData" @clickOnButton="clickOnTableButton" :emptyTableClass="'empty_table'"></v-datatable>
        </div>

        <div class="contract_data mt-6">
            <div class="w-100 ">
                <h3 class="my-0">{{$t('contract.view.freeze_history')}}</h3>
            </div>

            <v-datatable class="mt-3" :columTemplates="freeeHistoryColumTemplates" :data="freeeHistoryTableData" :emptyTableClass="'empty_table'"></v-datatable>
        </div>
        
        <div class="contract_data mt-6">
            <div class="w-100 ">
                <h3 class="my-0">{{$t('contract.view.discounts')}}</h3>
            </div>

            <v-datatable class="mt-3" :columTemplates="discountColumTemplates" :data="discountTableData" :emptyTableClass="'empty_table'"></v-datatable>
            <p class="discount_footnote" v-show="discountTableData.length > 0">* - {{$t('contract.view.estimated_date_of_application')}}</p>
        </div>

    </div>
    
</div>

