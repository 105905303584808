<div class="content_wrapper wrapper">
    <div class="content_body">
        <p class="component_title">{{$t('mailings.create.create_mailing')}}</p>
        <div class="two_block_wrapper">
            <div class="top_half_left mb-4"><v-input v-model="subject" :label="$t('mailings.create.subject')" height="22" :errorText="subjectErrorText" @focus="subjectErrorText = ''" clearable></v-input></div>
            <div class="top_half_right mb-4"><v-select v-model="selectedClubs" :label="'mailings.create.clubs'" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''" multiple clearable></v-select></div>
        </div>

        <div class="two_block_wrapper">
            <div class="top_half_left mb-4"><v-select v-model="selectedMailingType" :label="'mailings.create.mailing_type'" :items="mailingTypes" @update:modelValue="selectMailingType"></v-select></div>
            <div class="top_half_right mb-4"> <v-select v-model="selectedMailingGroup" :label="'mailings.create.mailing_group'" :items="mailingGroups" :errorText="mailingGroupErrorText" @click="mailingGroupErrorText = ''"></v-select></div>
        </div>

        <div class="one_block_wrapper" v-show="selectedMailingType.id != 'notification'">
            <div class="w-100 mb-4"><v-select v-model="selectedType" :label="'mailings.create.type'" :items="types"></v-select>  </div>
        </div>


        <div :class="{ edit_error: editorMessageErrorText }" class="mt-1 edit position-relative" v-show="selectedMailingType.id == 'mail'">
            <span class="input_label">{{$t('mailings.create.message')}}</span>
            <quill-editor v-model:value="editorMessage" :options="editorOption" @focus="editorMessageErrorText = ''"></quill-editor>
            <span class="span_error" v-show="editorMessageErrorText">{{messageErrorText}}</span>
        </div>

        <v-textarea class="mt-0" v-model="message" :label="$t('mailings.create.message')" v-show="selectedMailingType.id != 'mail'" :errorText="messageErrorText" @focus="messageErrorText = ''"></v-textarea>

        <div v-show="selectedMailingType.id == 'notification'">
            <img v-if="tempImg" class="notification_img" :src="tempImg" />
            <input type="file" hidden ref="fileInput" @change="addImg" />
            <button class="primary_button mt-3" @click="$refs.fileInput.click()">{{$t('mailings.create.add_img')}}</button>
        </div>


        <div class="w-100 mt-2 mb-3"><div class="flex"><v-checkbox v-model="isPeriodic" :label="$t('mailings.create.periodic')"></v-checkbox></div></div>
        <div class="periodic_wrapper" v-show="isPeriodic">
            <v-select class="small_input period_type" v-model="selectedPeriodType" :label="'mailings.create.period_type'" :items="periodTypes"></v-select>
            <v-input class="small_input" v-model="repetitionPeriod" :label="$t('mailings.create.repetition_period')" height="22" :errorText="repetitionPeriodErrorText" @focus="repetitionPeriodErrorText = ''" clearable></v-input>
        </div>

        <v-input class="date_input" v-model="sendDate" :label="$t('mailings.send_date')" inputType="datetime-local" height="22" :errorText="sendDateErrorText" @focus="sendDateErrorText = ''" clearable></v-input>

        <div class="button_wrapper mt-3">
            <button class="primary_button save_button" @click="save">{{$t('mailings.create.create')}}</button>
            <button class="secondary_button cancel_button" @click="$router.go(-1)">{{$t('mailings.create.back')}}</button>
        </div>
    </div>
   

</div>







