<template src="./viewRefundOrder.html">
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, getFormatedAmount, getFormatedDate, getRefundOrderStatus, retryGetPermissions } from '../../services/helper';
    import { Routes } from '../../const';
    import { RefundOrder } from '../../models/RefundOrder';
    import { TableColum, TableRow } from '../../models/Table'

    interface ComponentModel {
        refundOrder: RefundOrder | null,
        showAlert: boolean,
        refundOrderItemsColumTemplates: TableColum[],
        refundOrderItemsTableData: TableRow[],
        isLoaded: boolean,
        isButtonDisabled: boolean,
    }

    export default defineComponent({
        name: 'ViewRfundOrder',
        data(): ComponentModel {
            return {
                refundOrder: null,
                showAlert: false,
                refundOrderItemsColumTemplates: [
                    { title: 'refund_order.name', type: 'text', isSortable: true, template: '<div class="refund_order_item_name">%data%</div>' },
                    { title: 'refund_order.amount', type: 'text', isSortable: true, template: '<div>%data%</div>' },
                    { title: 'refund_order.vat', type: 'text', isSortable: true, template: '<div class="refund_order_item_vat">%data%</div>' },
                    { title: 'refund_order.type', type: 'text', isSortable: true, template: '<div class="refund_order_item_type">%data%</div>' },
                ],
                refundOrderItemsTableData: [],
                isLoaded: false,
                isButtonDisabled: false,
            }
        },
        methods: {
            getRefundOrder: async function (id: string) {
                await axiosRequests.get(editUrl(Routes.getRefundOrder, id))
                    .then(
                        (data: RefundOrder) => {
                            this.refundOrder = data;
                            this.refundOrderItemsTableData = this.refundOrder.refundOrderItems.map(x => { return { id: "", data: [x.name, getFormatedAmount(x.amount) + " " + data.currencySign, x.vat ? parseInt((x.vat / 100).toString()) + "%" : this.$t('refund_order.not_applicable'), this.$t(x.serviceTypeName)] } });

                            this.isLoaded = true;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getFormatedAmount,
            getFormatedDate,
            getRefundOrderStatus
        },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;

                    await this.getRefundOrder(id);
                },
                deep: true,
                immediate: true
            }
        },
        async beforeMount() {
            let refundOrderPermission = this.$store.getters.getRefundOrdersPermission;

            if (refundOrderPermission.length == 0) refundOrderPermission = await retryGetPermissions("refundorder");
            
            if (!refundOrderPermission?.includes('refund-orders/{id}/detailed')) this.$router.push('/forbidden');
        },
    });
</script>

<style scoped>
    .refund_order_page_wrapper {
        padding-right: 5px;
        overflow: auto;
    }

    .button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button_back {
        width: 107px;
        margin-bottom: 12px;
    }

    .back_button_img {
        margin-right: 7px;
        margin-top: 1px;
        margin-left: -10px;
    }

    .refund_order {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
    }

    .refund_order_data {
        display: flex;
        flex-flow: wrap;
    }

    .refund_order_data_block {
        width: 25%;
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .refund_order_status {
        margin-top: -1px;
        margin-left: 10px;
    }

    .refund_order_title {
        display: flex;
    }

    .status_text_not_processed {
        background-color: #fff1d7;
        padding: 4px 10px;
        border-radius: 12px;
        color: orange;
    }

    .status_text_processing {
        background-color: #e1e1ff;
        padding: 4px 10px;
        border-radius: 12px;
        color: blue;
    }

    .status_text_cancelled {
        background-color: #b4b4b4;
        padding: 4px 10px;
        border-radius: 12px;
        color: black;
    }

    .status_text_refunded {
        background-color: #ffe5e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: red;
    }

    .status_text_not_refunded {
        background-color: #b9b94b;
        padding: 4px 10px;
        border-radius: 12px;
        color: yellow;
    }

    @media(max-width: 860px) {
        .order_data_block {
            width: 50%;
        }
    }

    @media(max-width: 650px) {
        .refund_order_data_block {
            width: 100%;
        }

        .refund_order_status {
            margin-left: 0px;
            margin-top: 4px;
            width: fit-content;
        }

        .refund_order_title {
            display: block;
        }
    }

    @media(max-width: 500px) {
        .refund_order_cancel_button {
            width: 100%;
        }

        .refund_order_resume_button {
            width: 100%;
        }

        .refund_order_refund_button {
            width: 100%;
        }
    }

    @media(max-width: 500px) {
        :deep(.refund_order_item_name) {
            font-weight: bold;
            margin-bottom: 10px;
        }

        :deep(.refund_order_item_vat) {
            color: #a19d9d;
            font-size: 14px;
            margin-top: 10px;
        }

        :deep(.refund_order_item_type) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }
    }
</style>