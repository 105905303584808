import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce9c4bf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view_training_wrapper" }
const _hoisted_2 = { class: "view_training_body" }
const _hoisted_3 = { class: "view_training_title" }
const _hoisted_4 = { class: "view_training" }
const _hoisted_5 = { class: "view_training_info_wrapper" }
const _hoisted_6 = { class: "block" }
const _hoisted_7 = { class: "block_label" }
const _hoisted_8 = { class: "block" }
const _hoisted_9 = { class: "block_label" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { class: "block_label" }
const _hoisted_13 = { class: "block" }
const _hoisted_14 = { class: "block_label" }
const _hoisted_15 = {
  key: 0,
  class: "block"
}
const _hoisted_16 = { class: "block_label" }
const _hoisted_17 = { class: "client_pay_amount" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "block" }
const _hoisted_21 = { class: "block_label" }
const _hoisted_22 = { class: "client_wrapper" }
const _hoisted_23 = { class: "client_info_wrapper" }
const _hoisted_24 = { class: "client_phone" }
const _hoisted_25 = {
  key: 1,
  class: "view_training_buttons_wrapper"
}
const _hoisted_26 = ["disabled"]
const _hoisted_27 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_retryToPay = _resolveComponent("retryToPay")
  const _component_v_input = _resolveComponent("v-input")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.isShowAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isShowAlert) = $event)),
      title: 'timetable.view.alert_title',
      text: 'timetable.view.are_you_sure',
      successButtonText: _ctx.$t('timetable.view.confirm'),
      cancelButtonText: _ctx.$t('timetable.view.cancel'),
      onSuccess: _ctx.unregisterClient,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.isShowAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    (_ctx.isShowRetry)
      ? (_openBlock(), _createBlock(_component_retryToPay, {
          key: 0,
          client: _ctx.clientForRetryToPay,
          onClose: _ctx.closeWindow
        }, null, 8, ["client", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('timetable.view.personal_training')) + " ", 1),
          _createElementVNode("img", {
            class: "close_button",
            src: "/assets/img/close.svg",
            onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.close && _ctx.close(...args)))
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('timetable.view.trainer')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.training?.trainerNickName), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('timetable.view.start_date')), 1),
              (!_ctx.isPassed)
                ? (_openBlock(), _createBlock(_component_v_input, {
                    key: 0,
                    class: "mt-0 mb-0",
                    modelValue: _ctx.startDate,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.startDate) = $event)),
                    inputType: "datetime-local",
                    height: "22",
                    min: _ctx.minDate,
                    errorText: _ctx.startDateErrorText,
                    onFocus: _cache[4] || (_cache[4] = $event => (_ctx.startDateErrorText = '')),
                    clearable: ""
                  }, null, 8, ["modelValue", "min", "errorText"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.getFormatedDate(_ctx.startDate, 'DD.MM.yyyy HH:MM')), 1))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('timetable.view.duration')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.training.duration) + " " + _toDisplayString(_ctx.$t('timetable.view.min')), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('timetable.view.status')), 1),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.training?.isPaid || !_ctx.hasRetryPermission ? 'client_payed' : 'client_not_payed'),
                onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.openRetryWindow && _ctx.openRetryWindow(...args)))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.training?.isPaid ? 'dot mt-1 payed' : 'dot mt-1 not_payed')
                }, null, 2),
                _createTextVNode(_toDisplayString(_ctx.training?.isPaid ? _ctx.$t('timetable.view.payed') : _ctx.$t('timetable.view.not_payed')), 1)
              ], 2)
            ]),
            (_ctx.training?.isPaid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('timetable.view.payment_amount')), 1),
                  _createElementVNode("p", _hoisted_17, [
                    (_ctx.training?.paymentType == 'training_payment_method.training_block')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('timetable.view.from_block')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.getFormatedAmount(_ctx.training.amount)) + " " + _toDisplayString(_ctx.training.currencySign), 1))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('timetable.view.client')), 1),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", {
                  class: "client_photo",
                  style: _normalizeStyle({ 'background-image': `url(${_ctx.training?.clientPhoto})` })
                }, null, 4),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("p", {
                    class: "client_name",
                    onClick: _cache[6] || (_cache[6] = $event => (_ctx.redirectToClient(_ctx.training?.clientId)))
                  }, _toDisplayString(_ctx.training.clientName), 1),
                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.addPhoneMask(_ctx.training.clientPhone)), 1)
                ])
              ])
            ]),
            (!_ctx.isPassed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  (_ctx.hasUpdatePermission)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "save_button",
                        onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.save && _ctx.save(...args))),
                        disabled: _ctx.isButtonDisabled
                      }, _toDisplayString(_ctx.$t('timetable.view.save')), 9, _hoisted_26))
                    : _createCommentVNode("", true),
                  (_ctx.hasUnregisterPermission)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "delete_button",
                        onClick: _cache[8] || (_cache[8] = $event => (_ctx.isShowAlert = true)),
                        disabled: _ctx.isButtonDisabled
                      }, _toDisplayString(_ctx.$t('timetable.view.delete')), 9, _hoisted_27))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ], 64))
}