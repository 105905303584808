<template src="./retryToPayTrainingBlock.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, checkIfClickInsideAlert } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { RadioModel } from '../../../../models/GUIModels';

    interface ComponentModel {
        isButtonDisabled: boolean,
        availableMethod: RadioModel[]
        selectedMethod: RadioModel | null,
        showAlert: boolean,
        alertText: string,
    }

    export default defineComponent({
        name: 'RetryToPayTrainingBlock',
        props: ["trainingBlockId", "hasClientPaymentMean"],
        emits: ["close", "update"], 
        data(): ComponentModel {
            return {
                isButtonDisabled: false,
                availableMethod: [],
                selectedMethod: null,
                showAlert: false,
                alertText: "",
            }
        },
        methods: {
            clickOutside: function (event: any) {
                 if (!checkIfClickInsideAlert(event.target)) this.close();
            },
            close: function () {
                 this.$emit("close");
            },
            save: async function () {
                this.showAlert = false;
                this.isButtonDisabled = true;

                try {
                    const data = { paymentMethod: this.selectedMethod?.value };

                    await axiosRequests.post(editUrl(Routes.retryToPayTrainingBlockContract, this.trainingBlockId), data);
                    this.$emit("update");
                } catch (err: any) {
                    this.isButtonDisabled = false;

                    if (err.status == 401) {
                        return;
                    } else if (err.status == 403) {
                        this.$router.push('/forbidden');
                    } else if (err.status == 400 && err.data) {
                        this.$store.commit('SET_ADD_ERROR', err.data);
                    } else {
                        this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                    }
                }
            },
            clickOnSaveButton: function () {
                if (this.selectedMethod?.value == "pos") {
                    this.alertText = 'clients.view.retry_to_pay_training_block.pos_alert';
                    this.showAlert = true;
                } else if (this.selectedMethod?.value == "cash") {
                    this.alertText = 'clients.view.retry_to_pay_training_block.cash_alert';
                    this.showAlert = true;
                } else {
                    this.save();
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            this.availableMethod.push({ label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" });
            this.availableMethod.push({ label: 'add_entity_payment_method.cash', isTranslated: true, value: "cash" });

            if (this.hasClientPaymentMean) this.availableMethod.push({ label: 'add_entity_payment_method.linked_bank_card', isTranslated: true, value: "linkedCard" });

            this.selectedMethod = { label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" };
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .retry_to_pay_service_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .retry_to_pay_service_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .retry_to_pay_service_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .retry_to_pay_service {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .retry_to_pay_service_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .price_span {
        color: #65656F;
    }

    @media(max-width: 500px) {
        .retry_to_pay_service_body {
            width: 98%;
        }

        .retry_to_pay_service_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }

        .retry_to_pay_service_buttons_wrapper {
            display: block;
        }
    }
</style>