import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60cf8a16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup_wrapper" }
const _hoisted_2 = { class: "register_client_wrapper" }
const _hoisted_3 = { class: "register_client_body" }
const _hoisted_4 = { class: "register_client_title" }
const _hoisted_5 = { class: "register_client_content_wrapper" }
const _hoisted_6 = { class: "register_client" }
const _hoisted_7 = { class: "text_with_span" }
const _hoisted_8 = { class: "text_with_span mb-4" }
const _hoisted_9 = {
  key: 0,
  class: "mt-3 mb-2"
}
const _hoisted_10 = {
  key: 2,
  class: "no_available_payment_methods"
}
const _hoisted_11 = { class: "register_client_buttons_wrapper" }
const _hoisted_12 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_findClients = _resolveComponent("findClients")
  const _component_v_radio = _resolveComponent("v-radio")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.isShowAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isShowAlert) = $event)),
      title: 'timetable.view.register_client.confirmation',
      text: _ctx.alertText,
      successButtonText: _ctx.$t('timetable.view.register_client.confirm'),
      cancelButtonText: _ctx.$t('timetable.view.register_client.cancel'),
      onSuccess: _ctx.save,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.isShowAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.training.capacity > _ctx.training.load ? _ctx.$t('timetable.view.register_client.registration_of_client') : _ctx.$t('timetable.view.register_client.add_to_waiting_list')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t('timetable.view.register_client.training')) + ": ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.training.trainingClassName), 1)
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t('timetable.view.register_client.price')) + ": ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getFormatedAmount(_ctx.training.price)) + " " + _toDisplayString(_ctx.training.currencySign), 1)
            ]),
            _createVNode(_component_findClients, {
              onSelectClient: _ctx.selectClient,
              isGroup: true,
              trainingClassId: _ctx.training.trainingClassId,
              beginDate: _ctx.training.trainingStartDate
            }, null, 8, ["onSelectClient", "trainingClassId", "beginDate"]),
            (_ctx.isShowAvailableMethod)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString(_ctx.$t('add_entity_payment_method.payment_method')), 1))
              : _createCommentVNode("", true),
            (_ctx.isShowAvailableMethod)
              ? (_openBlock(), _createBlock(_component_v_radio, {
                  key: 1,
                  class: "ml-2",
                  modelValue: _ctx.selectedMethod,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedMethod) = $event)),
                  radioList: _ctx.availableMethod,
                  mb: 2
                }, null, 8, ["modelValue", "radioList"]))
              : _createCommentVNode("", true),
            (_ctx.availableMethod.length == 0 && _ctx.isShowAvailableMethod)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('timetable.view.register_client.no_available_payment_methods')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "primary_button",
              onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.clickOnSaveButton && _ctx.clickOnSaveButton(...args))),
              disabled: _ctx.isButtonDisabled || !_ctx.activeClient || _ctx.selectedMethod == null
            }, _toDisplayString(_ctx.training.capacity > _ctx.training.load ? _ctx.$t('timetable.view.register_client.register') : _ctx.$t('timetable.view.register_client.add')), 9, _hoisted_12),
            _createElementVNode("button", {
              class: "secondary_button button_space",
              onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit('close')))
            }, _toDisplayString(_ctx.$t('timetable.view.register_client.cancel')), 1)
          ])
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ]))
}