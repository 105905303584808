<div>
    <v-input class="mt-4 mb-2" v-model="searchText" :placeholder="$t('timetable.find.search_placeholder')" :label="$t('timetable.find.search_client')" height="22" search @update:modelValue="search"></v-input>

    <p class="find_clients_text" v-show="!isLoading">{{$t('timetable.find.find_clients')}}:</p>

    <div class="find_clients_wrapper" v-show="!isLoading">
        <div class="client_wrapper" :class="{ active: client.id == activeClient?.id }" v-for="client in clients" @click="clickOnClient(client)">
            <div class="radio" :class="{ active_radio: client.id == activeClient?.id }"><div :class="{ radio_child: client.id == activeClient?.id }"></div></div>
            <div class="client_photo"  :style="{ 'background-image': `url(${client?.photoUrl})` }"></div>
            <div class="client_info_wrapper">
                <p class="client_name">{{client.fullName}}</p>
                <p class="client_phone">{{addPhoneMask(client.phoneWithCountryCode)}}</p>
            </div>
        </div>

        <div v-show="clients.length == 0">{{$t('timetable.find.not_found')}}</div>
    </div>
</div>