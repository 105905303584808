<v-alert v-model="isShowAlert" :title="'timetable.create_personal_training.confirmation'" :text="alertText" :successButtonText="$t('timetable.create_personal_training.confirm')" :cancelButtonText="$t('timetable.create_personal_training.cancel')" @success="save" @cancel="isShowAlert = false"></v-alert>

<div class="create_training_wrapper">
    <div class="create_training_body" v-click-outside="clickOutside">
        <div class="create_training_title">{{$t('timetable.create_personal_training.create_training')}}</div>
        <div class="create_training_content_wrapper">
            <div class="create_training">
                <p class="text_with_span">{{$t('timetable.create_personal_training.club')}}: <span>{{clubName}}</span></p>
                <v-select class="mt-4 mb-4" v-model="selectedPersonalTrainer" :label="'timetable.create_personal_training.trainer'" :items="personalTrainers" @update:modelValue="selectTrainer"></v-select>
                <v-select class="mt-4 mb-2" v-model="selectedServicesToTrainer" :label="'timetable.create_personal_training.service'" :items="servicesToTrainer" :errorText="servicesToTrainerErrorText" @click="servicesToTrainerErrorText = ''" @update:modelValue="selectServiceToTrainer"></v-select>
                <p class="text_with_span mb-4" v-show="duration != 0">{{$t('timetable.create_personal_training.duration')}}: <span>{{duration}} {{$t('timetable.create_personal_training.minutes')}}</span></p>
                <v-input class="mt-4 mb-2" v-model="startDate" inputType="datetime-local" :label="$t('timetable.create_personal_training.start_date')" height="22" :min="minDate" :errorText="startDateErrorText" @focus="startDateErrorText = ''" clearable></v-input>
                <findClients @selectClient="selectClient" :isGroup="false" :сlubId="clubId" :trainerId="trainerId" :beginDate="startDate"></findClients>

                <h4 class="mt-3 mb-2" v-if="isShowAvailableMethod">{{$t('add_entity_payment_method.payment_method')}}</h4>
                <v-radio v-if="isShowAvailableMethod" class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
            </div>
            <div class="create_training_buttons_wrapper">
                <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled || !activeClient">{{$t('timetable.create_personal_training.create') }}</button>
                <button class="secondary_button button_space" @click="$emit('close')">{{$t('timetable.create_personal_training.cancel')}}</button>
            </div>
        </div>
        
    </div>
</div>