<v-alert v-model="isShowAlert" :title="'timetable.view.alert_title'" :text="'timetable.view.are_you_sure'" :successButtonText="$t('timetable.view.confirm')" :cancelButtonText="$t('timetable.view.cancel')" @success="unregisterClient" @cancel="isShowAlert = false"></v-alert>
<retryToPay v-if="isShowRetry" :client="clientForRetryToPay" @close="closeWindow"></retryToPay>

<div class="view_training_wrapper">
    <div class="view_training_body" v-click-outside="clickOutside">
        <div class="view_training_title">{{$t('timetable.view.personal_training')}} <img class="close_button" src="/assets/img/close.svg" @click="close" /></div>
        <div class="view_training">
            <div class="view_training_info_wrapper">
                <div class="block">
                    <div class="block_label">{{$t('timetable.view.trainer')}}</div>
                    <div>{{ training?.trainerNickName }}</div>
                </div>

                <div class="block">
                    <div class="block_label">{{$t('timetable.view.start_date')}}</div>
                    <v-input v-if="!isPassed" class="mt-0 mb-0" v-model="startDate" inputType="datetime-local" height="22" :min="minDate" :errorText="startDateErrorText" @focus="startDateErrorText = ''" clearable></v-input>
                    <div v-else>{{getFormatedDate(startDate, 'DD.MM.yyyy HH:MM')}}</div>
                </div>

                <div class="block">
                    <div class="block_label">{{$t('timetable.view.duration')}}</div>
                    <div>{{training.duration}} {{ $t('timetable.view.min')}}</div>
                </div>

                <div class="block">
                    <div class="block_label">{{$t('timetable.view.status')}}</div>
                    <div :class="training?.isPaid || !hasRetryPermission ? 'client_payed' : 'client_not_payed'" @click="openRetryWindow"><div :class="training?.isPaid ? 'dot mt-1 payed' : 'dot mt-1 not_payed'"></div>{{ training?.isPaid ? $t('timetable.view.payed') : $t('timetable.view.not_payed')}}</div>
                </div>

                <div class="block" v-if="training?.isPaid">
                    <div class="block_label">{{$t('timetable.view.payment_amount')}}</div>
                    <p class="client_pay_amount">
                        <span v-if="training?.paymentType == 'training_payment_method.training_block'">{{$t('timetable.view.from_block')}}</span>
                        <span v-else>{{getFormatedAmount(training.amount)}} {{training.currencySign}}</span>
                    </p>
                </div>

                <div class="block">
                    <div class="block_label">{{$t('timetable.view.client')}}</div>
                    <div class="client_wrapper">
                        <div class="client_photo" :style="{ 'background-image': `url(${training?.clientPhoto})` }"></div>
                        <div class="client_info_wrapper">
                            <p class="client_name" @click="redirectToClient(training?.clientId)">{{ training.clientName }}</p>
                            <p class="client_phone">{{addPhoneMask(training.clientPhone)}}</p>
                        </div>
                    </div>
                </div>


                <div class="view_training_buttons_wrapper" v-if="!isPassed">
                    <button class="save_button" @click="save" v-if="hasUpdatePermission" :disabled="isButtonDisabled">{{$t('timetable.view.save') }}</button>
                    <button class="delete_button" @click="isShowAlert = true" v-if="hasUnregisterPermission" :disabled="isButtonDisabled">{{$t('timetable.view.delete')}}</button>
                </div>

            </div>

        </div>

    </div>
</div>