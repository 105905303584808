<div class="content_wrapper wrapper">
    <button @click="$router.go(-1)" class="button button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('refund_order.back')}}</span></button>
    <div class="refund_order_page_wrapper">
        <div class="refund_order_data">

            <div class="w-100 mb-1">
                <div class="refund_order_title">
                    <p class="refund_order" v-show="isLoaded">RORD #{{refundOrder?.refundOrderId?.toString().padStart(8, '0')}}</p>
                    <div :class="'refund_order_status status_text_' + getRefundOrderStatus(refundOrder?.processingStatus, refundOrder?.paidStatus)" v-show="isLoaded">{{$t('refund_order_status.' + getRefundOrderStatus(refundOrder?.processingStatus ?? 1, refundOrder?.paidStatus))}}</div>
                </div>

            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.amount') }}</p>
                <p class="block_info">{{ getFormatedAmount(refundOrder?.total) }} {{refundOrder?.currencySign }}</p>
            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.date') }}</p>
                <p class="block_info">{{ getFormatedDate(refundOrder?.dueDate, "DD.MM.yyyy") || '-' }}</p>
            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.description') }}</p>
                <p class="block_info">{{ refundOrder?.comment }}</p>
            </div>
        </div>


        <div class="refund_order_data mt-6">
            <div class="w-100 ">
                <h3 class="my-0">{{$t('refund_order.refund_order_details')}}</h3>
            </div>

            <v-datatable class="mt-3" :columTemplates="refundOrderItemsColumTemplates" :data="refundOrderItemsTableData"></v-datatable>
        </div>
    </div>

</div>

