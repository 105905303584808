<template src="./changeContractStatus.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, getContractStatus, getFormatedDate, retryGetPermissions } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { SelectModel } from '../../../../models/GUIModels';

    interface ComponentModel {
        newContractStatuses: SelectModel[],
        newStatus: SelectModel,
        minFreezeStartDate: string,
        maxFreezeStartDate: string,
        minFreezeEndDate: string,
        maxFreezeEndDate: string,
        freezeStartDateErrorText: string,
        freezeEndDateErrorText: string,
        freezeStartDate: string,
        freezeEndDate: string,
        comment: string,
        commentErrorText: string,
        isButtonDisabled: boolean,
        terminateReasons: SelectModel[],
        terminateReason: SelectModel | null,
        canTerminateOnlyOnCurrentDay: boolean,
        terminateDate: string,
        terminateDateErrorText: string,
        terminateReasonErrorText: string,
        minFTerminationDate: string
    }

    export default defineComponent({
        name: 'ChangeContractStatus',
        props: ["contract"],
        data(): ComponentModel {
            return {
                newContractStatuses: [],
                newStatus: { id: "", text: "", disabled: false, isTranslated: true, groupName: null },
                minFreezeStartDate: "",
                maxFreezeStartDate: "",
                minFreezeEndDate: "",
                maxFreezeEndDate: "",
                freezeStartDateErrorText: "",
                freezeEndDateErrorText: "",
                freezeStartDate: "",
                freezeEndDate: "",
                comment: "",
                commentErrorText: "",
                isButtonDisabled: false,
                terminateReasons: [],
                terminateReason: null,
                canTerminateOnlyOnCurrentDay: false,
                terminateDate: "",
                terminateDateErrorText: "",
                terminateReasonErrorText: "",
                minFTerminationDate: getFormatedDate(new Date().toISOString(), "yyyy-MM-DD"),
            }
        },
        methods: {
            close: function () {
                this.$emit("close", false);
            },
            save: function () {
                if (this.newStatus?.id == "1") {
                    if (!this.comment) this.commentErrorText = 'clients.view.contract_status.comment_error';
                    if (this.commentErrorText) return;
                } else if (this.newStatus?.id == "2") {
                    if (!this.comment) this.commentErrorText = 'clients.view.contract_status.comment_error';
                    if (!this.freezeStartDate) this.freezeStartDateErrorText = 'clients.view.contract_status.freeze_start_date_error';
                    if (!this.freezeEndDate) this.freezeEndDateErrorText = 'clients.view.contract_status.freeze_end_date_error';
                    if (this.commentErrorText || this.freezeStartDateErrorText || this.freezeEndDateErrorText) return;
                } else if (this.newStatus?.id == "4") {
                    if (!this.comment) this.commentErrorText = 'clients.view.contract_status.comment_error';
                    if (this.commentErrorText) return;
                } else if (this.newStatus?.id == "6") {
                    if (!this.comment) this.commentErrorText = 'clients.view.contract_status.comment_error';
                    if (!this.canTerminateOnlyOnCurrentDay && !this.terminateDate) this.terminateDateErrorText ='clients.view.contract_status.terminate_date_error';
                    if (this.terminateReasons.length > 0 && !this.terminateReason?.id) this.terminateReasonErrorText = 'clients.view.contract_status.terminate_reasone_error';
                    if (this.commentErrorText || this.terminateDateErrorText || this.terminateReasonErrorText) return;
                }

                this.isButtonDisabled = true;

                const data = {
                    status: this.newStatus?.id,
                    comment: this.comment,
                    terminationDate: this.terminateDate ? this.terminateDate : null,
                    freezeDateFrom: this.freezeStartDate ? this.freezeStartDate : null,
                    freezeDateTo: this.freezeEndDate ? this.freezeEndDate : null,
                    terminationReasonId: this.terminateReason ? this.terminateReason?.id : null
                }

                axiosRequests.patch(editUrl(Routes.changeContractStatus, this.contract.id), data)
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            },
            setMinAndMaxFreezeEndDate: function () {
                let dateMinFreezeDays;
                let dateMaxFreezeDays; 

                if (this.freezeStartDate) {
                    dateMinFreezeDays = new Date(this.freezeStartDate);
                    dateMaxFreezeDays = new Date(this.freezeStartDate);

                    dateMinFreezeDays.setDate(dateMinFreezeDays.getDate() + this.contract?.minimumFreezeDays ?? 0);
                    dateMaxFreezeDays.setDate(dateMaxFreezeDays.getDate() + this.contract?.availableFreezingDaysNumber ?? 0);

                } else {
                    dateMinFreezeDays = new Date();
                    dateMaxFreezeDays = new Date();

                    dateMinFreezeDays.setDate(dateMinFreezeDays.getDate() + 1 + this.contract?.minimumFreezeDays ?? 0);
                    dateMaxFreezeDays.setDate(dateMaxFreezeDays.getDate() + 1 + this.contract?.availableFreezingDaysNumber ?? 0);
                }

                this.minFreezeEndDate = getFormatedDate(dateMinFreezeDays.toISOString(), "yyyy-MM-DD");
                this.maxFreezeEndDate = getFormatedDate(dateMaxFreezeDays.toISOString(), "yyyy-MM-DD");
            }
        },
        watch: {
            'freezeStartDate': function () {
                this.freezeEndDate = "";
                this.setMinAndMaxFreezeEndDate();
            }
        },
        async beforeMount() {
            let contractsPermission = this.$store.getters.getContractsPermission;

            if (contractsPermission.length == 0) contractsPermission = await retryGetPermissions("contract");

            this.canTerminateOnlyOnCurrentDay = contractsPermission.includes('workplace/contracts/change-termination-date');

            if (this.contract?.availableStatuses?.length > 0) {

                this.terminateReasons = this.contract.terminationReasons.map(x => { return { id: x.id, text: x.name, disabled: false } });

                if (this.terminateReasons.length > 0) {
                    this.terminateReason = this.terminateReasons[0];
                }

                for (let i = 0; i < this.contract.availableStatuses.length; i++) {
                    this.newContractStatuses.push({ id: this.contract.availableStatuses[i], text: 'contract_status.' + getContractStatus(this.contract.availableStatuses[i]), isTranslated: true, disabled: false, groupName: null });
                }

                this.newStatus = this.newContractStatuses[0];

                let tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);

                this.minFreezeStartDate = getFormatedDate(tomorrow.toISOString(), "yyyy-MM-DD");

                if (this.contract?.nextPaymentDueDate) {
                    this.maxFreezeStartDate = getFormatedDate(this.contract?.nextPaymentDueDate, "yyyy-MM-DD");
                } else if (this.contract?.endDate) {
                    var d = new Date(this.contract?.endDate);
                    d.setDate(d.getDate() - 1);
                    this.maxFreezeStartDate = getFormatedDate(d.toISOString(), "yyyy-MM-DD");
                }

                this.setMinAndMaxFreezeEndDate();
            } else {
                this.isButtonDisabled = true;
            }
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .change_contract_status_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .change_contract_status_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
    }

    .change_contract_status_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .achange_contract_status {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .change_contract_status_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    @media(max-width: 500px) {
        .change_contract_status_body {
            width: 98%;
        }

        .change_contract_status_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }
</style>