import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a842008"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "retry_to_pay_service_wrapper" }
const _hoisted_2 = { class: "retry_to_pay_service_body" }
const _hoisted_3 = { class: "retry_to_pay_service_title" }
const _hoisted_4 = { class: "retry_to_pay_service" }
const _hoisted_5 = { class: "mt-3 mb-2" }
const _hoisted_6 = { class: "retry_to_pay_service_buttons_wrapper" }
const _hoisted_7 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_radio = _resolveComponent("v-radio")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.showAlert) = $event)),
      title: 'clients.view.retry_to_pay_training_block.confirmation',
      text: _ctx.alertText,
      successButtonText: _ctx.$t('contract.view.retry.confirm'),
      cancelButtonText: _ctx.$t('clients.view.cancel'),
      onSuccess: _ctx.save,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.showAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('contract.view.retry.retry_to_pay')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('add_entity_payment_method.payment_method')), 1),
          _createVNode(_component_v_radio, {
            class: "ml-2",
            modelValue: _ctx.selectedMethod,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedMethod) = $event)),
            radioList: _ctx.availableMethod,
            mb: 2
          }, null, 8, ["modelValue", "radioList"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "primary_button",
            onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.clickOnSaveButton && _ctx.clickOnSaveButton(...args))),
            disabled: _ctx.isButtonDisabled
          }, _toDisplayString(_ctx.$t('clients.view.retry_to_pay_training_block.pay')), 9, _hoisted_7),
          _createElementVNode("button", {
            class: "secondary_button button_space",
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit('close')))
          }, _toDisplayString(_ctx.$t('clients.view.retry_to_pay_training_block.cancel')), 1)
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ], 64))
}