<div class="content_wrapper wrapper">
    <div class="chat_page_wrapper">
        <div :class="isShowChatBody ? 'left_side mobile_hide' : 'left_side'">
            <v-input v-model="search" class="search_input" :placeholder="$t('chat.find_client')" height="22" @update:modelValue="searchChat" clearable></v-input>
            <div class="chats_wrapper">
                <div v-for="room in rooms" :class="{ chat_wrapper_active: selectedRoom && selectedRoom?.roomId == room?.roomId }" v-show="!room.isHide" class="chat_wrapper" @click="selectRoom(room)">
                    <div class="client_photo" :style="{ 'background-image': `url(${room.clientPhoto})` }"></div>
                    <div :class="{ chat_data_wrapper_active: selectedRoom && selectedRoom?.roomId == room?.roomId }" class="chat_data_wrapper">
                        <div :class="room.unreadMessageCount > 0 ? 'chat_info_wrapper_with_uread_messages' : 'chat_info_wrapper'">
                            <div class="chat_info_name">{{room.lastName}} {{room.firstName}}</div>
                            <div class="chat_info_message">{{room.lastMessage}}</div>
                        </div>
                        <div v-show="room.unreadMessageCount > 0" class="unread_messages_count"><p class="mt-0">{{room.unreadMessageCount > 9 ? '9+' : room.unreadMessageCount}}</p></div>
                    </div>
                </div>
            </div>
        </div>


        <div :class="isShowChatBody ? 'right_side mobile_show': 'right_side'">
            <div class="right_side_buttons">
                <button @click="isShowChatBody = false" class="button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"></button>

                <button class="button_exit_mobile" v-if="selectedRoom?.isOwner" @click="removeOwner"><span class="">{{$t('chat.exit_chat')}}</span><img class="button_exit_img" src="/assets/img/logout.svg"></button>
                <button class="button_owner_mobile" v-if="!selectedRoom?.isOwner" @click="assignAsOwner"><span class="">{{$t('chat.become_owner')}}</span><img class="button_owner_img" src="/assets/img/edit-name-white.svg"></button>
            </div>

            <div class="chat_body_wrapper">
                <div class="chat_body_top_wrapper">
                    <div v-show="selectedRoom" class="chat_body_top">
                        <div class="client_photo_body_top" :style="{ 'background-image': `url(${selectedRoom?.clientPhoto})` }"></div>
                        <div class="client_name_body_top" @click="redirectToClient">{{selectedRoom?.lastName}} {{selectedRoom?.firstName}}</div>
                        <button class="button_exit" v-if="selectedRoom?.isOwner" @click="removeOwner"><span class="button_text">{{$t('chat.exit_chat')}}</span><img class="button_exit_img" src="/assets/img/logout.svg"></button>
                        <button class="button_owner" v-if="!selectedRoom?.isOwner" @click="assignAsOwner"><span class="button_text">{{$t('chat.become_owner')}}</span><img class="button_owner_img" src="/assets/img/edit-name-white.svg"></button>
                    </div>
                </div>
                <div class="chat_content_wrapper">
                    <div class="overflow-hidden" :style="{ 'min-height': `calc(100% - ${36 + textareaHeight}px)` }">
                        <div class="chat_body" id="messages">
                            <p class="info_text" v-if="messageInformation">{{messageInformation ? $t(messageInformation) : messageInformation}}</p>
                            <div v-for="message in messages" v-else>
                                <div class="technical_message" v-if="message.isTechnicalMessage">
                                    <p class="m-0">{{ message.isTranslated ? $t(message.text) : message.text}}</p>
                                    <p class="message_date" v-if="!message.isTechnicalMessage">{{message.sendDate}}</p>
                                </div>

                                <div :class="message.isClubMessage ? 'club_message' : 'client_message'" v-else>
                                    <p class="m-0" v-html="message.text"></p>
                                    <p class="message_date" v-if="!message.isTechnicalMessage">{{message.sendDate}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="chat_body_footer_wrapper" :style="{ 'min-height': `${36 + textareaHeight}px` }">
                        <div class="chat_body_footer" v-show="selectedRoom?.isOwner && !connectionInformation">
                            <div class="textarea_wrapper" :class="{ active: isTextareaFocused }">
                                <textarea :placeholder="$t('chat.enter_message')" class="textarea" v-model="message" @keydown="updateTextarea($event)" @input="changeTextArea" @focus="isTextareaFocused = true" @blur="isTextareaFocused = false" id="messageTextarea" />
                            </div>

                            <button class="message_send_button" @click="sendMessage"><img class="message_send_button_img" src="/assets/img/send.svg" /></button>
                        </div>
                        <p class="info_text" v-show="connectionInformation">{{connectionInformation ? $t(connectionInformation) : connectionInformation}}</p>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>