<template src="./writeMessageForClient.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { RadioModel } from '../../../../models/GUIModels';

    interface ComponentModel {
        subject: string,
        subjectErrorText: string,
        message: string,
        messageErrorText: string,
        isButtonDisabled: boolean,
        availableTypes: RadioModel[]
        selectedType: RadioModel | null
    }

    export default defineComponent({
        name: 'WriteMessageForClient',
        props: ['clientId', 'hasMobileDevice'],
        data(): ComponentModel {
            return {
                subject: "",
                subjectErrorText: "",
                message: "",
                messageErrorText: "",
                isButtonDisabled: false,
                availableTypes: [],
                selectedType: null
            }
        },
        methods: {
            close: function () {
                this.$emit("close");
            },
            save: function () {

                if (!this.subject) this.subjectErrorText = 'clients.view.write_message.subject_error_text';
                if (!this.message) this.messageErrorText = 'clients.view.write_message.message_error_text';

                if (this.subjectErrorText || this.messageErrorText) return;

                this.isButtonDisabled = true;

                const data = { subject: this.subject, message: this.message, type: this.selectedType?.value };

                axiosRequests.post(editUrl(Routes.sendMessageToClient, this.clientId), data)
                    .then(
                        () => {
                            this.isButtonDisabled = false;
                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'succee_messages.message_to_client_has_been_send');
                            this.close();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            this.availableTypes.push({ label: 'clients.view.write_message.email', isTranslated: true, value: "mail" });
            if (this.hasMobileDevice) {
                this.availableTypes.push({ label: 'clients.view.write_message.push_notification', isTranslated: true, value: "notification" });
                this.selectedType = { label: 'clients.view.write_message.push_notification', isTranslated: true, value: "notification" };
            } else {
                this.selectedType = { label: 'clients.view.write_message.email', isTranslated: true, value: "mail" }
            }
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .write_message_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .write_message_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .write_message_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .write_message {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .write_message_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    @media(max-width: 500px) {
        .write_message_body {
            width: 98%;
        }

        .write_message_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }
</style>