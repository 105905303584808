<div class="content_wrapper wrapper">
    <div class="content_body">
        <button @click="$router.go(-1)" class="button button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('training_class.view.back')}}</span></button>
        <div class="content_body" v-show="!isEdit">
            <div class="flex">
                <p class="component_title mb-2">{{mailing?.subject}}</p>
            </div>


            <div class="four_block_wrapper">
                <div class="half_left_wrapper">
                    <div class="four_block_left mb-4">
                        <p class="block_label">{{$t('mailings.view.mailing_type')}}</p>
                        <p class="block_info">{{mailingTypeName}}</p>
                    </div>
                    <div class="four_block_right mb-4">
                        <p class="block_label">{{$t('mailings.view.creator')}}</p>
                        <p class="block_info">{{userName}}</p>
                    </div>
                </div>
                <div class="half_right_wrapper">
                    <div class="four_block_left mb-4">
                        <p class="block_label">{{$t('mailings.send_date')}}</p>
                        <p class="block_info">{{getFormatedDate(mailing?.sendingDate, "DD.MM.yyyy HH:mm")}}</p>
                    </div>
                    <div class="four_block_right mb-4">
                        <p class="block_label">{{$t('mailings.view.mailing_group')}}</p>
                        <p class="block_info">{{mailingGroupName}}</p>
                    </div>
                </div>
            </div>

            <div class="two_block_wrapper">
                <div class="top_half_left mb-4">
                    <p class="block_label">{{$t('mailings.view.clubs')}}</p>
                    <p class="block_info">{{getSelectedClubNames()}}</p>
                </div>
                <div class="top_half_right mb-4">
                    <p class="block_label">{{$t('mailings.view.periodic')}}</p>
                    <p class="block_info">{{mailing?.isPeriodic && mailing?.periodsNumber && mailing?.periodTypeName ? `${$t('interval_measure.' + mailing.periodTypeName.toLowerCase())}: ${mailing.periodsNumber}` : $t('mailings.view.no') }}</p>
                </div>
            </div>

            <div class="">
                <p class="block_label">{{$t('mailings.view.message')}}</p>
                <div v-html="mailing?.body"></div>
            </div>

            <div class="mt-4" v-if="mailingType == 'notification' && mailing?.imageUrl">
                <p class="block_label">{{$t('mailings.view.attached_image')}}</p>
                <img class="notification_img" :src="mailing?.imageUrl" />
            </div>

            <div class="button_wrapper mt-3">
                <button class="primary_button button_space" @click="edit" v-if="isNew" :diasbled="isButtonDisabled">{{$t('mailings.view.edit')}}</button>
                <button class="change_status_button" @click="publish" v-if="isNew" :diasbled="isButtonDisabled">{{$t('mailings.view.publish')}}</button>
                <button class="change_status_button" @click="stop" v-if="!isNew && !isStoped && mailing?.isPeriodic" :diasbled="isButtonDisabled">{{$t('mailings.view.stop')}}</button>
                <button class="change_status_button" @click="resume" v-if="isStoped && mailing?.isPeriodic" :diasbled="isButtonDisabled">{{$t('mailings.view.resume')}}</button>
            </div>
        </div>

        <div class="content_body" v-show="isEdit">
            <p class="component_title">{{$t('mailings.view.edit_mailing')}}</p>

            <div class="two_block_wrapper">
                <div class="top_half_left mb-4"><v-input v-model="subject" :label="$t('mailings.view.subject')" height="22" :errorText="subjectErrorText" @focus="subjectErrorText = ''" clearable></v-input></div>
                <div class="top_half_right mb-4"><v-select v-model="selectedClubs" :label="'mailings.view.clubs'" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''" multiple clearable></v-select></div>
            </div>

            <div :class="mailingType != 'notification' ? 'two_block_wrapper' : 'one_block_wrapper'">
                <div :class="mailingType != 'notification' ? 'top_half_left mb-4' : 'w-100 mb-4'"><v-select v-model="selectedMailingGroup" :label="'mailings.view.mailing_group'" :items="mailingGroups" :errorText="mailingGroupErrorText" @click="mailingGroupErrorText = ''"></v-select></div>
                <div class="top_half_right mb-4" v-show="mailingType != 'notification'"><v-select v-model="selectedType" :label="'mailings.view.type'" :items="types"></v-select></div>
            </div>


            <div :class="{ edit_error: editorMessageErrorText }" class="mt-1 edit position-relative" v-show="mailingType == 'mail'">
                <span class="input_label">{{$t('mailings.view.message')}}</span>
                <quill-editor v-model:value="editorMessage" :options="editorOption" @focus="editorMessageErrorText = ''"></quill-editor>
                <span class="span_error" v-show="editorMessageErrorText">{{messageErrorText}}</span>
            </div>

            <v-textarea class="mt-5" v-model="message" :label="$t('mailings.view.message')" v-show="mailingType != 'mail'" :errorText="messageErrorText" @focus="messageErrorText = ''"></v-textarea>

            <div v-show="mailingType == 'notification'">
                <img v-if="tempImg" class="notification_img" :src="tempImg" />
                <input type="file" hidden ref="fileInput" @change="addImg" />
                <div class="flex mt-3">
                    <button class="primary_button" @click="$refs.fileInput.click()">{{$t('mailings.view.add_img')}}</button>
                    <button class="img_delete_button" @click="removeImg" v-if="tempImg"><img src="/assets/img/delete.svg"></button>

                </div>
            </div>


            <div class="w-100 mt-2 mb-3"><div class="flex"><v-checkbox v-model="isPeriodic" :label="$t('mailings.view.periodic')"></v-checkbox></div></div>
            <div class="periodic_wrapper" v-show="isPeriodic">
                <v-select class="small_input period_type" v-model="selectedPeriodType" :label="'mailings.view.period_type'" :items="periodTypes"></v-select>
                <v-input class="small_input" v-model="repetitionPeriod" :label="$t('mailings.view.repetition_period')" height="22" :errorText="repetitionPeriodErrorText" @focus="repetitionPeriodErrorText = ''" clearable></v-input>
            </div>

            <v-input class="date_input" v-model="sendDate" :label="$t('mailings.send_date')" inputType="datetime-local" height="22" :errorText="sendDateErrorText" @focus="sendDateErrorText = ''" clearable></v-input>

            <div class="button_wrapper mt-3">
                <button class="primary_button save_button" @click="save">{{$t('mailings.view.save')}}</button>
                <button class="secondary_button cancel_button" @click="isEdit=false">{{$t('mailings.view.cancel')}}</button>
            </div>
        </div>
    </div>
</div>







