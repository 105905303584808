import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e84b9538"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change_next_step_date_wrapper" }
const _hoisted_2 = { class: "change_next_step_date_body" }
const _hoisted_3 = { class: "change_next_step_date_body_title" }
const _hoisted_4 = { class: "change_next_step_date_buttons_wrapper" }

export function render(_ctx, _cache) {
  const _component_v_input = _resolveComponent("v-input")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('task.change_date.change_next_step_date')), 1),
      _createVNode(_component_v_input, {
        class: "change_next_step_date_inpute",
        modelValue: _ctx.nextStepDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.nextStepDate) = $event)),
        label: _ctx.$t('task.change_date.date'),
        inputType: "datetime-local",
        height: "22",
        min: _ctx.minDate,
        max: _ctx.maxDate,
        ref: "changeDateInpute",
        errorText: _ctx.dateErrorText,
        onFocus: _cache[1] || (_cache[1] = $event => (_ctx.dateErrorText = '')),
        clearable: ""
      }, null, 8, ["modelValue", "label", "min", "max", "errorText"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "primary_button",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.saveDate && _ctx.saveDate(...args)))
        }, _toDisplayString(_ctx.$t('task.change_date.change')), 1),
        _createElementVNode("button", {
          class: "secondary_button button_space",
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('task.change_date.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}