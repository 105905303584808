import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7430f652"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "find_clients_wrapper" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "client_info_wrapper" }
const _hoisted_4 = { class: "client_name" }
const _hoisted_5 = { class: "client_phone" }

export function render(_ctx, _cache) {
  const _component_v_input = _resolveComponent("v-input")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_input, {
      class: "mt-4 mb-2",
      modelValue: _ctx.searchText,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = $event => ((_ctx.searchText) = $event)),
        _ctx.search
      ],
      placeholder: _ctx.$t('timetable.find.search_placeholder'),
      label: _ctx.$t('timetable.find.search_client'),
      height: "22",
      search: ""
    }, null, 8, ["modelValue", "placeholder", "label", "onUpdate:modelValue"]),
    _withDirectives(_createElementVNode("p", { class: "find_clients_text" }, _toDisplayString(_ctx.$t('timetable.find.find_clients')) + ":", 513), [
      [_vShow, !_ctx.isLoading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["client_wrapper", { active: client.id == _ctx.activeClient?.id }]),
          onClick: $event => (_ctx.clickOnClient(client))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["radio", { active_radio: client.id == _ctx.activeClient?.id }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({ radio_child: client.id == _ctx.activeClient?.id })
            }, null, 2)
          ], 2),
          _createElementVNode("div", {
            class: "client_photo",
            style: _normalizeStyle({ 'background-image': `url(${client?.photoUrl})` })
          }, null, 4),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(client.fullName), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.addPhoneMask(client.phoneWithCountryCode)), 1)
          ])
        ], 10, _hoisted_2))
      }), 256)),
      _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.$t('timetable.find.not_found')), 513), [
        [_vShow, _ctx.clients.length == 0]
      ])
    ], 512), [
      [_vShow, !_ctx.isLoading]
    ])
  ]))
}