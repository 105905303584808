<div class="update_client_sourse_wrapper">
    <div class="update_client_sourse_body" v-click-outside="close">
        <div class="update_client_sourse_title">{{$t('clients.view.update_client_sourse.update_client_sourse')}}</div>
        <div class="update_client_sourse">
            <v-select class="" v-model="clientSourse" :label="'clients.view.update_client_sourse.client_sourse'" :items="clientSourses" :errorText="clientSoursesErrorText" @click="clientSoursesErrorText = ''"></v-select>            

        </div>
        <div class="update_client_sourse_buttons_wrapper">
            <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('clients.view.update_client_sourse.save') }}</button>
            <button class="secondary_button button_space" @click="$emit('close', false)">{{$t('clients.view.update_client_sourse.cancel')}}</button>
        </div>
    </div>
</div>

