import axios, { AxiosResponse, AxiosHeaders, AxiosError } from "axios";
import { nextTick } from "vue";
import { store } from '../store'
import { useCookies } from "vue3-cookies";

const cookies = useCookies();

const axiosInstance = axios.create();

let isRequestRefreshing = false;
let failedRequestQueue: any = [];

let isResponseRefreshing = false;
let failedResponseQueue: any = [];

const processRequestQueue = (error, token) => {
    failedRequestQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedRequestQueue = [];
}

const processResponseQueue = (error) => {
    failedResponseQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    })

    failedResponseQueue = [];
}

axiosInstance.interceptors.request.use((config) => {
    store.commit('SET_LOAD', true);
    return config;
});

axiosInstance.interceptors.request.use(
    async (config) => {
        if (config.url === process.env.VUE_APP_AUTH_URL || config.url === process.env.VUE_APP_REFRESH_URL) return config;

        let token = cookies.cookies.get("accessToken");
        const refreshToken = cookies.cookies.get("refreshToken");

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["ClubId"] = store.getters.getSelectedClubId;
        } else if (refreshToken) {
            if (isRequestRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedRequestQueue.push({ resolve, reject })
                }).then(token => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            isRequestRefreshing = true;

            await axiosInstance.post(process.env.VUE_APP_REFRESH_URL, { refreshToken: refreshToken })
                .then(
                    (response: any) => {
                        token = response.data.accessToken;
                        cookies.cookies.set("accessToken", response.data.accessToken, response.data.accessTokenLifetime + "s", "/", undefined, true);
                        cookies.cookies.set("refreshToken", response.data.refreshToken, response.data.refreshTokenLifetime + "s", "/", undefined, true);

                        processRequestQueue(null, token);
                    })
                .catch(
                    async (err: any) => {
                        store.commit('SET_ISAUTH', false);
                        nextTick(() => store.commit('SET_LOAD', false));

                        cookies.cookies.remove("accessToken");
                        cookies.cookies.remove("refreshToken");
                        const headers = new AxiosHeaders(); 

                        const error = new AxiosError(undefined, undefined, config, undefined, {
                            status: 401,
                            data: "Unauthorized",
                            statusText: "Unauthorized",
                            config,
                            headers
                        });

                        processRequestQueue(error, null);

                        return Promise.reject(error);
                    })
                .finally(() => { isRequestRefreshing = false });

            config.headers["Authorization"] = `Bearer ${token}`; 
        } else {
            const headers = new AxiosHeaders(); 

            store.commit('SET_ISAUTH', false);

            cookies.cookies.remove("accessToken");
            cookies.cookies.remove("refreshToken");

            return Promise.reject(new AxiosError(undefined, undefined, config, undefined, {
                status: 401,
                data: "Unauthorized",
                statusText: "Unauthorized",
                config,
                headers
            }));
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    response => {
        nextTick(() => store.commit('SET_LOAD', false));

        return response;
    },
    async function (error) {
        const originalConfig = error.config;
        const refreshToken = cookies.cookies.get("refreshToken");

        if (error?.code == "ERR_CANCELED") {
            nextTick(() => store.commit('SET_LOAD', false));

            return Promise.reject(error.response);
        }

        if (error.response.status === 401 && refreshToken) {
            if (originalConfig._retry) {
                store.commit('SET_ISAUTH', false);

                cookies.cookies.remove("accessToken");
                cookies.cookies.remove("refreshToken");
            } else {
                if (isResponseRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedResponseQueue.push({ resolve, reject })
                    }).then(() => {
                        return axiosInstance(originalConfig);
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }

                isResponseRefreshing = true;
                originalConfig._retry = true;

                await axiosInstance.post(process.env.VUE_APP_REFRESH_URL, { refreshToken: refreshToken })
                    .then(
                        (response: any) => {
                            cookies.cookies.set("accessToken", response.data.accessToken, response.data.accessTokenLifetime + "s", "/", undefined, true);
                            cookies.cookies.set("refreshToken", response.data.refreshToken, response.data.refreshTokenLifetime + "s", "/", undefined, true);

                            processResponseQueue(null);
                        })
                    .catch(
                        async (err: any) => {
                            store.commit('SET_ISAUTH', false);
                            nextTick(() => store.commit('SET_LOAD', false));

                            cookies.cookies.remove("accessToken");
                            cookies.cookies.remove("refreshToken");

                            processResponseQueue(err);

                            return Promise.reject(err);
                        })
                    .finally(() => { isResponseRefreshing = false });

                return axiosInstance(originalConfig);
            }
        }

        nextTick(() => store.commit('SET_LOAD', false));

        return Promise.reject(error.response);
    }
);

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string, signal?: any) => axiosInstance.get(url, { signal: signal }).then(responseBody),
    getFile: (url: string, signal?: any) => axiosInstance.get(url, { responseType: 'blob', signal: signal }),
    post: (url: string, body?: any, signal?: any) => axiosInstance.post(url, body, { signal: signal }).then(responseBody),
    put: (url: string, body?: any, signal?: any) => axiosInstance.put(url, body, { signal: signal }).then(responseBody),
    patch: (url: string, body?: any, signal?: any) => axiosInstance.patch(url, body, { signal: signal }).then(responseBody),
    delete: (url: string, signal?: any) => axiosInstance.delete(url, { signal: signal }).then(responseBody),
};


export const axiosRequests: any = {
    get: (url: string, signal?: any): Promise<any> => requests.get(url, signal),
    getFile: (url: string, signal?: any): Promise<any> => requests.getFile(url, signal),
    post: (url: string, body?: any, signal?: any): Promise<any> => requests.post(url, body, signal),
    put: (url: string, body?: any, signal?: any): Promise<any> => requests.put(url, body, signal),
    patch: (url: string, body?: any, signal?: any): Promise<any> => requests.patch(url, body, signal),
    delete: (url: string, signal?: any): Promise<any> => requests.delete(url, signal)
}
