<v-alert v-model="showAlert" :title="'contract.view.retry.confirmation'" :text="alertText" :successButtonText="$t('contract.view.retry.confirm')" :cancelButtonText="$t('clients.view.cancel')" @success="save" @cancel="showAlert = false"></v-alert>

<div class="retry_to_pay_service_wrapper">
    <div class="retry_to_pay_service_body" v-click-outside="clickOutside">
        <div class="retry_to_pay_service_title">{{$t('contract.view.retry.retry_to_pay')}}</div>
        <div class="retry_to_pay_service">
            <h4 class="mt-3 mb-2">{{$t('add_entity_payment_method.payment_method')}}</h4>
            <v-radio class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
        </div>
        <div class="retry_to_pay_service_buttons_wrapper">
            <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled">{{$t('contract.view.retry.pay') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('contract.view.retry.cancel')}}</button>
        </div>
    </div>
</div>

