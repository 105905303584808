<v-alert v-model="showAlert" :title="'contract.view.add_additional_freezing_days.confirmation'" :text="alertText" :successButtonText="$t('contract.view.add_additional_freezing_days.confirm')" :cancelButtonText="$t('clients.view.cancel')" @success="save" @cancel="showAlert = false"></v-alert>

<div class="add_additional_freezing_days_wrapper">
    <div class="add_additional_freezing_days_body" v-click-outside="clickOutside">
        <div class="add_additional_freezing_days_title">{{$t('contract.view.add_additional_freezing_days.add_additional_freezing_days')}}</div>
        <div class="add_additional_freezing_days">
            <v-select class="mt-4 mb-4" v-model="selectedService" :label="'contract.view.add_additional_freezing_days.service'" :items="services" @update:modelValue="selectService" :errorText="serviceErrorText" @click="serviceErrorText = ''"></v-select>
            <p class="mt-0 mb-2">{{$t('contract.view.add_additional_freezing_days.price')}}: <span class="price_span">{{selectedPrice}}</span></p>

            <h4 class="mt-3 mb-2">{{$t('add_entity_payment_method.payment_method')}}</h4>

            <v-radio class="ml-2" v-model="selectedMethod" :radioList="availableMethod" :mb="2"></v-radio>
        </div>
        <div class="add_additional_freezing_days_buttons_wrapper">
            <button class="primary_button" @click="clickOnSaveButton" :disabled="isButtonDisabled">{{$t('contract.view.add_additional_freezing_days.add') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('contract.view.add_additional_freezing_days.cancel')}}</button>
        </div>
    </div>
</div>

