import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4591b6ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pass_card_wrapper" }
const _hoisted_2 = {
  class: "pass_card_body",
  ref: "bodyRef"
}
const _hoisted_3 = { class: "pass_card_title" }
const _hoisted_4 = {
  key: 0,
  class: "pass_card"
}
const _hoisted_5 = { class: "pass_card_text" }
const _hoisted_6 = { ref: "clubsRef" }
const _hoisted_7 = {
  key: 1,
  class: "pass_card"
}
const _hoisted_8 = { class: "pass_card_text" }
const _hoisted_9 = { class: "pass_card_buttons_wrapper" }
const _hoisted_10 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('pass_card.link_pass_card.linking_card')), 1),
      (_ctx.clubs?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('pass_card.link_pass_card.text')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_select, {
                modelValue: _ctx.selectedClub,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedClub) = $event)),
                label: 'pass_card.link_pass_card.club',
                class: "w-100 mb-3",
                items: _ctx.clubs,
                errorText: _ctx.clubErrorText,
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.clubErrorText = ''))
              }, null, 8, ["modelValue", "label", "items", "errorText"])
            ], 512)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('pass_card.link_pass_card.no_active_contract')), 1)
          ])),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.clubs?.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "primary_button button_space",
              onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.link && _ctx.link(...args))),
              disabled: _ctx.isButtonDisabled || _ctx.clubs.length == 0
            }, _toDisplayString(_ctx.$t('pass_card.link_pass_card.link')), 9, _hoisted_10))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "secondary_button",
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('pass_card.link_pass_card.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}