<template src="./viewContract.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, getFormatedAmount, getFormatedDate, getContractStatus, getServiceContractStatus, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { Contract } from '../../../models/Contract';
    import { AdditionalFreezingDay } from '../../../models/AdditionalFreezingDay';
    import { TableColum, TableRow } from '../../../models/Table'
    import { Discount } from '../../../models/Discount';
    import AddService from './AddService/AddService.vue';
    import AddAdditionalFreezingDays from './AddAdditionalFreezingDays/AddAdditionalFreezingDays.vue';
    import RetryToPayService from './RetryToPayService/RetryToPayService.vue';


    interface ComponentModel {
        contractId: number,
        contract: Contract | null,
        showAlert: boolean,
        serviceToDeleteId: number,
        serviceRetryToPayId: number,
        additionalFreezingDayRetryToPayId: number,
        additionalFreezingDaysColumTemplates: TableColum[],
        freeeHistoryColumTemplates: TableColum[],
        discountColumTemplates: TableColum[],
        additionalFreezingDaysTableData: TableRow[],
        freeeHistoryTableData: TableRow[],
        discountTableData: TableRow[],
        hasAddServicePermission: boolean,
        hasDeleteServicePermission: boolean,
        hasPayServicePermission: boolean,
        showAddService: boolean,
        showAdditionalFreezingDays: boolean,
        showRetryToPayService: boolean,
        isLoaded: boolean,
        contractStatusDescription: string
    }

    export default defineComponent({
        name: 'ViewContract',
        data(): ComponentModel {
            return {
                contractId: 0,
                contract: null,
                showAlert: false,
                serviceToDeleteId: 0,
                serviceRetryToPayId: 0,
                additionalFreezingDayRetryToPayId: 0,
                additionalFreezingDaysColumTemplates: [
                    { title: 'contract.view.name', type: 'text', isSortable: true, template: '<div class="additional_freezing_day_name">%data%</div>' },
                    { title: 'contract.view.used', type: 'text', isSortable: true, template: '<div class="additional_freezing_day_used">%data%</div>' },
                    { title: 'contract.view.end_date', type: 'date', isSortable: true, template: '<div class="additional_freezing_day_date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'contract.view.price', type: 'text', isSortable: true, template: '<div class="additional_freezing_day_price">%data%</div>' },
                    { title: 'contract.view.paid', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                ],
                additionalFreezingDaysTableData: [],
                freeeHistoryColumTemplates: [
                    { title: 'contract.view.start_date', type: 'date', isSortable: true, template: '<div class="freee_history_date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'contract.view.end_date', type: 'date', isSortable: true, template: '<div class="freee_history_date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'contract.view.next_payment', type: 'text', isSortable: false, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'contract.view.days_count', type: 'text', isSortable: false, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'contract.view.status', type: 'freeze_status', isSortable: false, template: '<div class="flex status_text_%color%"><div class="dot dot_table dot_freeze_%color%"></div>%data%</div>' }
                ],
                freeeHistoryTableData: [],
                discountColumTemplates: [
                    { title: 'contract.view.type_of_discount', type: 'text', isSortable: true, template: '<div class="discount_type">%data%</div>' },
                    { title: 'contract.view.description', type: 'text', isSortable: true, template: '<div class="discount_description">%data%</div>' },
                    { title: 'contract.view.date_of_application', type: 'text', isSortable: true, template: '<div class="discount_date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'contract.view.discount', type: 'text', isSortable: true, template: '<div class="discount_discount">%data%</div>' },
                    { title: 'contract.view.discount_left', type: 'text', isSortable: true, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'contract.view.order', type: 'text', isSortable: true, template: '<div class="mobile_hide">%data%</div>' }
                ],
                discountTableData: [],
                hasAddServicePermission: false,
                hasDeleteServicePermission: false,
                hasPayServicePermission: false,
                showAddService: false,
                showAdditionalFreezingDays: false,
                showRetryToPayService: false,
                isLoaded: false,
                contractStatusDescription: ""
            }
        },
        methods: {
            getContract: async function () {
                await axiosRequests.get(editUrl(Routes.getContract, this.contractId))
                    .then(
                        (data: Contract) => {
                            this.contract = data;   
    
                            this.additionalFreezingDaysTableData = data.additionalFreezingDays.map(x => { return { id: "", data: [x.name, x.usedDaysNumber + "/" + x.count, x.endDate, `${getFormatedAmount(x.price)} ${data.currencySign}`, this.getAdditionalFreezingDaysPaidString(x) ]}});
                            this.freeeHistoryTableData = data.freezeHistories.map(x => { return { id: "", data: [x.startDate, x.endDate, x.nextPaymentDate ? `${getFormatedDate(x.nextPaymentDate, 'DD.MM.yyyy')} ${getFormatedAmount(x.nextPaymentPrice)} ${data.currencySign}` : "-", x.daysCount, x.status ]}});
                            this.discountTableData = data.discounts.map(x => { return { id: "", data: [this.$t(x.typeName), this.getDiscountDiscription(x), this.getDiscountUsedDate(x), this.getDiscount(x), x.periodsLeft > 0 ? x.periodsLeft : "", x.orderId] } });

                            if (data.status == 4 && data.lastBlockedReason) {
                                this.contractStatusDescription = data.lastBlockedReason;
                            } else if (data.status == 3 && data.lastFailedPaymentReason) {
                                this.contractStatusDescription = this.$t(data.lastFailedPaymentReason);
                            }

                            this.isLoaded = true;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            deleteService: function(){
                this.showAlert = false;

                if(!this.serviceToDeleteId || this.serviceToDeleteId == 0) return;
                
                axiosRequests.post(editUrl(Routes.cancelServiceContract, this.serviceToDeleteId), { contractId: this.contractId })
                    .then(
                        () => {
                        this.getContract();   
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getAdditionalFreezingDaysPaidString: function (additionalFreezingDay: AdditionalFreezingDay) {
                if (additionalFreezingDay.isPaid) {
                    return `<div class="additional_freezing_day_paid"><span class="hide_in_desktop">${this.$t("contract.view.paid") }:</span>${this.$t("contract.view.yes")}<div>`;
                } else {
                    return `<button class="retry_to_pay_button" param-action="retryToPayAdditionalFreezingDays" param-id="${additionalFreezingDay.id}">${this.$t("contract.view.pay")}</button>`;
                }
            },
            getDiscountUsedDate: function (discount: Discount) {
                if(discount.usedDate) return getFormatedDate(discount.usedDate, 'DD.MM.yyyy');
                if(this.contract?.nextPaymentDueDate) return getFormatedDate(this.contract.nextPaymentDueDate, 'DD.MM.yyyy') + "*";

                return "";
            },
            getDiscountDiscription: function(discount: Discount){
                if(discount.type == 0) return discount.promoCode;
                if(discount.type == 2) return "";

                if(discount.type == 1 && discount.referralClientId ) return this.$t('contract.view.invited_client') + `<a class="client_link" href='/client/${discount.referralClientId}' param-href='/client/${discount.referralClientId}'>${discount.referralClientFullName}</a>`;
                if(discount.type == 1 && discount.referredClientId) return this.$t('contract.view.invited_by_client') + `<a class="client_link" href='/client/${discount.referredClientId}' param-href='/client/${discount.referredClientId}'>${discount.referredClientFullName}</a>`;

                return "";
            },
            getDiscount: function(discount: Discount){
                if(discount.discountMeasure == 2){
                    return discount.discountIntervalMeasure + ": " + discount.periodsCount;
                }else{
                    return getFormatedAmount(discount.discountAmount) + " " + this.contract?.currencySign;
                }
            },
            clickOnTableButton: function (id: number, action: string) {
                if (action == "retryToPayAdditionalFreezingDays") {
                    this.serviceRetryToPayId = 0;
                    this.additionalFreezingDayRetryToPayId = id;
                    this.showRetryToPayService = true;

                }
            },
            updateContract: async function(){
                await this.getContract();
                this.showAddService = false;
                this.showRetryToPayService = false;
                this.showAdditionalFreezingDays = false;
            },
            getFormatedAmount,
            getFormatedDate,
            getContractStatus,
            getServiceContractStatus
        },
        components: { addService: AddService, retryToPayService: RetryToPayService, addAdditionalFreezingDays: AddAdditionalFreezingDays },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;

                    this.contractId = id;

                    await this.getContract();
                },
                deep: true,
                immediate: true
            }
        },
        async beforeMount() {
            let contractsPermission = this.$store.getters.getContractsPermission;
            let serviceContractsPermission = this.$store.getters.getServiceContractsPermission;

            if (contractsPermission.length == 0) contractsPermission = await retryGetPermissions("contract");
            if (serviceContractsPermission.length == 0) serviceContractsPermission = await retryGetPermissions("servicecontract");

            if (!contractsPermission?.includes('contracts/{id}/detailed')) this.$router.push('/forbidden');

            this.hasAddServicePermission = serviceContractsPermission.includes('service-contracts/create');
            this.hasDeleteServicePermission = serviceContractsPermission.includes('service-contracts/{id}/cancel');
            this.hasPayServicePermission = serviceContractsPermission.includes('service-contracts/{id}/retry-to-pay');
        },
    });
</script>

<style scoped>
    .contract_page_wrapper {
        padding-right: 5px;
        overflow: auto;
    }

    .button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button_back {
        width: 107px;
        margin-bottom: 12px;
    }

    .back_button_img {
        margin-right: 7px;
        margin-top: 1px;
        margin-left: -10px;
    }

    .agreement {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
    }

    .contract_data {
        display: flex;
        flex-flow:wrap;
    }

    .contract_data_block {
        width: 25%;
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .contract_status {
        margin-top: -1px;
        margin-left: 10px;
    }

    .contract_payment_block{
        width: 50%
    }

    .contract_title{
        display: flex;
    }


    .status_text_new {
        background-color: white;
        padding: 4px 10px;
        border-radius: 12px;
        color: black;
        border: 0.1px solid;
    }

    .status_text_active {
        background-color: #def8e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: green;
    }

    .status_text_frozen {
        background-color: #e1e1ff;
        padding: 4px 10px;
        border-radius: 12px;
        color: blue;
    }

    .status_text_indebt {
        background-color: #fff1d7;
        padding: 4px 10px;
        border-radius: 12px;
        color: orange;
    }

    .status_text_blocked {
        background-color: #b9b94b;
        padding: 4px 10px;
        border-radius: 12px;
        color: yellow;
    }

    .status_text_finished {
        background-color: #b4b4b4;
        padding: 4px 10px;
        border-radius: 12px;
        color: black;
    }

    .status_text_terminated {
        background-color: #efefef;
        padding: 4px 10px;
        border-radius: 12px;
        color: gray;
    }

    .status_text_refunded {
        background-color: #ffe5e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: red;
    }

    .payment_date {
        color: #65656F;
        margin-left: 12px;
    }

    .contract_previous_payment_queue {
        color: #65656F;
        font-size: 12px;
    }

    .service_wrapper:nth-child(even) {
        padding-right: 16px;
        width: calc(50% - 16px);
    }

    .service_wrapper:nth-child(odd) {
        width: 50%;
    }

    .service {
        display: flex;
        padding: 12px 16px;
        background-color: #f6f7f8;
        border-radius: 16px;
        margin: 4px 0px;
        flex-flow: column;
    }

    .service_delete_button {
        padding: 10px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
    }

    .service_delete_button_img {
        height: 13px;
        width: 13px;
    }

    .service_title_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .service_name_wrapper {
        width: calc(100% - 40px);
    }

    .service_title_data {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
    }

    .service_body_wrapper {
        display: flex;
        flex-flow: wrap;
        width: 100%;
    }

    .service_body {
        width: 50%;
    }

    .not_payed {
        color: red;
    }

    .service_name_wrapper_with_pay_button {
        width: calc(100% - 114px);
    }

    .pay_button {
        width: 75px;
        margin-right: 5px;
        padding: 10px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 33px;
    }

    .pay_button_mobile{
        display: none;
    }

    .service_name {
        font-weight: 600;
    }

    .discount_footnote {
        font-size: 12px;
        color: #65656F;
        margin: 12px 0px 0px 12px;
    }

    .service_title {
        width: 100%;
        display: flex;
    }

    .additional_freezing_days_title {
        width: 100%;
        display: flex;
    }

    .service_add_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 12px;
        line-height: 24px;
        color: white;
        width: 110px;
        height: 33px;
        margin-top: -4px;
        margin-left: 12px;
    }

    .contract_status_description {
        margin: 1px 0px 0px 0px;
        font-size: 14px;
        color: #65656F;
    }

    .contract_recurring_payments_agrees {
        width: 100%;
        font-size: 12px;
        color: #65656F;
    }

    @media(max-width: 1100px) {
        .service_body {
            width: 100%;
        }
    }

    @media(max-width: 860px) {
        .contract_data_block{
            width: 50%;
        }

        .service_wrapper:nth-child(odd) {
            width: 100%;
        }

        .service_wrapper:nth-child(even) {
            padding-right: 0px;
            width: 100%;
        }

        .service_body {
            width: 50%;
        }
    }

    @media(max-width: 650px) {
        .contract_data_block {
            width: 100%;
        }

        .contract_payment_block {
            width: 100%
        }

        .contract_status {
            margin-left: 0px;
            margin-top: 4px;
            width: fit-content;
        }

        .contract_title {
            display: block;
        }

        .service_body {
            width: 100%;
        }

        .contract_status_description {
            margin: 4px 0px 0px 0px;
        }

        .contract_recurring_payments_agrees{
            margin-top: 12px;
        }
    }

    @media(max-width: 500px) {
        .service_name_wrapper_with_pay_button {
            width: calc(100% - 40px);
        }

        .pay_button {
            display: none;
        }

        .pay_button_mobile {
            width: 100%;
            margin-right: 0px;
            margin-top: 6px;
            padding: 10px;
            border-radius: 12px;
            background-color: #eaebec;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            height: 33px;
        }

        .service_title_data {
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
        }

        .service_title {
            justify-content: space-between;
        }

        .additional_freezing_days_title {
            justify-content: space-between;
        }

        .service_add_button {
            width: 33px;
        }

        .service_add_button_text {
            display: none;
        }

        .additional_freezing_days_h {
            width: calc(100% - 45px);
        }

    }

    :deep(.client_link) {
        cursor: pointer;
        margin-left: 4px;
        color: #65656F;
    }

    :deep(.retry_to_pay_button) {
        width: 84px;
        margin-right: 5px;
        padding: 5px 15px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 26px;
    }

    :deep(.additional_freezing_day_paid) {
        display: flex;
    }

    @media(max-width: 500px) {
        :deep(.dot_table) {
            display: none;
        }

        :deep(.status_text_awaiting_activation) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
        }

        :deep(.status_text_active) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
        }

        :deep(.status_text_finished) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
        }

        :deep(.status_text_cancelled) {
            background-color: #efefef;
            padding: 4px 10px;
            border-radius: 12px;
            color: gray;
        }

        :deep(.status_text_awaiting_finish) {
            background-color: #ffe4af;
            padding: 4px 10px;
            border-radius: 12px;
            color: #cc8800;
        }


        :deep(.additional_freezing_day_name) {
            font-weight: bold;
            margin-bottom: 10px;
        }

        :deep(.additional_freezing_day_price) {
            font-size: 14px;
        }

        :deep(.mobile_hide) {
            display: none;
        }
        
        :deep(.additional_freezing_day_used) {
            color: #a19d9d;
            font-size: 14px;
            margin-top: 10px;
        }

        :deep(.additional_freezing_day_date) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex
        }

        :deep(.freee_history_date) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex
        }

        :deep(.discount_type) {
            font-weight: bold;
        }

        :deep(.discount_description) {
            font-size: 14px;
            margin-top: 10px;
        }

        :deep(.discount_discount) {
            color: #a19d9d;
            font-size: 14px;
        }

        :deep(.discount_date) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex
        }

        :deep(.retry_to_pay_button) {
            margin-top: 10px;
        }

        :deep(.additional_freezing_day_paid) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }
    }
</style>