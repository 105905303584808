<template src="./createGroupTraining.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, getFormatedDate, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';

    interface ComponentModel {
        groupTrainers: SelectModel[],
        classes: SelectModel[],
        selectedClass: SelectModel | null,
        selectedTrainer: SelectModel | null,
        startDate: string | null,
        isButtonDisabled: boolean,
        classErrorText: string,
        trainerErrorText: string,
        startDateErrorText: string,
        clubZoneName: string,
        clubName: string,
        capacity: number,
        minDate: string
    }

    export default defineComponent({
        name: 'CreateGroupTraining',
        props: ["clubWithZone", "trainingDate", "clubZoneId"],
        data(): ComponentModel {
            return {
                groupTrainers: [],
                classes: [],
                selectedClass: null,
                selectedTrainer: null,
                isButtonDisabled: false,
                classErrorText: "",
                trainerErrorText: "",
                startDate: null,
                startDateErrorText: "",
                clubZoneName: "",
                clubName: "",
                capacity: 0,
                minDate: getFormatedDate(new Date().toISOString(), 'yyyy-MM-DDTHH:MM')
            }
        },
        methods: {
            close: function () {
                this.$emit("close", false);
            },
            selectClass: function(){
                this.capacity = this.clubWithZone.trainingClasses.find(x => x.trainingClassId == this.selectedClass?.id)?.capacity ?? 0;
            },
            save: function () {
                if (!this.selectedClass) this.classErrorText = 'timetable.create_group_training.class_error';
                if (!this.selectedTrainer) this.trainerErrorText = 'timetable.create_group_training.trainer_error';
                if (!this.startDate) this.startDateErrorText = 'timetable.create_group_training.start_date_error';
                if (this.startDate && new Date(this.startDate) < new Date()) this.startDateErrorText = 'timetable.create_group_training.start_date_expired_error';

                if (this.classErrorText || this.trainerErrorText || this.startDateErrorText) return;

                let data = { clubZoneId: this.clubZoneId, beginDate: getFormatedDate(this.startDate, "yyyy-MM-DDTHH:mm"), trainingClassId: this.selectedClass?.id, trainerId: this.selectedTrainer?.id };

                this.isButtonDisabled = true;
                axiosRequests.post(editUrl(Routes.createGroupTraining), data)
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        watch: {
            startDate: function(newValue){
                if(!newValue || (newValue && new Date() > new Date(newValue))) {
                    this.isButtonDisabled = true;
                    this.startDateErrorText = 'timetable.create_group_training.start_date_expired_error';
                }
                else {
                    this.isButtonDisabled = false;
                }
            }
        },
        async beforeMount() {
            let timetablePermission = this.$store.getters.getTimetablePermission;

            if (timetablePermission.length == 0) timetablePermission = await retryGetPermissions("timetable");

            if (!timetablePermission?.includes('timetables/group-trainings/create')){
                this.$emit("close", false);
                return;
            }

            if (this.trainingDate) this.startDate = getFormatedDate(this.trainingDate, "yyyy-MM-DDTHH:mm");

            if(!this.trainingDate || (this.trainingDate && new Date() > new Date(this.trainingDate))) this.isButtonDisabled = true;

            if (!this.clubWithZone) {
                this.close();
                return;
            }

            this.clubZoneName = this.clubWithZone.clubZones.find(x => x.clubZoneId == this.clubZoneId)?.name
            this.clubName = this.clubWithZone.name;

            this.groupTrainers = this.clubWithZone.trainers.filter(x => x.isGroupTrainer).map(x => { return { id: x.trainerId, text: x.name, isTranslated: false, disabled: false, groupName: null } });
            this.classes = this.clubWithZone.trainingClasses.map(x => { return { id: x.trainingClassId, text: x.name, isTranslated: false, disabled: false, groupName: null } });

            if(this.groupTrainers.length > 0) this.selectedTrainer = this.groupTrainers[0];
            if(this.classes.length > 0){
                 this.selectedClass = this.classes[0];
                 this.selectClass();
            }
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);         
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .create_training_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .create_training_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
        min-height: 365px;
    }

    .create_training_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .create_training {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .create_training_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .text_with_span {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #65656F;
    }

    .text_with_span span {
        font-weight: 600;
        color: black;
    }

    @media(max-width: 500px) {
        .create_training_body {
            width: 98%;
        }

        .button_space {
            margin-left: 0px;
        }

        .create_training_buttons_wrapper {
            display: block;
        }
    }
</style>