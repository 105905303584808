<div class="content_wrapper">
    <button class="new_training_class_button" v-if="!isShowFilters && isShowNewMailingButton" @click="$router.push('/mailing/create')">{{$t('mailings.create_mailing')}}</button>
    <div :class="isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile'">
        <div class="filter_wrapper pt-2">
            <div class="filter_left_wrapper">
                <v-select v-model="filterMailingGroups" :label="'mailings.mailing_group'" class="w-100" :items="mailingGroups" @update:modelValue="applyFilter" clearable></v-select>
                <v-select class="status_filter_input" v-model="filterStatus" :label="'mailings.status'" :items="stauses" @update:modelValue="applyFilter" clearable></v-select>
            </div>

            <div class="filter_right_wrapper">
                <v-select v-model="filterMailingType" :label="'mailings.mailing_type'" class="small_filter_input" :items="mailingTypes" @update:modelValue="applyFilter" clearable></v-select>
                <v-input class="small_filter_input date_input ml-4" v-model="filterDate" :label="$t('mailings.send_date')" inputType="date" height="22" @update:modelValue="applyFilter" clearable></v-input>
            </div>

        </div>


        <div><p @click="clearAllFilters" class="clear_all_filters pl-2">{{$t('mailings.clear_all_filters')}}</p></div>
    </div>


    <button class="filter_button" @click="isShowFilters = !isShowFilters">{{ !isShowFilters ? $t('mailings.filter') : $t('mailings.hide')}} <img v-show="!isShowFilters" src="/assets/img/filter.svg" /></button>

    <div class="mt-3 total_wrapper">
        <p class="total pl-2" v-show="isLoad"></p>
        <p class="total pl-2" v-show="!isLoad">{{$t('mailings.total')}}: {{tableData.length}}</p>
    </div>

    <div :class="isShowFilters ? 'mt-2 table_wrapper_with_open_filter' : 'mt-2 table_wrapper'">
        <v-datatable class="pointer" :columTemplates="columTemplates" :data="tableData" @clickOnRow="openMailing"></v-datatable>
    </div>
</div>


    








