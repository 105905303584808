<createGroupTraining v-if="isShowCreateGroupTraining" :clubWithZone="clubWithZone" :trainingDate="addTrainingDate" :clubZoneId="selectedClubZone.id" @close="closeAllWindows"></createGroupTraining>
<createPesonalTraining v-if="isShowCreatePersonalTraining" :clubWithZones="clubWithZones" :trainingDate="addTrainingDate" :selectedPersonalTrainerId="this.personalTrainer?.id" @close="closeAllWindows"></createPesonalTraining>
<viewGroupTraining v-if="isShowGroupTraining" :training="selectedGroupTraining" @close="closeAllWindows" @updateTraining="updateTraining"></viewGroupTraining>
<viewPersonalTraining v-if="isShowPersonalTraining" :training="selectedPersonalTraining" @close="closeAllWindows" @updateTraining="updateTraining"></viewPersonalTraining>
<copyingAlert v-if="isShowAlert" :alertCopyingWeek="alertCopyingWeek" :alertPastedWeek="alertPastedWeek"  @success="copyTrainings" @cancel="isShowAlert = false"></copyingAlert>


<div class="content_wrapper wrapper">

    <div class="training_buttons_wrapper">
        <a v-show="false" ref="download"></a>
        <div class="training_select_wrapper">
            <button :class="isShowGroupTrainings ? 'training_button_active' : 'training_button'" @click="isShowGroupTrainings = true">{{$t('timetable.group_trainings')}}</button>
            <button :class="!isShowGroupTrainings ? 'training_button_active' : 'training_button'" @click="isShowGroupTrainings = false">{{$t('timetable.personal_trainings')}}</button>
        </div>

        <div class="flex">
            <button class="new_training" @click="createTraining(null)" v-if="(isShowGroupTrainings && hasCreateGroupPermission) || (!isShowGroupTrainings && hasCreatePersonalPermission)">{{$t('timetable.add_training')}}</button>
            <button class="load_pdf" v-if="hasPDFLoadPermission && isShowGroupTrainings && !hasCopyPermission" @click="downloadFile">{{$t('timetable.load_pdf')}}</button>
            <button class="load_pdf" v-if="hasCopyPermission && isShowGroupTrainings && !hasPDFLoadPermission" @click="showCopyTrainingsAlert">{{$t('timetable.copy_trainings')}}</button>

            <div v-if="hasCopyPermission && isShowGroupTrainings && hasPDFLoadPermission" class="position-relative" v-click-outside="onClickOutside">
                <button class="menu_button" @click="isShowButtons = !isShowButtons"><img class="menu_img" src="/assets/img/ellipsis.svg"></button>
                <div class="menu" v-show="isShowButtons">
                    <span v-if="hasPDFLoadPermission" class="menu_item" @click="downloadFile">{{$t('timetable.load_pdf')}}</span>
                    <span v-if="hasCopyPermission" class="menu_item" @click="showCopyTrainingsAlert">{{$t('timetable.copy_trainings')}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper_body" v-if="isShowGroupTrainings">
        <div :class="isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile'">
            <div class="filter_wrapper">
                <div class="filter">
                    <v-select v-model="selectedClubZone" class="club_zone_filter" :label="'timetable.club_zone'" :items="clubZones" @update:modelValue="changeClubZone"></v-select>
                    <v-select v-model="filterClass" class="classes_filter" :label="'timetable.training_class'" :items="classes" @update:modelValue="applyGroupFilter" clearable></v-select>
                    <v-select v-model="filterTrainer" class="w-100" :label="'timetable.trainer'" :items="groupTrainers" @update:modelValue="applyGroupFilter" clearable></v-select>
                </div>

            </div>
            <div><p @click="clearAllGroupFilters" class="clear_all_filters pl-2">{{$t('timetable.clear_all_filters')}}</p></div>
        </div>

        <button class="filter_button" @click="isShowFilters = !isShowFilters">{{ !isShowFilters ? $t('timetable.filter') : $t('timetable.hide')}} <img v-show="!isShowFilters" src="/assets/img/filter.svg" /></button>

        <div class="week_switch_wrapper">
            <button @click="clickOnPreviousWeek" class="week_switch_button mr-1" :disabled="isLoading"><img class="week_switch_button_img" src="/assets/img/arrow-left.svg"></button>
            <button @click="clickOnNextWeek" class="week_switch_button" :disabled="isLoading"><img class="week_switch_button_img" src="/assets/img/arrow-right.svg"></button>
        </div>

        <div class="table_wrapper">
            <div class="timetable_header_mobile_wrapper_sticky">
                <div class="timetable_header_mobile_wrapper">
                    <div :class="mobileShowDay != index ? 'timetable_header_mobile' : 'timetable_header_mobile timetable_header_mobile_active'" v-for="(weekDay, index) in groupTrainingsTimetable" @click="mobileShowDay = index">
                        <div class="timetable_header_mobile_week_day">
                            {{$t(weekDay.weekDay)}}
                        </div>
                        <div>
                            {{getFormatedDate(weekDay.date, 'DD')}}
                        </div>
                    </div>
                </div>
            </div>


            <div class="flex">
                <div v-if="groupTrainingsTimetable?.length > 0">
                    <div class="timestamp_header"></div>
                    <div v-for="cell in groupTrainingsTimetable[0]?.cells" class="timestamp" :style="{ height: `${groupCellHieght}px` }">
                        {{getFormatedDate(cell.dateTime, 'HH:mm')}}
                    </div>
                </div>

                <div class="timetable_wrapper">
                    <div v-for="(weekDay, index) in groupTrainingsTimetable" :class="mobileShowDay != index ? 'week_day_wrapper week_day_wrapper_hide' : 'week_day_wrapper'">
                        <div class="timetable_header">
                            <div>
                                {{$t(weekDay.weekDay)}}
                            </div>
                            <div class="mt-1">
                                {{getFormatedDate(weekDay.date, 'DD.MM.yyyy')}}
                            </div>
                        </div>

                        <div>
                            <div v-for="cell in weekDay.cells" :class="cell.isDisable ? 'cell cell_disabled' : cell.isPassed  ? 'cell_passed' : cell.trainings.length > 0 ? 'cell' : 'empty_cell'" :style="{ height: `${groupCellHieght}px` }" @click="clickOnGroupTrainersCell(cell)">
                                <div v-if="cell.trainings.length > 0" class="training_wrapper" :style="{ height: `calc(${groupCellHieght}px * ${cell.trainings[0].numberOfCells} - 10px)`}" v-show="cell.trainings[0].isShow">
                                    <div :style="{ 'background-color': cell.trainings[0].trainingColor, color: cell.trainings[0].trainingTextColor }" class="training">
                                        <div class="training_title_wrapper">
                                            <div class="training_title">
                                                <p class="training_time">{{cell.trainings[0].trainingTime}}</p>
                                                <p class="training_name">{{cell.trainings[0].trainingClassName}}</p>
                                            </div>
                                            <div class="trainer_photo" :style="{ 'background-image': `url(${cell.trainings[0].trainerPhoto})` }"></div>
                                        </div>

                                        <p class="training_trainer_name">{{cell.trainings[0].trainerName}}</p>
                                        <p class="training_trainer_capacity">{{cell.trainings[0].load}} {{$t('timetable.out_of')}} {{cell.trainings[0].capacity}}</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="wrapper_body" v-if="!isShowGroupTrainings">
        <div>
            <div class="filter_wrapper">
                <div class="filter">
                    <v-select v-model="personalTrainer" class="personal_trainer_filter" :label="'timetable.trainer'" :items="personalTrainers" @update:modelValue="selectPersonalTrainer"></v-select>
                </div>
            </div>
        </div>

        <div class="personal_week_switch_wrapper">
            <button @click="clickOnPreviousWeek" class="week_switch_button mr-1" :disabled="isLoading"><img class="week_switch_button_img" src="/assets/img/arrow-left.svg"></button>
            <button @click="clickOnNextWeek" class="week_switch_button" :disabled="isLoading"><img class="week_switch_button_img" src="/assets/img/arrow-right.svg"></button>
        </div>

        <div class="table_wrapper">
            <div class="timetable_header_mobile_wrapper_sticky">
                <div class="timetable_header_mobile_wrapper">
                    <div :class="mobileShowDay != index ? 'timetable_header_mobile' : 'timetable_header_mobile timetable_header_mobile_active'" v-for="(weekDay, index) in personalTrainingsTimetable" @click="mobileShowDay = index">
                        <div class="timetable_header_mobile_week_day">
                            {{$t(weekDay.weekDay)}}
                        </div>
                        <div>
                            {{getFormatedDate(weekDay.date, 'DD')}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex">
                <div v-if="personalTrainingsTimetable?.length > 0">
                    <div class="timestamp_header"></div>
                    <div v-for="cell in personalTrainingsTimetable[0]?.cells" class="timestamp" :style="{ height: `${personalCellHieght}px` }">
                        {{getFormatedDate(cell.dateTime, 'HH:mm')}}
                    </div>
                </div>

                <div class="timetable_wrapper">
                    <div v-for="(weekDay, index) in personalTrainingsTimetable" :class="mobileShowDay != index ? 'week_day_wrapper week_day_wrapper_hide' : 'week_day_wrapper'">
                        <div class="timetable_header">
                            <div>
                                {{$t(weekDay.weekDay)}}
                            </div>
                            <div class="mt-1">
                                {{getFormatedDate(weekDay.date, 'DD.MM.yyyy')}}
                            </div>
                        </div>

                        <div>
                            <div v-for="cell in weekDay.cells" :class="cell.isDisable ? 'cell cell_disabled' : cell.isPassed  ? 'cell_passed' : cell.trainings.length > 0 ? 'cell overflow_hidden' : 'empty_cell'" :style="{ height: `${personalCellHieght}px` }" @click="clickOnPersonalTrainersCell(cell)">
                                <div v-for="training in cell.trainings" class="training_wrapper" :class="{show_full_personal_training: training.isShowFullTraining, hide_personal_training: training.isHideTraining}" :style="{ height: `calc(${personalCellHieght}px * ${training.numberOfCells} - 10px)`, left: `${training.left}%`, transition: 'left 0.3s ease-out 0.1s' }" @click="clickOnPersonalTraining(training)" @mouseenter="mouseenterOnPersonalTraining(cell, training.trainingId)" @mouseleave="mouseleaveOnPersonalTraining(cell)">
                                    <div class="training">
                                        <div class="flex">
                                            <div v-show="!training.isPaid" class="training_not_paid"></div>
                                            <p class="training_time">{{training.trainingTime}}</p>
                                        </div>
                                        <p class="training_trainer_name mt-2">{{training.trainerName}}</p>
                                        <p class="training_name mt-1">{{training.clientrName}} </p>
                                        <p class="training_service_name">{{training.serviceName}} </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
