<template src="./updateClientSourse.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { SelectModel } from '../../../../models/GUIModels';
    import { ClientSourse } from '../../../../models/ClientSourse';


    interface ComponentModel {
        clientSourses: SelectModel[],
        clientSourse: SelectModel | null,
        isButtonDisabled: boolean,
        clientSoursesErrorText: string
    }

    export default defineComponent({
        name: 'UpdateClientSourse',
        props: ['clientId', 'isRequireSalesChannelSelectionOnSignUp', 'clientSourceId'],
        data(): ComponentModel {
            return {
                clientSourses: [],
                clientSourse: null,
                isButtonDisabled: false,
                clientSoursesErrorText: ""
            }
        },
        methods: {
            close: function () {
                this.$emit("close", false);
            },
            getClientSourses: function(){
                  axiosRequests.get(editUrl(Routes.getClientSources, this.clientId))
                      .then(
                          (data: ClientSourse[]) => {
                              this.clientSourses = data.map(x => { return { id: x.clientSourceId.toString(), text: x.sourceName, isTranslated: false, disabled: false, groupName: null } });

                              if(!this.isRequireSalesChannelSelectionOnSignUp) this.clientSourses.unshift({ id: "0", text: "clients.view.update_client_sourse.client_sourse_not_set", isTranslated: true, disabled: false, groupName: null });

                              let index = this.clientSourses.findIndex(x => x.id == this.clientSourceId?.toString());

                              if (index != -1) {
                                  this.clientSourse = this.clientSourses[index];
                                  return;
                              }
                              
                              if(!this.isRequireSalesChannelSelectionOnSignUp) this.clientSourse = this.clientSourses[0];
                          }
                      )
                      .catch(
                          async (err: any) => {
                              this.isButtonDisabled = false;
                              if (err.status == 401) {
                                  return;
                              } else if (err.status == 403) {
                                   this.$router.push('/forbidden');
                              } else if (err.status == 400 && err.data) {
                                  this.$store.commit('SET_ADD_ERROR', err.data);
                              } else {
                                  this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                              }
                          }
                      );
            },
            save: function () {
                if ((!this.clientSourse || this.clientSourse.id == "0") && this.isRequireSalesChannelSelectionOnSignUp) {
                    this.clientSoursesErrorText = 'clients.view.update_client_sourse.client_sourse_error_text';
                    return;
                }
                
                this.isButtonDisabled = true;

                let clientSourseId: string| null = null;

                if(this.clientSourse?.id && this.clientSourse?.id != "0") clientSourseId = this.clientSourse.id;

                axiosRequests.post(editUrl(Routes.updateClientSources, this.clientId), { clientSourceId: clientSourseId})
                    .then(
                        () => {
                            this.isButtonDisabled = false;
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
           this.getClientSourses();
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .update_client_sourse_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .update_client_sourse_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .update_client_sourse_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .update_client_sourse {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .update_client_sourse_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    @media(max-width: 500px) {
        .update_client_sourse_body {
            width: 98%;
        }

        .update_client_sourse_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }
</style>