<addPhoto  v-if="showAddPhoto" :userId="userId"  @close="closeAddPhotoWindow" ></addPhoto>
<linkPassCard v-if="showlinkPassCard" :userId="userId" @close="showlinkPassCard = false" @linkPassCard="createPassCard"></linkPassCard>

<passCardAlert :title="passCardAlertTitle" v-if="isShowAwaitingCard" @close="closeAwaitingCard"></passCardAlert>

<div class="content_wrapper user_wrapper">
    <div class="top_buttons_wrapper">
        <button @click="$router.go(-1)" class="button button_back"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('user.view.back')}}</span></button>

        <div class="flex" v-if="hasConnection">
            <a class="link_card_button" @click="showlinkPassCard = true">{{$t('user.view.link_card')}}</a>
            <a class="untie_card" v-if="user?.hasPassCard" @click="deactivatePassCard">{{$t('user.view.untie_card')}}</a>
        </div>
    </div>

    <div class="tab_body">
        <div class="profile_wrapper">
            <div class="photo_wrapper">
                <div class="img_wrapper">
                    <div class="img_block position-relative">
                        <p class="photo_text">{{$t('user.view.photo')}}</p>
                        <div v-if="user?.photoUrl" class="photo" :style="{ 'background-image': `url(${user?.photoUrl})` }"></div>
                        <button class="camera" @click="showAddPhoto = true" v-if="hasUploadPhotoPermission"><img class="camera_img" src="/assets/img/camera.svg" /></button>
                    </div>
                </div>
            </div>

            <div class="data_wrapper">
                <div class="user_info_wrapper">
                    <div class="user_info_title">
                        <div class="w-100">
                            <p class="user_info_fi">{{user?.lastName}} {{user?.firstName}}</p>
                            <p class="user_info_phone">{{addPhoneMask(user?.phone) || $t('user.view.phone_absent')}}</p>
                        </div>
                    </div>

                    <div class="user_info_body">
                        <div class="first_block_wrapper">
                            <div>
                                <div class="one_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.email')}}</p>
                                    <p class="block_info">{{user?.email}}</p>
                                </div>
                            </div>
                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.role')}}</p>
                                    <p class="block_info">{{user?.mainRoleName ? $t(user?.mainRoleName) : "-"}}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.sex')}}</p>
                                    <p class="block_info">{{user?.sex ? $t('user.view.male') : $t('user.view.female') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="second_block_wrapper">
                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.club')}}</p>
                                    <p class="block_info club_name_info">{{user?.lastClubName }}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.stay')}}</p>
                                    <div class="flex"><div :class="user?.isInClub ? 'dot dot_active' : 'dot dot_disable'"></div><p class="in_club_status">{{user?.isInClub ? $t('user.view.in_club') : $t('user.view.not_in_club')}}</p></div>
                                </div>
                            </div>
                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.last_in')}}</p>
                                    <p class="block_info">{{getFormatedDate(user?.lastInDate, "DD.MM.yyyy HH:mm") || '-' }}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('user.view.last_out')}}</p>
                                    <p class="block_info">{{getFormatedDate(user?.lastOutDate, "DD.MM.yyyy HH:mm") || '-'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>