import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59fc0b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "update_client_sourse_wrapper" }
const _hoisted_2 = { class: "update_client_sourse_body" }
const _hoisted_3 = { class: "update_client_sourse_title" }
const _hoisted_4 = { class: "update_client_sourse" }
const _hoisted_5 = { class: "update_client_sourse_buttons_wrapper" }
const _hoisted_6 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('clients.view.update_client_sourse.update_client_sourse')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_select, {
          class: "",
          modelValue: _ctx.clientSourse,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.clientSourse) = $event)),
          label: 'clients.view.update_client_sourse.client_sourse',
          items: _ctx.clientSourses,
          errorText: _ctx.clientSoursesErrorText,
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.clientSoursesErrorText = ''))
        }, null, 8, ["modelValue", "label", "items", "errorText"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "primary_button",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.save && _ctx.save(...args))),
          disabled: _ctx.isButtonDisabled
        }, _toDisplayString(_ctx.$t('clients.view.update_client_sourse.save')), 9, _hoisted_6),
        _createElementVNode("button", {
          class: "secondary_button button_space",
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('close', false)))
        }, _toDisplayString(_ctx.$t('clients.view.update_client_sourse.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}