import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af2a7e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_wrapper" }
const _hoisted_2 = { class: "search_wrapper" }
const _hoisted_3 = { class: "total_wrapper" }
const _hoisted_4 = { class: "mt-2 table_wrapper" }

export function render(_ctx, _cache) {
  const _component_v_datatable = _resolveComponent("v-datatable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("p", { class: "total pl-2" }, _toDisplayString(_ctx.$t('search.find')) + ": " + _toDisplayString(_ctx.allData.length), 513), [
          [_vShow, !_ctx.isLoad]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_datatable, {
          class: "pointer",
          columTemplates: _ctx.columTemplates,
          data: _ctx.allData,
          onClickOnRow: _ctx.openClient
        }, null, 8, ["columTemplates", "data", "onClickOnRow"])
      ])
    ])
  ]))
}