<div class="content_wrapper wrapper">
    <div class="content_body">
        <h2 class="mt-0">{{$t('clients.create.create_new_client')}}</h2>
        <div class="client_wrapper">
            <div class="elment_wrapper"><v-input class="elment" v-model="lastname" :label="$t('clients.create.lastname')" height="22" :errorText="lastnameErrorText" @focus="lastnameErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="firstname" :label="$t('clients.create.firstname')" height="22" :errorText="firstnameErrorText" @focus="firstnameErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="middlename" :label="$t('clients.create.middlename')" height="22" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="email" :label="$t('clients.create.email')" height="22" :errorText="emailErrorText" @focus="emailErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="phone" placeholder="+7 (000) 000-00-00" v-maska:[options] :label="$t('clients.create.phone')" height="22" :errorText="phoneErrorText" @focus="phoneErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="birthday" inputType="date" :label="$t('clients.create.birthday')" height="22" :errorText="birthdayErrorText" :min="minBirthdayDate" :max="maxBirthdayDate" @focus="birthdayErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-select class="elment" v-model="selectSex" :label="'clients.create.sex'" :items="sex"></v-select></div>
        </div>

        <div class="contract_wrapper">
            <div class="elment_wrapper"><v-select class="elment" v-model="selectedClub" :label="'clients.create.club'" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''" @update:modelValue="selectClub"></v-select></div>
            <div class="elment_wrapper"><v-select class="elment" v-model="selectedAgreement" :label="'clients.create.agreement'" :items="agreements" :errorText="agreementErrorText" @click="agreementErrorText = ''" @update:modelValue="selectAgreement" clearable></v-select></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="activationDate" inputType="date" :label="$t('clients.create.contract_activation_date')" height="22" :errorText="activationDateErrorText" @focus="activationDateErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-input class="elment" v-model="paymentDate" inputType="date" :label="$t('clients.create.payment_date')" height="22" :errorText="paymentDateErrorText" @focus="paymentDateErrorText = ''" clearable></v-input></div>
            <div class="elment_wrapper"><v-select class="elment" v-model="selectedPaymentMethod" :label="'clients.create.payment_method'" :items="paymentMethods"></v-select></div>
            <div class="w-100 mt-2 mb-3"><div class="flex"><v-checkbox v-model="isCorporateClient" :label="$t('clients.create.corporate_client')"></v-checkbox></div></div>
            <div class="corporate_organizations_wrapper" v-show="isCorporateClient">
                <div class="elment_wrapper"><v-select class="elment" v-model="selectedOrganizations" :label="'clients.create.organization'" :items="organizations" @update:modelValue="selectOrganization"></v-select></div>
                <div class="elment_wrapper"><v-input v-show="!selectedOrganizations?.id" class="elment new_organization_name" v-model="organizationName" :label="$t('clients.create.organization_name')" height="22" :errorText="organizationNameErrorText" @focus="organizationNameErrorText = ''" clearable></v-input></div>
            </div>
        </div>

        <div class="agreement_element_wrapper" v-show="selectedAgreement">
            <div class="agreement_element">
                <p class="agreement_element_label">{{selectedAgreement?.text}}</p>
                <v-input v-model="tempContractPrice" inputType="number" :label="`${$t('clients.create.price')}, ${currencySign}`" height="22" :min="0" :step="0.01" @blur="checkContractPrice" @update:modelValue="getTotalPrice"></v-input>
            </div>

            <div class="agreement_element" v-for="(service, index) in tempSevices">
                <p class="agreement_element_label">{{service.name}}</p>
                <v-input v-model="service.price" inputType="number" :label="`${$t('clients.create.price')}, ${currencySign}`" height="22" :min="0" :step="0.01" @blur="checkServicePrice(index)" @update:modelValue="getTotalPrice"></v-input>
            </div>

        </div>

        <p class="total_price" v-show="selectedAgreement">{{$t('clients.create.total_price')}}: {{getFormatedAmount(totalPrice * 100)}} {{currencySign}}</p>

        <div class="button_wrapper">
            <button class="primary_button button_space" @click="save">{{$t('clients.create.create')}}</button>
            <button class="secondary_button" @click="$router.go(-1)">{{$t('clients.create.back')}}</button>
        </div>
    </div>
   

</div>







